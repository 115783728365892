<template>
  <div class="regular-header">
    <h3 v-if="data.title != undefined">{{ data.title }}</h3>
    <p v-if="data.description != undefined">{{ data.description }}</p>
  </div>
</template>

<script>
export default {
  name: "RegularHeader",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  font-size: $mediumFontSize;
  margin-bottom: 0.5rem;
}

p {
  font-size: $smallFontSize;
  opacity: $mediumOpacity;
}
</style>