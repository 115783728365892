import _ from "lodash";
import moment from "moment-timezone";

// Importing services
// Importing Services
import { AuthService, UserService } from "@/services";

export default {
  methods: {
    /****************************/
    /** Form Validations **/
    /****************************/

    // Form validation
    validateForm(formFields) {
      var hasErrors = false;
      var validField;
      var response;

      for (const field of Object.keys(formFields)) {
        if (formFields[field].show !== false)
          if (formFields[field].type == "group") {
            response = this.validateForm(formFields[field].fields);
            if (!response) {
              hasErrors = true;
            }
          } else if (formFields[field].type == "variants") {
            response = true;
            formFields[field].value.forEach((variant) => {
              response = this.validateForm({ ...variant.fields });
              if (!response) {
                hasErrors = true;
              }
            });
          } else {
            validField = this.validateData(formFields[field]);
            if (!validField) {
              console.log(field);
              formFields[field].hasError = true;
              hasErrors = true;
            } else {
              formFields[field].hasError = false;
            }
          }
      }
      return !hasErrors;
    },

    // Data validation
    validateData(field) {
      switch (field.type) {
        case "text":
        case "textarea":
        case "text-editor":
        case "date":
        case "password":
        case "profile-image":
        case "color-picker":
        case "slug-url":
          if (
            field.required &&
            (field.value == undefined || field.value.length == 0)
          )
            return false;
          else return true;

        case "number":
        case "money":
          if (
            field.required &&
            (field.value == undefined ||
              field.value.length == 0 ||
              isNaN(field.value))
          )
            return false;
          else return true;

        case "tel":
          if (
            field.required &&
            (field.value == undefined || field.value.length != 10)
          )
            return false;
          else return true;

        case "country-tel":
          if (field.required && field.value.number == undefined) return false;
          else return true;

        case "url":
          if (
            field.required &&
            (field.value == undefined || field.value.length == 0)
          )
            return false;
          else if (
            !field.required &&
            field.value != undefined &&
            field.value != null &&
            field.value != "" &&
            field.value.length > 0
          ) {
            var pattern = new RegExp(
              "^(https?:\\/\\/)?" +
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                "(\\#[-a-z\\d_]*)?$",
              "i"
            );
            return !!pattern.test(field.value);
          } else return true;

        case "toggle":
        case "checkbox":
          return true;

        case "radio":
          if (
            field.required &&
            (field.modelName == undefined || field.modelName.length == 0)
          )
            return false;
          else return true;

        case "file-large":
        case "gallery-images":
          if (
            field.required &&
            (field.fileList == undefined || field.fileList.length == 0)
          )
            return false;
          else return true;

        case "dropdown":
          if (field.required && !field.value.length) return false;
          else return true;

        case "multiple-select-dropdown":
          if (field.required && field.value.length == 0) return false;
          else return true;

        case "email":
          if (
            field.required &&
            (field.value == undefined || field.value.length == 0)
          )
            return false;
          else if (
            !field.required &&
            (field.value == undefined || field.value.length == 0)
          ) {
            return true;
          } else {
            var emailValidator = /^\w+[\w-+.]*@\w+([-.]\w+)*\.[a-zA-Z]{2,}$/;
            return field.value.match(emailValidator);
          }
        case "time":
          if (field.required && (field.value == "" || field.value === null))
            return false;
          else return true;
        case "scheduler":
          var ele = this;
          var hasError = false;

          // Parse through the days
          field.uniqueSlots.forEach(function(day) {
            if (day.isActive.value)
              day.timeSlots.forEach(function(timeSlot) {
                if (!ele.validateForm(timeSlot)) hasError = true;
              });
          });
          return !hasError;
        case "otp":
          if (field.codeLength != field.value.length) return false;
          else return true;
        case "icon-selector":
          if (
            field.value.icon == undefined ||
            field.value.title == undefined ||
            field.value.icon.length == 0 ||
            field.value.title.length == 0
          )
            return false;
          else return true;
      }
    },

    // Email validation
    validateEmail(email) {
      var emailValidator = /^\w+[\w-+.]*@\w+([-.]\w+)*\.[a-zA-Z]{2,}$/;
      return email.match(emailValidator);
    },

    // Create JSON object for data transfer
    parseData(formFields, allowEmptyValues = false) {
      var parsedData = {};
      var tempData;

      //Loop through all the fields
      for (const field of Object.keys(formFields)) {
        if (formFields[field] != null && formFields[field].show !== false) {
          //Check if it's a group of fields
          if (formFields[field].type == "group") {
            var groupFields = formFields[field].fields;
            tempData = this.parseData(groupFields);

            //Add the fields in a nested object
            if (
              formFields[field].nestFields == true &&
              Object.keys(tempData).length !== 0
            ) {
              parsedData[field] = tempData;
            } else {
              parsedData = { ...parsedData, ...tempData };
            }
          }
          //Check if it's a variants type
          else if (formFields[field].type == "variants") {
            var variantFields = formFields[field].value;

            let variantsArray = [];
            variantFields.forEach((variant) => {
              variantsArray.push({
                _id: variant.variantID,
                ...this.parseData({ ...variant.fields }),
              });
            });
            tempData = variantsArray;

            //Add the fields in a nested object
            if (
              formFields[field].nestFields == true &&
              Object.keys(tempData).length !== 0
            ) {
              parsedData[field] = tempData;
            } else {
              parsedData = { ...parsedData };
              parsedData[field] = tempData;
            }
          }
          //If it's a multiple select dropdown
          else if (
            formFields[field].type == "multiple-select-dropdown" &&
            formFields[field].value.length != 0
          ) {
            parsedData[field] = formFields[field].value;
          }

          //If it's a multiple select dropdown
          else if (
            formFields[field].type == "dropdown" &&
            formFields[field].value != "" &&
            formFields[field].value.length !== 0
          ) {
            parsedData[field] = formFields[field].value[1];
          }

          //If it's a multiple toggle button
          else if (
            formFields[field].type == "radio" &&
            formFields[field].value != undefined
          ) {
            const object = formFields[field].options.find(
              (o) => o.name === formFields[field].value
            );
            parsedData[field] = object.value;
          }

          //If it's a multiple toggle button
          else if (formFields[field].type == "gallery-images") {
            if (formFields[field].fileList.length)
              parsedData.uploadedFiles = formFields[field].fileList;
            if (formFields[field].value.length)
              parsedData[field] = formFields[field].value;
          }

          //If it's a scheduler type
          else if (formFields[field].type == "scheduler") {
            var days = [],
              timeSlots = [];
            var slotsLength;

            const ele = this;
            formFields[field].uniqueSlots.forEach(function(day) {
              slotsLength = day.timeSlots.length;
              if (day.isActive.value) {
                timeSlots = [];
                for (let i = 0; i < slotsLength; i++) {
                  timeSlots.push(ele.parseData(day.timeSlots[i]));
                }
              }

              const utcTimeSlots = [];
              timeSlots.forEach((slot) => {
                const utcStartTime = moment(slot.startTime, "HH:mm")
                  .utc()
                  .format("HH:mm");
                const utcEndTime = moment(slot.endTime, "HH:mm")
                  .utc()
                  .format("HH:mm");
                utcTimeSlots.push({
                  startTime: utcStartTime,
                  endTime: utcEndTime,
                });
              });

              days.push({
                day: day.day.substring(0, 3),
                isActive: day.isActive.value,
                timeSlots: day.isActive.value ? utcTimeSlots : [],
              });
            });
            parsedData[field] = days;
          }

          //If it's a phone type
          else if (formFields[field].type == "country-tel") {
            if (
              formFields[field]?.dataNumberOnly &&
              formFields[field]?.value?.number
            ) {
              parsedData[field] =
                "+" +
                formFields[field]?.value.countryCallingCode +
                formFields[field]?.value?.number;
            } else if (formFields[field]?.value?.number)
              parsedData[field] = formFields[field].value;
          }

          //If it's a money type
          else if (formFields[field].type == "money") {
            if (parseInt(formFields[field].value)) {
              parsedData[field] = parseInt(formFields[field].value);
            }
          }

          //Add field if it's a normal field
          else if (
            formFields[field].value !== undefined &&
            formFields[field].value !== null &&
            (allowEmptyValues || formFields[field].value !== "") &&
            (allowEmptyValues || formFields[field].value.length !== 0)
          ) {
            parsedData[field] = formFields[field].value;
          }
        }
      }

      return parsedData;
    },

    // Reset form
    resetFields(formFields) {
      for (const field of Object.keys(formFields)) {
        if (formFields[field].type == "group")
          this.resetFields(formFields[field].fields);
        else if (
          formFields[field].type == "file-large" ||
          formFields[field].type == "gallery-images"
        ) {
          formFields[field].fileList.splice(0);
          formFields[field].imagesList.splice(0);
          formFields[field].value.splice(0);
        } else if (formFields[field].type == "country-tel") {
          formFields[field].value = {
            countryCode: null,
            countryCallingCode: null,
            number: null,
          };
        } else if (formFields[field].type == "variants") {
          formFields[field].value = [];
        } else {
          formFields[field].value = "";
        }
      }
    },

    // Clear all null and empty objects
    cleanObject(obj) {
      Object.keys(obj).forEach(
        (k) =>
          (obj[k] && typeof obj[k] === "object" && this.cleanObject(obj[k])) ||
          (!obj[k] && obj[k] !== undefined && delete obj[k])
      );
      return obj;
    },

    /****************************/
    /** Form Validations **/
    /****************************/

    /****************************/
    /** Workspace Functions **/
    /****************************/

    // Get user and update store
    async getUser() {
      const response = await UserService.GetUser();

      //Check the user doesn't exist and redirect
      if (response.hasError) {
        return false;
      } else {
        const { user, company } = response.data;
        this.$store.commit("updateUser", { user, loggedIn: true });
        this.$store.commit("updateCompany", company);

        return true;
      }
    },

    // Logout user
    async logoutUser() {
      var response = await AuthService.LogoutUser();
      if (!response.hasError) {
        this.$store.commit("updateUser", {
          user: {},
          loggedIn: false,
        });
        this.$store.commit("updateCompany", {});
        this.$store.commit("updateCategories", {});
        this.$store.commit("updateWidget", {});
      }

      console.log({logoutUser:"Logging Out"})
      this.$router.push({ path: "/sign-in" });
    },

    /****************************/
    /** Workspace Functions **/
    /****************************/

    // Extract first name
    getFirstName(fullName) {
      if (fullName.indexOf(" ") >= 0) {
        fullName = fullName.split(" ");
        return fullName[0];
      } else return fullName;
    },

    // Check if user is logged in
    async authenticateUser() {
      // Check if user variable is stored in VueEx
      if (this.isUserLoggedIn()) {
        return true;
      }
      // Check if user has logged in but not saved in VueEx
      else if (await this.getUser()) {
        return true;
      }
      // Return user not authenticated
      else {
        return false;
      }
    },

    // Check if the logged in user has access to view
    restrictAdminAccess() {
      if (this.$store.state.user.user.role !== "admin") {
        this.redirectDashboard();
      }
    },

    // Check if user is logged in
    isUserLoggedIn() {
      return this.$store.getters.user.loggedIn;
    },

    // Redirect user to the dashboard
    redirectDashboard() {
      this.$router.push({ path: "/inbox" });
    },

    // Redirect user to sign-in
    redirectSignin() {
      this.$router.push({ path: `/sign-in` });
    },

    // Get the currency symbols against the names
    getCurrency(currency) {
      var currencySymbols = {
        USD: "$", // US Dollar
        CAD: "$", // Canadian Dollar
        EUR: "€", // Euro
        CRC: "₡", // Costa Rican Colón
        GBP: "£", // British Pound Sterling
        ILS: "₪", // Israeli New Sheqel
        INR: "₹", // Indian Rupee
        JPY: "¥", // Japanese Yen
        KRW: "₩", // South Korean Won
        NGN: "₦", // Nigerian Naira
        PHP: "₱", // Philippine Peso
        PLN: "zł", // Polish Zloty
        PYG: "₲", // Paraguayan Guarani
        THB: "฿", // Thai Baht
        UAH: "₴", // Ukrainian Hryvnia
        VND: "₫", // Vietnamese Dong
      };

      if (currencySymbols[currency] !== undefined)
        return currencySymbols[currency];
      else return currency;
    },

    // Get all country timezones
    getAllTimeZones() {
      let timezonesArray = [];
      const timezones = [
        "UTC (GMT+00:00) Default",
        "Etc/GMT+12	(GMT-12:00) International Date Line West",
        "Etc/GMT+11	(GMT-11:00) Coordinated Universal Time-11",
        "Pacific/Samoa	(GMT-11:00) Samoa",
        "Pacific/Honolulu	(GMT-10:00) Hawaii",
        "Pacific/Marquesas	(GMT-09:30) Marquesas Islands",
        "America/Anchorage	(GMT-09:00) Alaska",
        "America/Tijuana	(GMT-08:00) Baja California",
        "America/Los_Angeles	(GMT-08:00) Pacific Time (US & Canada)",
        "America/Chihuahua	(GMT-07:00) Chihuahua,Mazatlan",
        "America/Phoenix	(GMT-07:00) Arizona",
        "America/Denver	(GMT-07:00) Mountain Time (US & Canada)",
        "America/Chicago	(GMT-06:00) Central Time (US & Canada)",
        "America/Guatemala	(GMT-06:00) Central America",
        "America/Mexico_City	(GMT-06:00) Guadalajara,Mexico City, Monterrey",
        "America/Regina	(GMT-06:00) Saskatchewan",
        "America/Bogota	(GMT-05:00) Bogota, Lima, Quito",
        "America/New_York	(GMT-05:00) Eastern Time (US & Canada)",
        "America/Indianapolis	(GMT-05:00) Indiana (East)",
        "America/Caracas	(GMT-04:30) Caracas",
        "America/Halifax	(GMT-04:00) Atlantic Time (Canada)",
        "America/Asuncion	(GMT-04:00) Asuncion",
        "America/Cuiaba	(GMT-04:00) Cuiaba",
        "America/Santiago	(GMT-04:00) Santiago",
        "America/La_Paz	(GMT-04:00) Georgetown, La Paz, Manaus, San Juan",
        "America/St_Johns	(GMT-03:30) Newfoundland",
        "America/Buenos_Aires	(GMT-03:00) Buenos Aires",
        "America/Sao_Paul	(GMT-03:00) Brasilia",
        "America/Cayenne	(GMT-03:00) Cayenne, Fortaleza",
        "America/Montevideo	(GMT-03:00) Montevideo",
        "America/Godthab	(GMT-03:00) Greenland",
        "Etc/GMT+2	(GMT-02:00) Coordinated Universal Time-02",
        "Etc/GMT+2	(GMT-02:00) Mid-Atlantic",
        "Atlantic/Azores	(GMT-01:00) Azores",
        "Atlantic/Cape_Verde	(GMT-01:00) Cape Verde Is.",
        "Africa/Casablanca	(GMT+00:00) Casablanca",
        "Atlantic/Reykjavik	(GMT+00:00) Monrovia,Reykjavik",
        "Europe/London	(GMT+00:00) Greenwich Mean Time : Dublin,Edinburgh,Lisbon, London",
        "Europe/Warsaw	(GMT+01:00) Sarajevo,Skopje, Warsaw, Zagreb",
        "Africa/Lagos	(GMT+01:00) West Central Africa",
        "Europe/Budapest	(GMT+01:00) Belgrade,Bratislava, Budapest, Ljubljana, Prague",
        "Europe/Paris	(GMT+01:00) Brussels, Copenhagen,Madrid, Paris",
        "Europe/Berlin	(GMT+01:00) Amsterdam,Berlin, Bern, Rome,Stockholm, Vienna",
        "Africa/Johannesburg	(GMT+02:00) Harare, Pretoria",
        "Asia/Damascus	(GMT+02:00) Damascus",
        "Europe/Kiev	(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        "Africa/Windhoek	(GMT+02:00) Windhoek",
        "Europe/Minsk	(GMT+02:00) Minsk",
        "Europe/Istanbu	(GMT+02:00) Athens,Bucharest, Istanbul",
        "Asia/Amman	(GMT+02:00) Amman",
        "Asia/Beirut	(GMT+02:00) Beirut",
        "Asia/Jerusalem	(GMT+02:00) Jerusalem",
        "Africa/Cairo	(GMT+02:00) Cairo",
        "Asia/Riyadh	(GMT+03:00) Kuwait, Riyadh",
        "Europe/Moscow	(GMT+03:00) Moscow, St. Petersburg, Volgograd",
        "Asia/Baghdad	(GMT+03:00) Baghdad",
        "Africa/Nairobi	(GMT+03:00) Nairobi",
        "Asia/Tehran	(GMT+03:30) Tehran",
        "Indian/Mauritius	(GMT+04:00) Port Louis",
        "Asia/Tbilisi	(GMT+04:00) Tbilisi",
        "Asia/Baku	(GMT+04:00) Baku",
        "Asia/Yerevan	(GMT+04:00) Yerevan",
        "Asia/Dubai	(GMT+04:00) Abu Dhabi, Muscat",
        "Asia/Kabul	(GMT+04:30) Kabul",
        "Asia/Yekaterinburg	(GMT+05:00) Yekaterinburg",
        "Asia/Karachi	(GMT+05:00) Islamabad,Karachi",
        "Asia/Tashkent	(GMT+05:00) Tashkent",
        "Asia/Calcutta	(GMT+05:30) Chennai, Kolkata, Mumbai,New Delhi",
        "Asia/Colombo	(GMT+05:30) Sri Jayawardenepura",
        "Asia/Katmandu	(GMT+05:45) Kathmandu",
        "Asia/Dhaka	(GMT+06:00) Dhaka",
        "Asia/Novosibirsk	(GMT+06:00) Novosibirsk",
        "Asia/Almaty	(GMT+06:00) Astana",
        "Asia/Rangoon	(GMT+06:30) Yangon (Rangoon)",
        "Asia/Krasnoyarsk	(GMT+07:00) Krasnoyarsk",
        "Asia/Bangkok	(GMT+07:00) Bangkok, Hanoi,Jakarta",
        "Asia/Ulaanbaatar	(GMT+08:00) Ulaanbaatar",
        "Australia/Perth	(GMT+08:00) Perth",
        "Asia/Taipei	(GMT+08:00) Taipei",
        "Asia/Singapore	(GMT+08:00) Kuala Lumpur,Singapore",
        "Asia/Shanghai	(GMT+08:00) Beijing,Chongqing,Hong Kong, Urumqi",
        "Asia/Irkutsk	(GMT+08:00) Irkutsk",
        "Asia/Pyongyang	(GMT+08:30) Pyongyang",
        "Australia/Eucla	(GMT+08:30) Eucla",
        "Asia/Seoul	(GMT+09:00) Seoul",
        "Asia/Tokyo	(GMT+09:00) Osaka,Sapporo, Tokyo",
        "Asia/Yakutsk	(GMT+09:00) Yakutsk",
        "Australia/Darwin	(GMT+09:30) Darwin",
        "Australia/Adelaide	(GMT+09:30) Adelaide",
        "Australia/Hobart	(GMT+10:00) Hobart",
        "Asia/Vladivostok	(GMT+10:00) Vladivostok",
        "Pacific/Port_Moresby	(GMT+10:00) Guam, Port Moresby",
        "Australia/Brisbane	(GMT+10:00) Brisbane",
        "Australia/Sydney	(GMT+10:00) Canberra,Melbourne, Sydney",
        "Australia/LHI	(GMT+10:30) Lord Howe Island",
        "Asia/Magadan	(GMT+11:00) Magadan, Solomon Is.,New Caledonia",
        "Pacific/Fiji	(GMT+12:00) Fiji",
        "Asia/Kamchatka	(GMT+12:00) Petropavlovsk-Kamchatsky",
        "Pacific/Auckland	(GMT+12:00) Auckland, Wellington",
        "Etc/GMT-12	(GMT+12:00) Coordinated Universal Time+12",
        "Pacific/Chatham	(GMT+12:45) Chatham Islands",
        "Pacific/Enderbury	(GMT+13:00) Phoenix Islands, Tokelau, Tonga",
        "Pacific/Kiritimati	(GMT+14:00) Line Islands",
      ];

      timezones.forEach((tz) => {
        timezonesArray.push({
          name: tz,
          value: tz,
        });
      });

      return timezonesArray;
    },

    // Check if viewport is mobile
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },

    // Compare data to check if they're equal
    isDataEqual(a, b) {
      return _.isEqual(a, b);
    },

    // Show status message
    showStatusMessage(status, time = 6000, message = false) {
      status.status = "success";
      status.show = true;

      if (message) status.title = message;
      setTimeout(() => (status.show = false), time);
    },

    // Show error message
    showErrorMessage(status, message) {
      status.status = "error";
      status.title = message;
      status.show = true;

      setTimeout(() => (status.show = false), 3000);
    },

    // Construct dropdown fields
    constructDropdownFields(fields, capitalize, isDefault, prefix, suffix) {
      let dropdownFields = [];
      fields.forEach((f) => {
        let fieldName = capitalize
          ? f.toUpperCase()
          : isDefault
          ? this.capitalizeFirstLetter(f)
          : this.toTitleCase(f);

        // Check for prefix and suffix
        if (prefix) prefix = prefix + " ";
        else prefix = "";
        if (suffix) suffix = " " + suffix;
        else suffix = "";
        fieldName = `${prefix}${fieldName}${suffix}`;

        dropdownFields.push({ name: fieldName, value: f });
      });

      return dropdownFields;
    },

    // Construct the singular dropdown value
    getDropdownValue(val, capitalize, isDefault, prefix, suffix) {
      let fieldName = capitalize
        ? val.toUpperCase()
        : isDefault
        ? this.capitalizeFirstLetter(val)
        : this.toTitleCase(val);

      // Check for prefix and suffix
      if (prefix) prefix = prefix + " ";
      else prefix = "";
      if (suffix) suffix = " " + suffix;
      else suffix = "";
      fieldName = `${prefix}${fieldName}${suffix}`;

      return val ? [fieldName, val] : "";
    },

    // Convert a string to title case
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    // Capitalize first letter of string
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    // Adjust the color using JS
    adjustColor(col, amt) {
      var usePound = false;

      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }

      var num = parseInt(col, 16);

      var r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amt;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },

    // Convert time to 24 hours
    convertTime24Hours(time) {
      time.replace(" ", "");
      time = time.toLowerCase();

      let addHours = 0;
      if (time.includes("pm")) addHours = 12;
      time = time.replace("am", "").replace("pm", "");

      const splitTime = time.split(":");
      splitTime[0] =
        parseInt(splitTime[0]) !== 12
          ? parseInt(splitTime[0]) + addHours
          : parseInt(splitTime[0]);
      if (splitTime[0] < 10) splitTime[0] = `0${splitTime[0]}`;

      time = `${splitTime[0]}:${splitTime[1]}`;
      return time;
    },

    getPhoneNumberString(phone) {
      return `+${phone.countryCallingCode}${phone.number}`;
    },

    // Clean and remove extra fields from the object
    sanitizeObject(obj) {
      return JSON.parse(
        JSON.stringify(obj, (key, value) => {
          return value === null ? undefined : value;
        })
      );
    },

    // Formatter for numbers
    nFormatter(num) {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
      }
      return num;
    },

    // Color changer
    pSBC(p, c0, c1, l) {
      let r,
        g,
        b,
        P,
        f,
        t,
        h,
        i = parseInt,
        m = Math.round,
        a = typeof c1 == "string";
      if (
        typeof p != "number" ||
        p < -1 ||
        p > 1 ||
        typeof c0 != "string" ||
        (c0[0] != "r" && c0[0] != "#") ||
        (c1 && !a)
      )
        return null;
      if (!this.pSBCr)
        this.pSBCr = (d) => {
          let n = d.length,
            x = {};
          if (n > 9) {
            ([r, g, b, a] = d = d.split(",")), (n = d.length);
            if (n < 3 || n > 4) return null;
            (x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4))),
              (x.g = i(g)),
              (x.b = i(b)),
              (x.a = a ? parseFloat(a) : -1);
          } else {
            if (n == 8 || n == 6 || n < 4) return null;
            if (n < 6)
              d =
                "#" +
                d[1] +
                d[1] +
                d[2] +
                d[2] +
                d[3] +
                d[3] +
                (n > 4 ? d[4] + d[4] : "");
            d = i(d.slice(1), 16);
            if (n == 9 || n == 5)
              (x.r = (d >> 24) & 255),
                (x.g = (d >> 16) & 255),
                (x.b = (d >> 8) & 255),
                (x.a = m((d & 255) / 0.255) / 1000);
            else
              (x.r = d >> 16),
                (x.g = (d >> 8) & 255),
                (x.b = d & 255),
                (x.a = -1);
          }
          return x;
        };
      (h = c0.length > 9),
        (h = a ? (c1.length > 9 ? true : c1 == "c" ? !h : false) : h),
        (f = this.pSBCr(c0)),
        (P = p < 0),
        (t =
          c1 && c1 != "c"
            ? this.pSBCr(c1)
            : P
            ? { r: 0, g: 0, b: 0, a: -1 }
            : { r: 255, g: 255, b: 255, a: -1 }),
        (p = P ? p * -1 : p),
        (P = 1 - p);
      if (!f || !t) return null;
      if (l)
        (r = m(P * f.r + p * t.r)),
          (g = m(P * f.g + p * t.g)),
          (b = m(P * f.b + p * t.b));
      else
        (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
          (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
          (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
      (a = f.a),
        (t = t.a),
        (f = a >= 0 || t >= 0),
        (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
      if (h)
        return (
          "rgb" +
          (f ? "a(" : "(") +
          r +
          "," +
          g +
          "," +
          b +
          (f ? "," + m(a * 1000) / 1000 : "") +
          ")"
        );
      else
        return (
          "#" +
          (
            4294967296 +
            r * 16777216 +
            g * 65536 +
            b * 256 +
            (f ? m(a * 255) : 0)
          )
            .toString(16)
            .slice(1, f ? undefined : -2)
        );
    },
  },
};
