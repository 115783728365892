<template>
  <div class="sources-wrapper">
    <SourcesInsights
      v-for="source in insights"
      :key="source.id"
      :source="source"
    ></SourcesInsights>
  </div>
</template>
    
<script>
// Import components
import SourcesInsights from "./SourcesInsights";

export default {
  name: "ViewSourceInsights",
  props: {
    insights: { type: Array, required: true },
  },
  data() {
    return {};
  },
  components: { SourcesInsights },
  async created() {},
  methods: {},
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
</style>