<template>
  <div class="item">
    <!--START: Item Info-->
    <div class="item-details">
      <img v-if="item.imageSrc" :src="item.imageSrc" alt="Product item" />
      <div class="item-info">
        <h3 class="title">{{ item.title }}</h3>
        <span class="variant-title" v-if="item.variantTitle">{{
          item.variantTitle
        }}</span>
      </div>
    </div>
    <!--END: Item Info-->
  </div>
</template>

<script>
export default {
  name: "OrderItem",
  data() {
    return {};
  },
  props: { item: { type: Object, required: true } },
  watch: {},
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/chat.scss";

.item {
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }
}
.item-details {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 2.25rem;
    border-radius: 0.5rem;
    margin-right: .75rem;
  }
  .item-info {
    flex: 1;
    overflow: hidden;
    margin-right: 1.5rem;
  }
  .title {
    display: block;
    color: $darkBlackColor;
    font-size: $smallFontSize;
    font-weight: $mediumFontWeight;
    margin-bottom: 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .variant-title {
    display: table;
    font-size: 10px;
    font-weight: $mediumFontWeight;
    color: lighten($darkBlackColor, 15%);
    background-color: darken($whiteColor, 10%);
    padding: 0.15rem 0.35rem;
    border-radius: 0.25rem;
  }
}

.item-quantity {
  display: block;
  font-size: $smallFontSize;
  color: lighten($darkBlackColor, 15%);
  margin-top: 0.5rem;
}
</style>