<template>
  <div>
    <!--START: Color Picker-->
    <div class="color-picker-btn" @click="showSwatchModal">
      <div class="selected-color-wrapper">
        <div class="selected-color">
          <span :style="`background-color:${this.selectedColor}`"></span>
        </div>
        <label>{{ selectedColor }}</label>
      </div>
      <button class="btn btn-border btn-small">Change Color</button>

      <!--START: Swatch Picker-->
      <div
        v-if="showSwatches"
        class="swatches-picker"
        v-click-outside="closeSwatch"
      >
        <ColorSwatches
          v-model="brandColor"
          @input="colorSelected"
        ></ColorSwatches>
      </div>
      <!--END: Swatch Picker-->
    </div>
    <!--END: Color Picker-->
  </div>
</template>
  
  <script>
// Importing libraries
import vClickOutside from "v-click-outside";
import { Chrome } from "vue-color";

export default {
  name: "ColorPicker",
  props: {
    field: {
      type: Object,
      required: false,
    },
  },
  watch: {
    field: {
      immediate: true,
      deep: true,
      handler() {
        this.initColors();
      },
    },
  },
  data() {
    return {
      showSwatches: false,
      colorChanged: false,
      selectedColor: null,
      originalColor: null,
      brandColor: {
        hex: null,
      },
    };
  },
  components: {
    ColorSwatches: Chrome,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    initColors() {
      this.selectedColor =
        this.field.value == "" ? "#4e45dd" : this.field.value;
      this.brandColor.hex =
        this.field.value == "" ? "#4e45dd" : this.field.value;
      this.originalColor =
        this.field.value == "" ? "#4e45dd" : this.field.value;
    },

    colorSelected() {
      this.selectedColor = this.brandColor.hex;
      this.field.value = this.selectedColor;
      this.formChanged();
    },

    showSwatchModal() {
      this.showSwatches = true;
      this.showBackground = true;
    },

    closeSwatch() {
      this.colorChanged = false;
      this.showSwatches = false;
    },

    formChanged() {
      this.$emit("fieldChanged");
    },
  },
};
</script>
  
  <style scoped lang="scss">
.color-picker-btn {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.selected-color-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $whiteColor;
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  border: 1px solid $greyBorderColor;
  margin-right: 1rem;
  cursor: pointer;

  &:hover {
    background-color: $lightWhiteColor;
  }

  .selected-color {
    margin-right: 0.5rem;
    border-radius: 50%;
    border: 1px solid $greyBorderColor;

    span {
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }
  }

  label {
    font-weight: $mediumFontWeight;
    font-size: $smallFontSize;
    text-transform: uppercase;
  }
}

.swatches-picker {
  position: absolute;
  right: 0;
  bottom: calc(100% + 1rem);
  background: $whiteColor;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba($darkBoxShadowColor, 0.5);
  border: 2px solid rgba(235, 235, 235, 0.7);
  border-radius: 1rem;
  z-index: 102;
  text-align: center;

  .vc-swatches {
    height: calc(70vh - 2.5rem);
    border-radius: 0.5rem;
  }

  .vc-chrome {
    overflow: hidden;
    width: 15rem;
    border-radius: 0.75rem;
    box-shadow: none;
  }

  /deep/ {
    .vc-input__label,
    .vc-chrome-toggle-btn {
      display: none;
    }

    .vc-input__input {
      font-size: $normalFontSize;
      padding: 0.5rem 0;
      border-radius: 0.5rem;
      font-size: $smallerFontSize;
      height: auto;
      box-shadow: none;
    }
  }
}
</style>