require("dotenv").config();
import axios from "axios";

//Importing Variable mixins
const apiURL = `${process.env.VUE_APP_API_URL}/chat`;

class ChatService {
  // Event: Get Inbox
  static GetInbox(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/inbox`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Get inbox categories
  static GetInboxCategories() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/inbox/categories`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Event: Get Chat
  static GetChat(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Event: Delete Chat
  static DeleteChat(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/delete`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Event: Mark chat unread
  static MarkUnread(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/mark-unread`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Event: Toggle flag
  static ToggleFlag(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/chat/toggle-flag`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Event: Toggle resolution
  static ToggleResolution(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/chat/toggle-resolution`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Get the insights of the conversations
  static GetInsights(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/get-analytics`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Get openAI token usage
  static GetTokenUsage(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/get-token-usage`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Delete single chat
  static GetShopifyCustomer(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/shopify/get-customer`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default ChatService;
