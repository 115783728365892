require("dotenv").config();
import axios from "axios";

//Importing Variable mixins
const apiURL = process.env.VUE_APP_API_URL;
import { io } from "socket.io-client";

class WidgetService {
  // Socket configuration
  static socket;
  constructor() {}

  // START: Socket Events
  // Setting up socket connections
  static SetupSocketConnection(data) {
    this.socket = io(apiURL, { transports: ["websocket"] });
    this.socket.emit("join-chat-room", data);
  }

  // Disconnecting socket
  static DisconnectSocketConnection() {
    if (this.socket) {
      this.socket.disconnect(this.socket);
    }
  }

  // Event when question asked
  static AskQuestion(data) {
    this.socket.emit("ask-question", data);
  }

  // Add event messages
  static AddEventMessage(data) {
    this.socket.emit("add-event", data);
  }

  // Restart chat event
  static RestartChat(data) {
    this.socket.emit("restart-chat", data);
  }

  // Reset the answer
  static ResetAnswer(data) {
    this.socket.emit("reset-answer", data);
  }

  // Continue chat event
  static ContinueChat(data) {
    this.socket.emit("continue-chat", data);
  }

  // Send AI chat history
  static sendAIChatHistory(data) {
    this.socket.emit("send-ai-chat-history", data);
  }

  // END: Socket Events

  // Save the customer details
  static GetChat(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/widget/get-chat`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  static GetWidget(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/widget/get`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Save the customer details
  static SaveCustomer(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/widget/save-customer`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Resolve the customer chat
  static ResolveChat(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/widget/resolve-chat`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Get an order
  static GetOrder(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/widget/manage-order/get`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Get the reasons for cancellations
  static GetCancellationReasons(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/widget/manage-order/get-cancellation-reasons`,
                data,
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Submit request for cancellation
  static RequestCancellation(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/widget/manage-order/request-cancellation`,
                data,
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default WidgetService;
