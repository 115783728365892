<template>
  <div v-if="!lodash.isEmpty(widget)" class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton"
        @click="saveWidget"
      >
        Save Changes
      </button>
    </div>
    <!--END: Header Wrapper-->

    <div ref="settings" class="settings-main" @scroll="settingsScroll">
      <!--START: Settings Form-->
      <SettingsForm
        v-for="field in fields"
        :key="field._id"
        :fields="field.fields"
        :content="field.content"
        @fieldChanged="fieldChanged"
      ></SettingsForm>
      <!--END: Settings Form-->
    </div>

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->

    <!--START: Unsaved Changes Modal -->
    <UnsavedChangesModal
      :show="showOptions.unsavedChanges"
      @primaryEvent="continueRoute"
      @secondaryEvent="closeModal"
    ></UnsavedChangesModal>
    <!--END: Unsaved Changes Modal -->
  </div>
</template>
              
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import SettingsForm from "@/components/form/SettingsForm";
import LineLoader from "@/components/loaders/LineLoader";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal";

export default {
  name: "WidgetActiveHours",
  data() {
    return {
      lodash: _,
      dataPopulated: false,
      showOptions: {
        headerShadow: false,
        lineLoader: false,
        unsavedChanges: false,
        disableButton: true,
      },
      regularHeader: {
        title: "Active Hours",
        description: "Control when you want the widget to be active",
      },
      fields: {
        availableHours: {
          content: {
            title: "Settings & Timings",
            description: "Control the time you want the widget to be active",
          },
          fields: {
            isActive: {
              type: "toggle",
              title: "Enable hours for the widget to be active",
              additionalClass: "large-toggle",
              value: false,
              alertMessage: {
                color: "yellow",
                message:
                  "The widget will not be shown during the specified hours, if you this is toggled on",
              },
            },
            availableDays: {
              type: "scheduler",
              title: "Select the schedule to show the Macha widget",
              additionalClass: "large-scheduler",
              required: true,
              show: false,
              hasError: false,
              hideLabel: true,
              uniqueSlots: [],
              days: [],
              value: [],
            },
            showHandoff: {
              type: "toggle",
              title: "When widget is inactive, show agent handoff option",
              additionalClass: "large-toggle",
              show: false,
              value: false,
            },
          },
        },
      },

      initData: {},
      dataChanged: false,
      status: {
        show: false,
        status: "success",
        title: "Active hours saved",
      },
    };
  },
  props: {
    widget: Object,
  },
  components: {
    RegularHeader,
    SettingsForm,
    LineLoader,
    UnsavedChangesModal,
  },
  computed: {},
  watch: {
    widget: {
      immediate: true,
      deep: true,
      handler() {
        this.populateData();
      },
    },
  },
  async created() {
    this.populateData();
  },
  methods: {
    // Save widget
    async saveWidget() {
      if (this.dataChanged) {
        const data = { ...this.fields.availableHours.fields };
        var isFormValid = this.validateForm(data);
        if (isFormValid) {
          const formData = this.constructPayload();
          await this.$emit("saveWidget", formData);
          this.dataPopulated = false;
        }
      }
    },

    // Populate the data for the fields
    populateData() {
      if (!this.dataPopulated) {
        this.dataPopulated = true;

        // App fields
        const { availableHours } = this.widget;
        const { fields } = this.fields.availableHours;

        if (!_.isEmpty(availableHours)) {
          fields.isActive.value = availableHours.isActive;

          // Convert the timeslots to local time from UTC
          const availableDays = _.cloneDeep(availableHours.availableDays);
          availableDays.forEach((day) => {
            day?.timeSlots.forEach((slot) => {
              const utcStartTime = this.moment.utc(slot.startTime, "HH:mm");
              const localStartTime = utcStartTime.local().format("HH:mm");

              const utcEndTime = this.moment.utc(slot.endTime, "HH:mm");
              const localEndTime = utcEndTime.local().format("HH:mm");

              slot.startTime = localStartTime;
              slot.endTime = localEndTime;
            });
          });
          fields.availableDays.value = availableDays;

          fields.showHandoff.value = availableHours.showHandoff;
        }

        this.handleFieldVisibility();
        this.initFormData();
      }
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = { ...this.fields };
      this.initData = _.cloneDeep(data);
      this.disableSaveButton(true);
    },

    // Construct the payload for the controller
    constructPayload() {
      const data = this.parseData(this.fields.availableHours.fields);
      const widget = { availableHours: data };

      // Create form data
      let formData = new FormData();
      for (var key in widget) formData.append(key, JSON.stringify(widget[key]));

      return formData;
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Event on settings scroll
    settingsScroll() {
      const scrollTop = this.$refs.settings.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    handleFieldVisibility() {
      const availableHoursFields = this.fields.availableHours.fields;
      Object.keys(availableHoursFields).forEach(function (key) {
        if (key !== "isActive") {
          availableHoursFields[key].show = availableHoursFields.isActive.value;
        }
      });
    },

    // Event when form field changes
    fieldChanged() {
      this.handleFieldVisibility();

      const data = { ...this.fields };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.unsavedChanges = false;
    },

    // Discard all the changes
    discardChanges() {
      this.fields = _.cloneDeep(this.initData);
      this.disableSaveButton(true);
      this.closeModal();
    },

    // Discard the changes and move to the selected route
    continueRoute() {
      this.closeModal();
      this.discardChanges();
      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
    },
  },
  // Check if there are any changes to be saved
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      this.toRoute = to;
      this.showOptions.unsavedChanges = true;
    } else {
      next();
    }
  },
};
</script>
              
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  margin-bottom: 0 !important;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.settings-main {
  overflow-y: scroll;
  padding: 1.5rem 1rem;
  flex: 1;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.script-tag-wrapper {
  .script-description {
    font-size: $smallFontSize;

    > code {
      padding: 0.15rem 0.35rem;
      background: darken($lightWhiteColor, 1.5%);
      border-radius: 0.25rem;
    }
  }

  .script-tag {
    code {
      font-family: monospace;
      color: darken($warningYellowColor, 0%);
      font-size: $smallFontSize;
      font-weight: $mediumFontWeight;
      padding: 1rem 1.5rem;
    }
  }
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}

.settings-form {
  padding-top: 1rem;
}

.handoff-app {
  display: block;
  font-size: $smallerFontSize;
  font-weight: $mediumFontWeight;
  text-transform: capitalize;
  text-align: right;
  margin-top: -2rem;

  &.handoff-unavailable {
    float: right;
    display: table;
    padding: 0.35rem 0.75rem;
    border-radius: 0.75rem;
    color: $redMessageTextColor;
    background-color: lighten($redMessageColor, 2.5%);
    border: 1px solid $redMessageBorderColor;

    a {
      color: $redMessageTextColor;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
</style>