<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button class="btn btn-small btn-grey" @click="addCategory(false)">
        Add Custom Reply
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Zero State -->
    <ZeroState
      v-if="customReplies.length == 0 && showOptions.initCustomReplies"
      class="zero-state"
      :imageURL="zeroStateData.imageURL"
      :title="zeroStateData.title"
      :message="zeroStateData.message"
      :buttonTitle="zeroStateData.buttonTitle"
      @eventHandler="addCustomReply()"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Question Categories Table-->
    <CustomRepliesTable
      v-else
      :customReplies="customReplies"
      @customReplySelected="addCustomReply"
      @deleteCustomReply="deleteCustomReply"
    ></CustomRepliesTable>
    <!--END: Question Categories Table-->

    <!--START: Loader-->
    <PageLoader
      v-if="!showOptions.initCustomReplies"
      :showLoader="true"
    ></PageLoader>
    <!--END: Loader-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
        
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import CustomRepliesTable from "@/components/dashboard/tables/CustomRepliesTable";
import ZeroState from "@/components/misc/ZeroState";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";

// Importing Services
import { CustomReplyService } from "@/services";

export default {
  name: "CustomReplies",
  data() {
    return {
      showOptions: {
        initCustomReplies: false,
        lineLoader: false,
      },
      regularHeader: {
        title: "Custom Replies",
        description:
          "Achieve complex replies and workflows for categories and intents",
      },
      zeroStateData: {
        imageURL: "services.jpg",
        title: "Add Custom Replies",
        message: "Add custom replies to create workflows for more control",
        buttonTitle: "Add Custom Reply",
      },
      status: {
        show: false,
        status: "success",
        title: "Custom Reply deleted",
      },
      customReplies: [],
      selectedCustomReplyID: "",
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    CustomRepliesTable,
    ZeroState,
    NotificationMessage,
    LineLoader,
    PageLoader,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },

    hasCompanyLoaded() {
      return !_.isEmpty(this.company);
    },
  },
  async created() {
    await this.getCustomReplies();
    this.showOptions.initCustomReplies = true;
  },
  methods: {
    async getCustomReplies() {
      const response = await CustomReplyService.GetAllReplies();
      if (!response.hasError) this.customReplies = response.data;
    },

    addCustomReply(customReplyID) {
      this.$router.push({
        path: `/automation/custom-replies/${customReplyID}`,
      });
    },

    customReplyAdded(customReply) {
      let tempCustomReplies = _.cloneDeep(this.customReplies);
      const index = tempCustomReplies.findIndex(
        (i) => i._id === customReply._id
      );

      if (index !== -1) tempCustomReplies[index] = customReply;
      else tempCustomReplies.push(customReply);

      this.customReplies.splice(0);
      this.customReplies = _.cloneDeep(tempCustomReplies);
    },

    async deleteCustomReply(customReplyID) {
      this.showOptions.lineLoader = true;

      const data = { customReplyID: customReplyID };
      await CustomReplyService.DeleteReply(data);

      // Remove from the table
      const index = this.customReplies.findIndex((s) => s._id == customReplyID);
      this.customReplies.splice(index, 1);

      this.status.title = "Custom reply deleted";
      this.showStatusMessage(this.status, 2500);
      this.showOptions.lineLoader = false;
    },

    closeModal() {},
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
</style>