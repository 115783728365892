<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton"
        @click="saveAiAgent"
      >
        Save Changes
      </button>
    </div>
    <!--END: Header Wrapper-->

    <div ref="settings" class="settings-main" @scroll="settingsScroll">
      <!--START: Settings Form-->
      <div class="settings-wrapper">
        <!--START: Form Content-->
        <div class="settings-form-wrapper">
          <SettingsForm
            :fields="personaFields.fields"
            :content="personaFields.content"
            @fieldChanged="fieldChanged"
          ></SettingsForm>
          <SettingsForm
            :fields="companyFields.fields"
            :content="companyFields.content"
            @fieldChanged="fieldChanged"
          ></SettingsForm>
        </div>
        <!--END: Form Content-->
      </div>
      <!--END: Settings Form-->
    </div>

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->

     <!--START: Notification Message-->
     <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Unsaved Changes Modal -->
    <UnsavedChangesModal
      :show="showOptions.unsavedChanges"
      @primaryEvent="continueRoute"
      @secondaryEvent="closeModal"
    ></UnsavedChangesModal>
    <!--END: Unsaved Changes Modal -->
  </div>
</template>
            
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import LineLoader from "@/components/loaders/LineLoader";
import SettingsForm from "@/components/form/SettingsForm";
import NotificationMessage from "@/components/modals/NotificationMessage";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal";

// Importing Services
import { AiAgentService } from "@/services";

export default {
  name: "AiPersona",
  props: {
    aiAgent: { type: Object, required: true },
  },
  computed: {},
  data() {
    return {
      title: "AI Persona",
      showOptions: {
        lineLoader: false,
        showShadow: false,
        preLoader: true,
        unsavedChanges: false,
        headerShadow: false,
        disableButton: true,
      },
      regularHeader: {
        title: "Persona",
        description: "Define the AI's personas when generating answers",
      },
      personaFields: {
        content: {
          title: "Agent Characteristics",
          description:
            "This persona is how the AI agent will interact with customers",
        },
        fields: {
          thumbnailURL: {
            type: "profile-image",
            title: "Profile image",
            fileList: [],
            imagesList: [],
            showPreview: true,
            show: true,
            cropType: "auto",
            required: false,
            hasError: false,
            value: "",
          },
          name: {
            type: "text",
            title: "Name of your AI agent",
            required: true,
            hasError: false,
            placeholder: "Add a persona name",
            value: "",
          },
          designation: {
            type: "text",
            title: "Designation of your AI agent",
            required: true,
            hasError: false,
            placeholder: "Add a designation",
            value: "",
          },
          characteristics: {
            type: "textarea",
            placeholder: "Describe the characteristics of the persona",
            required: true,
            hasError: false,
            maxlength: 250,
            title: "Characteristics of your AI agent",
            value: "",
          },
          responseTone: {
            type: "dropdown",
            placeholder: "Select tone",
            title: "Tonality of the answers",
            required: true,
            hasError: false,
            fields: [
              { name: "Normal", value: "normal" },
              { name: "Formal", value: "formal" },
              { name: "Friendly", value: "friendly" },
              { name: "Casual", value: "casual" },
              { name: "Funny", value: "funny" },
            ],
            value: [],
          },
          responseLength: {
            type: "dropdown",
            placeholder: "Select length",
            title: "Length of the answers",
            required: true,
            hasError: false,
            fields: [
              { name: "Normal", value: "normal" },
              { name: "Brief", value: "brief" },
              { name: "Detailed", value: "detailed" },
            ],
            value: [],
          },
        },
      },
      companyFields: {
        content: {
          title: "Company",
          description: "Details of the company the AI agent represents",
        },
        fields: {
          name: {
            type: "text",
            title: "Name of the company the agent represents",
            required: true,
            hasError: false,
            placeholder: "Add a company name",
            value: "",
          },
          description: {
            type: "textarea",
            placeholder: "Add a company description",
            required: false,
            hasError: false,
            maxlength: 250,
            title: "Describe the company for the agent",
            value: "",
          },
          industry: {
            type: "text",
            title: "Industry the company belongs to",
            required: false,
            hasError: false,
            placeholder: "Add the industry name",
            value: "",
          },
        },
      },
      initData: {},
      dataChanged: false,

      status: {
        show: false,
        status: "success",
        title: "AI agent saved",
      },
      saveInProgress: false,
    };
  },
  components: {
    RegularHeader,
    LineLoader,
    SettingsForm,
    NotificationMessage,
    UnsavedChangesModal,
  },
  async created() {
    this.populateFields();
  },

  methods: {
    // Save AI agent
    async saveAiAgent() {
      if (!this.saveInProgress) {
        this.saveInProgress = true;
        this.showOptions.lineLoader = true;

        // Check if the form has valid input
        const data = {
          ...this.personaFields.fields,
          ...this.companyFields.fields,
        };
        var isFormValid = this.validateForm(data);
        if (isFormValid) {
          const formData = this.constructPayload();
          const response = await AiAgentService.SaveAiAgent(formData);

          // Check for errors
          if (!response.hasError) {
            await this.$emit("aiAgentSaved", response.data);

            this.populateFields();
            this.showStatusMessage(this.status, 2500);
          } else this.showErrorMessage(this.status, response.message);
        }

        this.showOptions.lineLoader = false;
        this.saveInProgress = false;
      }
    },

    // Populate the fields
    populateFields() {
      const { persona, company } = this.aiAgent;

      const {
        name,
        characteristics,
        designation,
        responseTone,
        responseLength,
        thumbnailURL,
      } = this.personaFields.fields;

      name.value = persona.name;
      thumbnailURL.value = persona.thumbnailURL;
      designation.value = persona.designation;
      characteristics.value = persona.characteristics;

      responseTone.value = [
        this.toTitleCase(persona.responseTone),
        persona.responseTone,
      ];
      responseLength.value = [
        this.toTitleCase(persona.responseLength),
        persona.responseLength,
      ];

      const {
        name: companyName,
        description,
        industry,
      } = this.companyFields.fields;
      companyName.value = company.name;
      description.value = company.description;
      industry.value = company.industry;

      this.initFormData();
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = {
        persona: this.personaFields.fields,
        company: this.companyFields.fields,
      };

      this.disableSaveButton(true);
      this.initData = _.cloneDeep(data);
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Update persona when fields change
    fieldChanged() {
      const data = {
        persona: this.personaFields.fields,
        company: this.companyFields.fields,
      };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Construct the payload for the controller
    constructPayload() {
      let data = {
        persona: this.parseData(this.personaFields.fields),
        company: this.parseData(this.companyFields.fields),
      };

      const { thumbnailURL } = this.personaFields.fields;
      data.persona.thumbnailURL = thumbnailURL.imagesList[0]
        ? thumbnailURL.imagesList[0]
        : data.thumbnailURL;

      data = this.sanitizeObject(data);

      let formData = new FormData();
      for (var key in data) formData.append(key, JSON.stringify(data[key]));

      // Add company profile picture
      if (thumbnailURL.fileList.length) {
        formData.append("images", thumbnailURL.fileList[0]);
        formData.append("thumbnailAdded", true);

        delete data.persona.thumbnailURL;
      }

      return formData;
    },

    // Event on settings scroll
    settingsScroll() {
      const scrollTop = this.$refs.settings.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.unsavedChanges = false;
    },

    // Discard all the changes
    discardChanges() {
      this.personaFields.fields = _.cloneDeep(this.initData.personaFields);
      this.disableSaveButton(true);
      this.closeModal();
    },

    // Discard the changes and move to the selected route
    continueRoute() {
      this.closeModal();
      this.discardChanges();
      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
    },
  },
  // Check if there are any changes to be saved
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      this.toRoute = to;
      this.showOptions.unsavedChanges = true;
    } else {
      next();
    }
  },
};
</script>
            
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.header-wrapper {
  padding-bottom: 1.5rem;
  margin-bottom: 0 !important;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.settings-main {
  overflow-y: scroll;
  padding: 1.5rem 1rem;
  flex: 1;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

/deep/ .profile-image-cropper {
  width: 45vw;
}
</style>