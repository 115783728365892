<template>
  <div>
    <NestedDraggable
      :questions="questions"
      @questionSelected="showQuestion"
      @deleteQuestion="confirmQuestionDelete"
      @itemsMoved="constructSuggestedQuestions"
    ></NestedDraggable>
    <button class="btn btn-text btn-small btn-no-padding" @click="addQuestion">
      <unicon name="plus"></unicon>
      <span>Add question</span>
    </button>

    <!--START: Add Question modal-->
    <ViewQuestion
      v-if="showOptions.addQuestion"
      :question="selectedQuestion"
      :categories="categories"
      @questionUpdated="questionUpdated"
      @closeModal="closeModal"
    ></ViewQuestion>
    <!--END: Add Question modal-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->
  </div>
</template>
  
<script>
// Import components
import NestedDraggable from "./NestedDraggable";
import ViewQuestion from "./ViewQuestion";
import ConfirmationModal from "@/components/modals/ConfirmationModal";

// Importing Services
import { WidgetSettingsService } from "@/services";

export default {
  name: "SuggestedQuestions",
  data() {
    return {
      initComplete: false,
      showOptions: {
        addQuestion: false,
      },
      selectedQuestion: {},
      questions: [],
      deleteID: null,
      confirmationFields: {
        show: false,
        title: "",
        type: "delete",
        modalType: "fixed",
        message: "This action cannot be undone. This rule will be removed.",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  props: { suggestedQuestions: Array },
  watch: {
    suggestedQuestions: {
      immediate: true,
      deep: true,
      handler() {
        this.initQuestions();
      },
    },
  },
  components: {
    NestedDraggable,
    ConfirmationModal,
    ViewQuestion,
  },
  computed: {
    categories() {
      const response = [];
      const { question: selectedQuestion } = this.selectedQuestion;

      this.questions.forEach((q) => {
        if (selectedQuestion?.question !== q.question.question)
          response.push(q.question.question);
      });

      return response;
    },
  },
  async created() {},
  methods: {
    // Initialise the questions
    initQuestions() {
      this.questions.splice(0);

      const categoryQuestions = [];
      this.suggestedQuestions.forEach((q) => {
        if (!q.category) this.questions.push({ question: q, questions: [] });
        else categoryQuestions.push(q);
      });

      this.questions.forEach((q) => {
        var extractedQuestions = categoryQuestions.filter((cq) => {
          return cq.category === q.question.question;
        });

        extractedQuestions.forEach((eq) => {
          q.questions.push({ question: eq, questions: [] });
        });
      });

      if (!this.initComplete) {
        this.initComplete = true;
        this.$emit("initCompleted");
      }
    },

    // Construct suggested questions
    constructSuggestedQuestions() {
      this.questions.forEach((category) => {
        category.question.category = null;
        category.questions.forEach((q) => {
          q.question.category = category.question.question;
        });
      });

      this.$emit("itemsMoved", this.questions);
    },

    // Update the question in the questions array
    questionUpdated(question) {
      this.$emit("questionUpdated", question);
    },

    // Confirm deleting suggested question
    confirmQuestionDelete(question) {
      this.showDeleteModal(question._id, question.question);
    },

    // Delete the suggested question
    deleteQuestion(questionID) {
      WidgetSettingsService.DeleteSuggestedQuestion({ questionID });
    },

    // Show the delete modal
    showDeleteModal(itemID, itemTitle) {
      this.deleteID = itemID;
      this.confirmationFields.confirmValue = itemTitle;
      this.confirmationFields.show = true;
      this.confirmationFields.title = `Delete "${itemTitle}"?`;
    },

    // Delete the item
    async deleteItem() {
      this.deleteQuestion(this.deleteID);
      this.$emit("deleteSuggestedQuestion", this.deleteID);
      this.closeModal();
    },

    // Question has been selected
    showQuestion(question) {
      this.selectedQuestion = question;
      this.addQuestion();
    },

    // Show the add question modal
    addQuestion() {
      this.showOptions.addQuestion = true;
    },

    // Close all modals
    closeModal() {
      this.selectedQuestion = {};
      this.confirmationFields.show = false;
      this.showOptions.addQuestion = false;
    },
  },
};
</script>
      
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.btn {
  float: right;
  margin-top: 1.5rem;
}
</style>