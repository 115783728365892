<template>
  <div class="message-form card">
    <!--START: Intro-->
    <div class="intro-wrapper">
      <h3>{{ messageData.form.title }}</h3>
      <p v-html="messageData.form.description"></p>
    </div>
    <!--END: Intro-->

    <DynamicForm
      class="form"
      :fields="messageData.dataModel.fields"
      :messageData="messageData"
      @saveData="saveForm"
    ></DynamicForm>
  </div>
</template>
    
<script>
// Importing components
import DynamicForm from "./Forms/DynamicForm";

export default {
  name: "MessageForm",
  data() {
    return {};
  },
  props: {
    messageData: { type: Object, required: true },
  },
  watch: {},
  components: { DynamicForm },
  computed: {},
  created() {},
  methods: {
    // Event to save the data
    saveForm(payload) {
      this.$emit("saveForm", payload);
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.intro-wrapper {
  margin-bottom: 0.75rem;

  h3 {
    color: lighten($darkBlackColor, 15%);
    font-size: $normalFontSize;
    margin-bottom: 0;
  }

  p {
    margin: 0.5rem 0 1rem;
    font-size: $smallFontSize;
    color: $paragraphColor;
  }
}

.message-form.card {
  margin: 1rem 0;
  padding: 1.25rem;
  width: 45%;
  float: right;
}

.form /deep/ {
  .field-main-item {
    margin-bottom: 0.75rem;
  }

  .field-title {
    font-size: $smallestFontSize;
  }

  .btn-primary {
    display: block;
    width: 100%;
    font-size: $smallerFontSize;

    &:hover {
      span {
        color: var(--customer-text-color);
      }
      background: var(--customer-bg-color);
    }
  }
}

.read-only {
  /deep/ {
    input,
    textarea {
      font-size: $smallFontSize;
      border-radius: 0;
    }
  }
}
</style>