<template>
  <div>
    <!--START: Message Details-->
    <div class="message-details">
      <span class="message-time-wrapper"
        >{{ widgetLanguage.chat.messages.sentLabel }}
        <span class="message-time">
          {{ getMessageTime(message.timestamp) }}
        </span>
      </span>
      <span class="message-ai"
        >{{ widgetLanguage.chat.messages.aiLabel }}
        <svg
          :data-src="require('@/assets/images/icons/ai-sparkle.svg')"
          height="12px"
          width="12px"
          class="ai-icon"
        ></svg>
      </span>
    </div>
    <!--END: Message Details-->
    <div class="message" :class="message.messageType">
      <p v-html="message.message"></p>
      <div class="options-wrapper">
        <button
          v-for="bubble in message.messageData.answerBubbles.bubbles"
          :key="bubble.id"
          class="option-button"
          @click="saveAnswer(bubble.message, bubble.value)"
        >
          {{ bubble.message }}
        </button>
      </div>
    </div>
  </div>
</template>
      
<script>
export default {
  name: "ChatMessage",
  data() {
    return {};
  },
  props: {
    message: { type: Object, required: true },
  },
  watch: {},
  components: {},
  computed: {
    // Widget language
    widgetLanguage() {
      return this.languages[this.$store.getters.widgetLanguage].widget;
    },
  },
  created() {},
  methods: {
    // Time for the message
    getMessageTime(responseTime) {
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      if (responseTime)
        return new Date(responseTime)
          .toLocaleTimeString([], options)
          .toUpperCase();
      return new Date().toLocaleTimeString([], options).toUpperCase();
    },

    // Select a bubble value
    saveAnswer(message, value) {
      const modelFields = this.message.messageData.dataModel.fields;

      const chatMessage = {
        message,
        messageType: "question",
        senderRole: "customer",
        timestamp: new Date(),
      };

      const payload = {
        [modelFields[0].key]: value,
      };
      this.$emit("saveAnswer", chatMessage, payload);
    },
  },
};
</script>
          
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/chat.scss";

.option-button {
  display: block;
  border: none;
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.25rem;
  background: lighten($purpleColor, 10%);
  border: 1px solid lighten($purpleColor, 12.5%);
  color: $whiteColor;
  cursor: pointer;

  &:first-child {
    margin-top: 1rem;
  }
}
</style>