<template>
  <div class="main-wrapper">
    <!--START: Status Message-->
    <SuccessForm
      :successForm="successForm"
      @primaryEvent="gotoDashboard"
    ></SuccessForm>
    <!--END: Status Message-->
  </div>
</template>
  
<script>
// Importing components
import SuccessForm from "@/components/modals/SuccessForm";

export default {
  name: "OauthSuccess",
  data() {
    return {
      successForm: {
        show: true,
        status: "success",
        title: "Zendesk Acount Connected",
        message: "Your Zendesk account is now connected",
        primaryBtn: { title: "Goto Macha Dashboard" },
      },
    };
  },
  components: { SuccessForm },
  methods: {
    gotoDashboard() {
      window.open(
        `${process.env.VUE_APP_API_URL}/settings/integrations`,
        "_blank"
      );
      window.setTimeout(function () {
        this.close();
      }, 0);
    },
  },
};
</script>
  
<style scoped lang="scss">
.main-wrapper {
  margin-top: 3rem;
}
</style>