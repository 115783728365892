<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <DateSelector @dateRangeUpdated="dateRangeUpdated"></DateSelector>
    </div>
    <!--END: Header Wrapper-->

    <div
      v-if="!fetchingInsights"
      ref="insights"
      class="insights-main"
      @scroll="insightsScroll"
    >
      <ViewSourceInsights :insights="insights"></ViewSourceInsights>
    </div>

    <!--START: Pre Loader-->
    <div v-else class="pre-loader-wrapper">
      <PreLoader :show="true" v-for="index in 2" :key="index"></PreLoader>
    </div>
    <!--END: Pre Loader-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
            
  <script>
// Imoprt libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import DateSelector from "@/components/dashboard/analytics/DateSelector";
import NotificationMessage from "@/components/modals/NotificationMessage";
import PreLoader from "@/components/loaders/PreLoader";
import LineLoader from "@/components/loaders/LineLoader";

import ViewSourceInsights from "@/components/dashboard/analytics/ViewSourceInsights";

// Importing Services
import { DocumentService } from "@/services";

// Importing Services
import "external-svg-loader";

export default {
  name: "SourcesInsights",
  data() {
    return {
      lodash: _,
      showOptions: {
        headerShadow: false,
        unsavedChanges: false,
        lineLoader: false,
        disableButton: true,
      },
      startDate: this.moment().subtract(1, "months").toDate(),
      endDate: this.moment().toDate(),
      fetchingInsights: true,
      insights: {},
      regularHeader: {
        title: "Sources",
        description:
          "Details around the sources the AI has used to answer questions",
      },

      status: {
        show: false,
        status: "success",
        title: "Widget theme saved",
      },
    };
  },
  props: {},
  components: {
    RegularHeader,
    ViewSourceInsights,
    DateSelector,
    NotificationMessage,
    LineLoader,
    PreLoader,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  watch: {},
  async created() {
    await this.getInsights();
  },
  methods: {
    // Get insights
    async getInsights() {
      this.fetchingInsights = true;
      const data = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      const response = await DocumentService.GetSourceInsights(data);
      if (!response.hasError) this.insights = response.data;

      this.fetchingInsights = false;
    },

    // Refresh the insights
    refreshInsights(dateRange) {
      this.dateFilter = dateRange;
      this.getInsights();
    },

    // Event on settings scroll
    insightsScroll() {
      const scrollTop = this.$refs.insights.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Date range has been updated
    dateRangeUpdated(startDate, endDate) {
      this.startDate = startDate;
      this.endDate = endDate;
      this.refreshInsights();
    },
  },
};
</script>
            
<style scoped lang="scss">
.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.insights-main {
  overflow-y: scroll;
  padding: 1.5rem 1rem 0;
  flex: 1;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}

.pre-loader-wrapper {
  flex: 1;
  margin: 5rem;

  /deep/ {
    .buffer-line {
      width: auto;
    }
    .buffer-category {
      display: none;
    }

    .pre-loader:first-child {
      margin-bottom: 2rem;
      .buffer-category {
        display: block;
      }
    }
  }
}

/deep/ .line-loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
</style>