<template>
  <div class="token-usage-wrapper">
    <div class="usage-header">
      <!--START: Total Conversations-->
      <div class="intro-wrapper">
        <h3>Overall token usage</h3>
      </div>
      <!--END: Total Conversations-->

      <!--START: Overall Usage-->
      <OverallUsage :usage="tokenUsage.overallUsage"></OverallUsage>
      <!--END: Overall Usage-->
    </div>

    <!--START: Usage Catgories-->
    <div class="usage-category-wrapper">
      <div class="usage-title">
        <svg
          :data-src="require('@/assets/images/icons/ai-sparkle.svg')"
          height="15px"
          width="15px"
          class="ai-icon"
        ></svg>
        <h3>Tokens used from</h3>
        <div class="date-range">
          <b>{{ moment(this.startDate).format("Do MMMM YYYY") }}</b>
          to
          <b>{{ moment(this.endDate).format("Do MMMM YYYY") }}</b>
        </div>
      </div>
      <div class="categories-wrapper">
        <ModelUsage
          title="Overall usage by model"
          :usage="tokenUsage.usageByModel"
        ></ModelUsage>
        <ModelUsage
          title="Average usage per chat"
          :usage="tokenUsage.usageByConversation"
        ></ModelUsage>
      </div>
    </div>
    <!--END: Usage Catgories-->

    <!--START: Day Usage-->
    <div class="daily-usage-wrapper">
      <div class="usage-title">
        <unicon name="calendar-alt"></unicon>
        <h3>Daily usage from</h3>
        <div class="date-range">
          <b>{{ moment(this.startDate).format("Do MMMM YYYY") }}</b>
          to
          <b>{{ moment(this.endDate).format("Do MMMM YYYY") }}</b>
        </div>
      </div>

      <GChart
        v-if="dailyUsageData[0].length > 1"
        class="bar-chart"
        type="ColumnChart"
        :data="dailyUsageData"
        :options="chartOptions"
      />
      <span v-else class="disclaimer">Data after August 3rd, 2023 will show up here</span>
    </div>
    <!--END: Day Usage-->
  </div>
</template>
    
<script>
// Import libraries
import { GChart } from "vue-google-charts/legacy";

// Import components
import OverallUsage from "./OverallUsage";
import ModelUsage from "./ModelUsage";

export default {
  name: "ViewTokenUsage",
  props: {
    tokenUsage: { type: Object, required: true },
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
  },
  data() {
    return {
      chartOptions: {
        chartArea: { width: "90%", height: "80%" },
        bar: { groupWidth: "80%" },
        legend: {
          position: "top",
          alignment: "end",
          textStyle: {
            fontSize: 14,
            color: this.pSBC(0.15, "#464558", false, true),
          },
        },
        vAxis: {
          gridlines: { color: "#ECECEC" },
          baselineColor: this.pSBC(-0.25, "#ECECEC", false, true),
          textStyle: {
            color: this.pSBC(0.35, "#464558", false, true),
            fontSize: 12,
          },
        },
        hAxis: {
          textStyle: {
            color: this.pSBC(0.35, "#464558", false, true),
            fontSize: 10,
          },
        },
        series: {
          0: { color: "#4e45dd" },
          1: { color: this.pSBC(0.15, "#4e45dd", "#ff4eb9", true) },
          2: { color: this.pSBC(-0.25, "#4e45dd", "#ff4eb9", true) },
          3: { color: this.pSBC(-0.5, "#4e45dd", "#ff4eb9", true) },
          4: { color: this.pSBC(-0.65, "#4e45dd", "#ff4eb9", true) },
          5: { color: this.pSBC(-0.75, "#4e45dd", "#ff4eb9", true) },
        },
      },
    };
  },
  components: { OverallUsage, ModelUsage, GChart },
  computed: {
    // Construct the array to display the chart
    dailyUsageData() {
      const { dailyUsage } = this.tokenUsage;

      // Construct the columns array
      const columns = [];
      dailyUsage.forEach((day) => {
        day.usage.forEach((model) => {
          if (!columns.includes(model.model)) columns.push(model.model);
        });
      });

      // Construct the data
      const data = [];
      dailyUsage.forEach((day) => {
        const modelData = [];
        columns.forEach((c) => {
          let model = day.usage.find((m) => m.model === c);
          if (model) modelData.push(model.tokensUsed);
          else modelData.push(0);
        });

        data.push([this.moment(day.date).format('MMM D'), ...modelData]);
      });

      columns.unshift("Date");
      data.unshift(columns);
      return data;
    },
  },
  async created() {},
  methods: {},
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.token-usage-wrapper {
  flex: 1;
}
.intro-wrapper {
  h3 {
    font-size: $largestFontSize;
    margin-bottom: 0;
  }
}

/deep/ {
  .bar-style {
    border-radius: 0.5rem;
  }
  .stat-item {
    text-align: center;
  }
  .stat {
    font-size: $extraExtraLargeFontSize;
  }
  .stat-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
      font-size: $smallerFontSize;
      opacity: $lightOpacity;
    }
    .stat-info {
      position: relative;
      margin-left: 0.5rem;

      span {
        cursor: pointer;
        display: inline-block;
        font-weight: bold;
        font-size: 8px;
        padding: 1px;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: darken($whiteColor, 20%);
        display: block;
      }

      p {
        display: none;
        position: absolute;
        font-size: $smallestFontSize;
        top: calc(100% + 0.5rem);
        left: 50%;
        transform: translateX(-50%);
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: $darkBlackColor;
        color: $whiteColor;
        width: 10rem;
        z-index: 5;
      }

      span:hover + p {
        display: block;
      }
    }
  }
}

.usage-category-wrapper {
  margin-top: 4rem;
  margin-bottom: 2rem;

  .categories-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .category-insights {
    flex: 1;

    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      margin-left: 1rem;
    }
  }
}

.usage-title {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 1rem;

  /deep/ svg {
    fill: $secondaryColor;
    width: 15px;
    height: auto;
  }

  .unicon /deep/ svg {
    width: 18px;
    transform: translateY(2px);
  }

  h3 {
    font-size: $mediumFontSize;
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  .date-range {
    margin-left: 0.5rem;
    opacity: $lightOpacity;

    b {
      font-weight: $mediumFontWeight;
    }
  }
}

.conversation-metric {
  margin-bottom: 2rem;

  span {
    display: block;
    color: $paragraphColor;
    opacity: $lightOpacity;
    margin-bottom: 0.5rem;
  }

  b {
    font-size: $largerFontSize;
  }
}

.bar-chart {
  height: 45vh;
}
.disclaimer {
  font-size: $smallFontSize;
  opacity: .75;
}
</style>
