<template>
  <div v-if="showComponent" class="authentication-component">
    <!--START: Vue Headful-->
    <vue-headful :title="meta.title" />
    <!--END: Vue Headful-->

    <div class="authentication-wrapper">
      <div class="card">
        <!--START: Illustration-->
        <img
          src="@/assets/images/doodles/welcome.png"
          class="card-illustration"
          alt="Welcome to Macha"
        />
        <!--END: Illustration-->

        <!--START: Intro-->
        <div class="intro-wrapper">
          <img src="@/assets/images/logo.png" class="logo" alt="Macha Logo" />
          <h1>Hello there, welcome</h1>
          <p>Login to your account</p>
        </div>
        <!--END: Intro-->

        <!--START: Status Message-->
        <StatusMessage
          :show="status.show"
          :status="status.status"
          :message="status.message"
        ></StatusMessage>
        <!--END: Status Message-->

        <!--START: Form Wrapper-->
        <form v-on:submit.prevent="loginUser()">
          <FormBuilder
            :fields="fields"
            @fieldChanged="fieldChanged()"
          ></FormBuilder>
          <button type="submit" class="btn btn-primary">
            <span>Sign In</span>
            <unicon name="angle-right-b"></unicon>
          </button>
        </form>
        <!--END: Form Wrapper-->

        <!--START: Line Loader-->
        <LineLoader :show="showLoader" additionalClass="bottom"></LineLoader>
        <!--END: Line Loader-->
      </div>

      <!--START: Alternate Option-->
      <div class="alternate-option">
        <p>Don't have an account?</p>
        <router-link to="/sign-up">Create your account</router-link>
      </div>
      <!--END: Alternate Option-->
    </div>
  </div>
</template>

<script>
//Importing services
import { AuthService } from "@/services";

//Importing components
import LineLoader from "@/components/loaders/LineLoader";
import FormBuilder from "@/components/form/FormBuilder";
import StatusMessage from "@/components/modals/StatusMessage";

export default {
  name: "loginUser",
  data() {
    return {
      showComponent: false,
      showLoader: false,
      meta: {
        title: "Sign In",
      },
      status: {
        show: false,
        status: null,
        message: null,
      },
      fields: {
        email: {
          type: "email",
          placeholder: "E-mail",
          required: true,
          hasError: false,
        },
      },
    };
  },
  watch: {
    $route(to) {
      if (to.name === "Login") {
        this.showComponent = false;
        this.status.show = false;
        this.showLoader = false;

        this.initRoute();
      }
    },
  },
  components: {
    LineLoader,
    FormBuilder,
    StatusMessage,
  },
  async created() {
    await this.initRoute();
  },
  methods: {
    async initRoute() {
      if (await this.authenticateUser()) {
        this.redirectDashboard();
      } else {
        this.showComponent = true;
      }
    },
    async loginUser() {
      // Hide status message and show line loader
      this.status.show = false;
      this.showLoader = true;

      // Check if the form has valid input
      var isFormValid = this.validateForm(this.fields);
      if (isFormValid) {
        // Use the service to authenticate the user
        const response = await AuthService.GetEmailOTP(
          this.parseData(this.fields)
        );

        if (response.hasError) {
          this.status.status = "error";
          this.status.message = response.message;
        } else if (!response.hasError) {
          this.status.message = response.message;
          this.redirectUser(this.parseData(this.fields));
          this.resetFields(this.fields);
        }
        this.status.show = true;
      }
      // Hide line loader
      this.showLoader = false;
    },

    // Detech where to redirect a user
    redirectUser(data) {
      this.$store.commit("updateUser", {
        user: { ...data },
        loggedIn: false,
      });

      this.$router.push({ path: "/authenticate-login" });
    },

    fieldChanged() {
      this.status.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/authentication.scss";
</style>