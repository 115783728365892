<template>
  <div class="dashboard-view">
    <div class="page-wrapper">
      <!--START: Menu-->
      <PageMenu
        :menu="$route.meta.helpdeskRoute ? helpdeskMenu : chatMenu"
        :highlightExactRoute="false"
        class="page-menu"
      ></PageMenu>
      <!--END: Menu-->

      <!--START: Settings View-->
      <div class="page-content">
        <keep-alive><router-view></router-view></keep-alive>
      </div>
      <!--END: Settings View-->
    </div>
  </div>
</template>
  
<script>
// Import libraries
import _ from "lodash";

//Importing components
import PageMenu from "@/components/dashboard/PageMenu";

export default {
  name: "Analytics",
  data() {
    return {
      lodash: _,
      reloadView: false,
      showOptions: {
        lineLoader: false,
      },
      fetchingInsights: true,
      insights: {},
      chatMenu: [
        {
          name: "Analytics",
          type: "category",
          menuItems: [
            {
              name: "Insights",
              icon: "chart-line",
              path: "/analytics/insights",
            },
            {
              name: "Sources",
              icon: "copy-alt",
              path: "/analytics/sources",
            },
            {
              name: "Token Usage",
              icon: "analytics",
              path: "/analytics/token-usage",
            },
          ],
        },
      ],
      helpdeskMenu: [
        {
          name: "Analytics",
          type: "category",
          menuItems: [
            {
              name: "Insights",
              icon: "chart-line",
              path: "/helpdesk/analytics/insights",
            },
          ],
        },
      ],
      status: {
        show: false,
        status: "success",
        title: "Insights retrieved",
      },
    };
  },
  components: {
    PageMenu,
  },
  computed: {},
  async created() {
    this.restrictAdminAccess();
  },
  methods: {},
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ .page-header {
  margin-bottom: 3rem;
}
</style>