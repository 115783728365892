<template>
  <div>
    <span class="fulfillment-number"
      >{{ languages[$store.getters.widgetLanguage].widget.chat.orderDetails.shipmentLabel }}
      {{ index + 1 }}</span
    >
    <!--START: Shipping Status-->
    <ShippingStatus :shipping="fulfillment"></ShippingStatus>
    <!--END: Shipping Status-->
    <div
      class="fulfillment card"
      :class="{ 'tracking-button': fulfillment.trackingURL }"
    >
      <!--START: Items-->
      <div class="item-wrapper">
        <OrderItem
          v-for="item in fulfillment.items"
          :key="item.id"
          :item="item"
        >
        </OrderItem>
      </div>
      <!--END: Items-->

      <!--START: Logistics Partner-->
      <div class="logistics-wrapper">
        <div class="logistics-partner">
          <unicon name="truck"></unicon>
          <div class="logistics-info">
            <label>{{
              languages[$store.getters.widgetLanguage].widget.chat.orderDetails.logisticsPartnerLabel
            }}</label>
            <span class="name">{{ fulfillment.company }}</span>
            <a
              :href="fulfillment.trackingURL"
              target="_blank"
              class="tracking-link"
              >{{ fulfillment.trackingNumber }}</a
            >
          </div>
        </div>
        <a
          v-if="fulfillment.trackingURL"
          :href="fulfillment.trackingURL"
          target="_blank"
          class="btn btn-small btn-grey btn-track"
        >
          <span>{{
            languages[$store.getters.widgetLanguage].widget.chat.orderDetails.trackButton
          }}</span></a
        >
      </div>
      <!--END: Logistics Partner-->
    </div>
  </div>
</template>
    
<script>
// Importing components
import OrderItem from "./OrderItem";
import ShippingStatus from "./ShippingStatus";

export default {
  name: "FulfillmentDetails",
  data() {
    return {};
  },
  props: {
    index: { type: Number, required: true },
    fulfillment: { type: Object, required: true },
  },
  watch: {},
  components: { OrderItem, ShippingStatus },
  computed: {},
  created() {},
  methods: {
    // Get the total cost of the items
    getItemTotalCost(item) {
      const quantity = item.quantity;
      const { amount, currency_code } = item.price_set.presentment_money;

      const totalAmount = `${this.getCurrency(currency_code)}${(
        quantity * parseInt(amount)
      ).toLocaleString()}`;

      return totalAmount;
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/chat.scss";
.card {
  margin-top: 0 !important;
  padding: 0.75rem !important;
  border-radius: 0.5rem !important;
  box-shadow: none !important;
  overflow: hidden;
  border: 1px solid darken($cardBorderColor, 5%) !important;

  &.tracking-button {
    padding-bottom: 3.5rem !important;
  }
}

.fulfillment-number {
  display: block;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  color: lighten($darkBlackColor, 45%);
  text-transform: uppercase;
  letter-spacing: 0.015rem;
  margin: 1.25rem 0 0.5rem;
}

.logistics-partner {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 1.5rem;

  .unicon /deep/ {
    svg {
      fill: darken($whiteColor, 45%);
      padding: 2px;
      height: auto;
      width: 1rem;
    }
  }

  .logistics-info {
    flex: 1;
    margin-left: 1rem;

    label {
      display: block;
      color: lighten($darkBlackColor, 35%);
      font-size: $smallestFontSize;
      font-weight: $mediumFontWeight;
      margin: -0.15rem 0 0.15rem;
    }

    .name {
      color: $darkBlackColor;
      font-weight: $mediumFontWeight;
      font-size: $smallFontSize;
      margin-right: 1rem;
    }

    .tracking-link {
      color: $blueColor;
      font-size: $smallFontSize;
    }
  }
}

.btn-track {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: calc(100% - 1.5rem);
  border: none;
  border-top: 1px solid $greyBorderColor;
  border-radius: 0 !important;
}
</style>