<template>
  <div v-if="showComponent">
    <!--START: Vue Headful-->
    <vue-headful :title="meta.title" :description="meta.description" />
    <!--END: Vue Headful-->

    <!--START: Dashboard Header-->
    <DashboardHeader
      v-if="!$route.meta.hideHeader"
      @toggleCompany="toggleCompany"
    ></DashboardHeader>
    <!--END: Dashboard Header-->

    <!--START: Router View-->
    <router-view id="dashboard-main" :key="$route.fullPath"></router-view>
    <!--END: Router View-->

    <!--START: Subscription-->
    <SubscriptionBanner v-if="showSubscriptionBanner"></SubscriptionBanner>
    <!--END: Subscription-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
  
<script>
// Import components
import DashboardHeader from "@/components/dashboard/Header";
import SubscriptionBanner from "@/components/dashboard/SubscriptionBanner";
import LineLoader from "@/components/loaders/LineLoader";

// Importing services
import { AuthService } from "@/services";

export default {
  name: "Dashboard",
  data() {
    return {
      showComponent: false,
      showOptions: {
        lineLoader: false,
      },
      meta: {
        title: "Dashboard - Macha",
        description: "",
      },
    };
  },

  components: {
    DashboardHeader,
    SubscriptionBanner,
    LineLoader,
  },

  computed: {
    company() {
      return this.$store.getters.company;
    },

    showSubscriptionBanner() {
      return this.company?.subscription?.status !== "active";
    },
  },

  watch: {
    $route(to) {
      if (!to.meta?.authRoute) this.checkAccess();
      if (to.name === "Logout") this.logoutUser();
    },
  },

  async created() {
    await this.checkAccess();
  },
  methods: {
    // Check if dashboard access can be granted
    async checkAccess() {
      // Set body class to dashboard
      document.body.className = "dashboard";

      // Check to see if the staff is authenticated
      if (await this.authenticateUser()) {
        this.showComponent = true;
      } else {
        this.showComponent = false;
        this.redirectSignin();
      }
    },

    // Toggle the company
    async toggleCompany(data) {
      this.showOptions.lineLoader = true;
      await AuthService.ToggleCompany(data);

      this.$router.go();
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.dashboard-view {
  margin: 3.75rem 0 0;
}

/deep/ {
  .line-loader {
    position: fixed;
    bottom: 0;
  }
}
</style>