<template>
  <div v-if="!lodash.isEmpty(widget)" class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton"
        @click="saveWidget"
      >
        Save Changes
      </button>
    </div>
    <!--END: Header Wrapper-->

    <div ref="settings" class="settings-main" @scroll="settingsScroll">
      <!--START: Form Blocks-->
      <SettingsForm
        v-for="field in fields"
        :key="field._id"
        :fields="field.fields"
        :content="field.content"
        @fieldChanged="fieldChanged"
      ></SettingsForm>

      <div v-if="showOptions.humanAgentFields">
        <SettingsForm
          v-for="field in humanAgentFields"
          :key="field._id"
          :fields="field.fields"
          :content="field.content"
          @fieldChanged="fieldChanged"
        ></SettingsForm>
      </div>

      <div v-if="showOptions.ticketStatusFields">
        <SettingsForm
          v-for="field in ticketStatusFields"
          :key="field._id"
          :fields="field.fields"
          :content="field.content"
          @fieldChanged="fieldChanged"
        ></SettingsForm>
      </div>
      <!--END: Form Blocks-->
    </div>

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->

    <!--START: Unsaved Changes Modal -->
    <UnsavedChangesModal
      :show="showOptions.unsavedChanges"
      @primaryEvent="continueRoute"
      @secondaryEvent="closeModal"
    ></UnsavedChangesModal>
    <!--END: Unsaved Changes Modal -->
  </div>
</template>
          
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import SettingsForm from "@/components/form/SettingsForm";
import LineLoader from "@/components/loaders/LineLoader";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal";

export default {
  name: "WidgetHandoff",
  data() {
    return {
      lodash: _,
      showOptions: {
        headerShadow: false,
        unsavedChanges: false,
        lineLoader: false,
        disableButton: true,
        ticketStatusFields: false,
        humanAgentFields: false,
      },
      regularHeader: {
        title: "Handoff",
        description:
          "Control how conversations are handed off by the AI agent to your support",
      },
      fields: {
        ticket: {
          content: {
            title: "Ticket Handoffs",
            description:
              "Control how customer requests to talk to agents are handled",
          },
          fields: {
            isActive: {
              type: "toggle",
              title: "Handoff tickets to another app",
              additionalClass: "large-toggle",
              value: true,
            },
            handoffMessage: {
              type: "text",
              title: "Message to show before handoff",
              placeholder: "Add a message",
              required: true,
              hasError: false,
              show: false,
              value: "",
            },
            appType: {
              type: "dropdown",
              placeholder: "Choose app",
              title: "Send customer tickets to",
              required: true,
              hasError: false,
              show: false,
              fields: [],
              value: [],
            },
            ticketStatus: {
              type: "dropdown",
              placeholder: "Choose status",
              title: "Ticket status on helpdesk",
              required: true,
              hasError: false,
              show: false,
              fields: [
                { name: "New", value: "new" },
                { name: "Open", value: "open" },
                { name: "Pending", value: "pending" },
                { name: "Solved", value: "solved" },
              ],
              value: [],
            },
            notificationEmail: {
              type: "email",
              title: "Email address",
              placeholder: "Add email address",
              required: true,
              hasError: false,
              show: false,
              value: "",
            },
          },
        },
        agent: {
          content: {
            title: "Agent Handoffs",
            description:
              "Control how customer requests to talk to agents are handled",
          },
          fields: {
            isActive: {
              type: "toggle",
              title: "Allow customers to connect to a live agent",
              additionalClass: "large-toggle",
              value: true,
            },
            appType: {
              type: "dropdown",
              placeholder: "Choose app",
              title: "Connect customers to support using",
              required: true,
              hasError: false,
              show: false,
              fields: [],
              value: [],
            },
            whatsapp: {
              type: "text",
              title: "Phone Number",
              placeholder: "Add contact number",
              required: true,
              hasError: false,
              show: false,
              value: "",
            },
          },
        },
      },
      humanAgentFields: {
        humanAgent: {
          content: {
            title: "Human Agent",
            description: "Let customers know that human help is available",
          },
          fields: {
            showAvailability: {
              type: "toggle",
              title: "Let customers know that human support is available",
              additionalClass: "large-toggle",
              value: true,
            },
            profilePictureURL: {
              type: "profile-image",
              title: "Show an image of your team",
              fileList: [],
              imagesList: [],
              showPreview: true,
              show: false,
              cropType: "auto",
              required: false,
              hasError: false,
              value: "",
            },
            responseTime: {
              type: "text",
              title: "Indicate the typical reply time",
              placeholder: "Add the reply time",
              required: true,
              hasError: false,
              show: false,
              value: "",
            },
            availabilityMessage: {
              type: "textarea",
              title: "Indicate your teams availability",
              placeholder: "Add a message",
              required: true,
              hasError: false,
              show: false,
              value: "",
            },
            availableDays: {
              type: "scheduler",
              title: "Select the schedule to show human support",
              additionalClass: "large-scheduler",
              required: true,
              show: false,
              hasError: false,
              hideLabel: true,
              uniqueSlots: [],
              days: [],
              value: [],
            },
          },
        },
      },
      ticketStatusFields: {
        ticketStatus: {
          show: false,
          content: {
            title: "Ticket Status",
            description: "Determine the ticket status for the AI chat history",
          },
          fields: {
            chatHistory: {
              type: "dropdown",
              placeholder: "Choose app",
              title: "Ticket status for AI chat histoty",
              required: true,
              hasError: false,
              show: false,
              fields: [
                { name: "New", value: "new" },
                { name: "Open", value: "open" },
                { name: "Pending", value: "pending" },
                { name: "Solved", value: "solved" },
              ],
              value: [],
            },
          },
        },
      },
      initData: {},
      dataChanged: false,
      status: {
        show: false,
        status: "success",
        title: "Handoff settings saved",
      },
    };
  },
  props: {
    widget: Object,
  },
  components: {
    RegularHeader,
    SettingsForm,
    LineLoader,
    UnsavedChangesModal,
  },
  computed: {},
  watch: {},
  async created() {
    this.populateData();
  },
  methods: {
    // Save widget
    async saveWidget() {
      if (this.dataChanged) {
        if (this.isFormValid()) {
          const formData = this.constructPayload();
          await this.$emit("saveWidget", formData);
        }
      }
    },

    isFormValid() {
      var isFormValid = true;
      if (
        !this.validateForm(this.fields.ticket.fields) ||
        !this.validateForm(this.fields.agent.fields)
      )
        isFormValid = false;

      if (
        this.showOptions.ticketStatusFields &&
        !this.validateForm(this.ticketStatusFields.ticketStatus.fields)
      )
        isFormValid = false;

      if (
        this.showOptions.humanAgentFields &&
        !this.validateForm(this.humanAgentFields.humanAgent.fields)
      )
        isFormValid = false;

      return isFormValid;
    },

    // Populate the data for the fields
    populateData() {
      const {
        agent: { fields: agent },
        ticket: { fields: ticket },
      } = this.fields;
      const { chatHistory } = this.ticketStatusFields.ticketStatus.fields;
      const { fields: humanAgent } = this.humanAgentFields.humanAgent;
      const { handoff } = this.widget;

      const widgetAgentHandoff = handoff.channels.agent;
      const widgetTicketHandoff = handoff.channels.ticket;
      agent.isActive.value = widgetAgentHandoff.isActive;
      humanAgent.showAvailability.value =
        widgetAgentHandoff.humanAgent.showAvailability;
      ticket.isActive.value = widgetTicketHandoff.isActive;

      // Ticket handoff
      if (handoff.channels.ticket.isActive) {
        ticket.handoffMessage.value = widgetTicketHandoff.handoffMessage;

        if (widgetTicketHandoff.appType) {
          ticket.appType.value = [
            this.toTitleCase(widgetTicketHandoff.appType),
            widgetTicketHandoff.appType,
          ];

          switch (widgetTicketHandoff.appType) {
            case "email":
              ticket.notificationEmail.value =
                widgetTicketHandoff.attributes[0].notificationEmail;
              break;
            default:
              break;
          }
        }
      }

      // Agent handoff
      if (handoff.channels.agent.isActive) {
        if (widgetAgentHandoff.appType) {
          agent.appType.value = [
            this.toTitleCase(widgetAgentHandoff.appType),
            widgetAgentHandoff.appType,
          ];

          switch (widgetAgentHandoff.appType) {
            case "whatsapp":
              agent.whatsapp.value = widgetAgentHandoff.attributes[0].whatsapp;
              break;
            default:
              break;
          }
        }

        // Human agent fields
        if (widgetAgentHandoff.humanAgent.showAvailability) {
          humanAgent.profilePictureURL.value =
            widgetAgentHandoff.humanAgent.profilePictureURL;
          humanAgent.availableDays.value =
            widgetAgentHandoff.humanAgent.availableDays;
          humanAgent.responseTime.value =
            widgetAgentHandoff.humanAgent.responseTime;
          humanAgent.availabilityMessage.value =
            widgetAgentHandoff.humanAgent.availabilityMessage;
        }
      }

      // Chat history ticket status
      if (handoff.ticketStatus.chatHistory.isActive) {
        chatHistory.value = [
          this.toTitleCase(handoff.ticketStatus.chatHistory),
          handoff.ticketStatus.chatHistory,
        ];
      }

      this.ticketHandoffHandler();
      this.agentHandoffHandler();
      this.ticketStatusHandler();
      this.initFormData();
    },

    // Agent handoff options
    agentHandoffHandler() {
      const options = [
        { name: "Shopify Inbox", value: "shopify-inbox" },
        { name: "Tawk", value: "tawk" },
        { name: "WhatsApp", value: "whatsapp" },
        { name: "Gorgias", value: "gorgias" },
        { name: "Zendesk", value: "zendesk" },
        { name: "Zendesk Classic", value: "zendesk-classic" },
      ];

      const { fields } = this.fields.agent.fields.appType;
      if (fields.length == 0) options.forEach((o) => fields.push(o));

      // Toggle hidden fields
      const { fields: agent } = this.fields.agent;
      agent.appType.show = agent.isActive.value;

      this.showOptions.humanAgentFields = agent.isActive.value;
      const { fields: humanAgent } = this.humanAgentFields.humanAgent;
      humanAgent.availableDays.show = humanAgent.showAvailability.value;
      humanAgent.responseTime.show = humanAgent.showAvailability.value;
      humanAgent.availabilityMessage.show = humanAgent.showAvailability.value;
      humanAgent.profilePictureURL.show = humanAgent.showAvailability.value;

      if (agent.appType.value.length > 1)
        switch (agent.appType.value[1]) {
          case "whatsapp":
            agent.whatsapp.show = agent.isActive.value;
            break;
          default:
            break;
        }
    },

    // Ticket handoff options
    ticketHandoffHandler() {
      const options = [
        { name: "Email", value: "email" },
        { name: "Zendesk", value: "zendesk" },
      ];

      const { fields } = this.fields.ticket.fields.appType;
      if (fields.length == 0) options.forEach((o) => fields.push(o));

      // Toggle hidden fields
      const { fields: ticket } = this.fields.ticket;
      ticket.appType.show = ticket.isActive.value;
      ticket.handoffMessage.show = ticket.isActive.value;

      if (ticket.appType.value.length > 1) {
        ticket.notificationEmail.show = false;
        ticket.ticketStatus.show = false;

        switch (ticket.appType.value[1]) {
          case "email":
            ticket.notificationEmail.show = ticket.isActive.value;
            break;
          case "zendesk":
            ticket.ticketStatus.show = ticket.isActive.value;
            break;
          default:
            break;
        }
      }
    },

    // Handler for AI chat history tickets
    ticketStatusHandler() {
      const allowedApps = ["zendesk", "zendesk-classic"];
      const { value: agentAppType } = this.fields.agent.fields.appType;
      const { value: ticketAppType } = this.fields.ticket.fields.appType;

      let status = false;
      if (
        allowedApps.includes(agentAppType[1]) ||
        allowedApps.includes(ticketAppType[1])
      ) {
        status = true;
      }

      this.ticketStatusFields.ticketStatus.fields.chatHistory.show = status;
      this.showOptions.ticketStatusFields = status;
    },

    // Initialise the form data for comparisons
    initFormData() {
      this.disableSaveButton(true);
      this.initData = _.cloneDeep({
        ...this.fields,
        ...this.ticketStatusFields,
        ...this.humanAgentFields,
      });
    },

    // Event when field changed
    fieldChanged() {
      this.agentHandoffHandler();
      this.ticketHandoffHandler();
      this.ticketStatusHandler();

      const data = {
        ...this.fields,
        ...this.ticketStatusFields,
        ...this.humanAgentFields,
      };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Construct the payload for the controller
    constructPayload() {
      const ticket = this.parseData(this.fields.ticket.fields);
      const agent = this.parseData(this.fields.agent.fields);
      const humanAgent = this.parseData(
        this.humanAgentFields.humanAgent.fields
      );

      if (ticket.appType === "email") {
        ticket.attributes = [{ notificationEmail: ticket.notificationEmail }];
        delete ticket.notificationEmail;
      }

      if (agent.appType === "whatsapp") {
        agent.attributes = [{ whatsapp: agent.whatsapp }];
        delete agent.whatsapp;
      }

      const widget = {
        handoff: { channels: { ticket, agent: { ...agent, humanAgent } } },
      };

      if (this.showOptions.ticketStatusFields)
        widget.handoff.ticketStatus = {
          ...this.parseData(this.ticketStatusFields.ticketStatus.fields),
        };

      // Create form data
      let formData = new FormData();
      for (var key in widget) formData.append(key, JSON.stringify(widget[key]));

      // Add agent image
      const { profilePictureURL } = this.humanAgentFields.humanAgent.fields;
      if (profilePictureURL.fileList.length) {
        formData.append("images", profilePictureURL.fileList[0]);
        formData.append("agentImageAdded", true);

        delete widget.handoff.channels.agent.profilePictureURL;
      }

      return formData;
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Event on settings scroll
    settingsScroll() {
      const scrollTop = this.$refs.settings.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.unsavedChanges = false;
    },

    // Discard all the changes
    discardChanges() {
      this.fields = _.cloneDeep(this.initData);
      this.disableSaveButton(true);
      this.closeModal();
    },

    // Discard the changes and move to the selected route
    continueRoute() {
      this.closeModal();
      this.discardChanges();
      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
    },
  },
  // Check if there are any changes to be saved
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      this.toRoute = to;
      this.showOptions.unsavedChanges = true;
    } else {
      next();
    }
  },
};
</script>
          
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  margin-bottom: 0 !important;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.settings-main {
  overflow-y: scroll;
  padding: 1.5rem 1rem;
  flex: 1;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}

.messages-form-wrapper {
  .message-fields-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;

    .message-field-form {
      flex: 1;
    }

    .message-actions {
      transform: translateY(-0.25rem);
      .unicon /deep/ {
        svg {
          cursor: pointer;
          height: auto;
          width: 0.75rem;
          fill: $greyColor;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .btn {
    float: right;
  }
}
</style>