<template>
  <div v-if="selectedReferences.length > 0" class="references">
    <label>Sources</label>
    <a
      v-for="r in selectedReferences"
      :key="r.id"
      :href="r.url"
      target="_blank"
      class="reference"
    >
      <span>{{ r.title }}</span>
      <unicon name="angle-right"></unicon>
    </a>
  </div>
</template>
  
<script>
export default {
  name: "MessageReferences",
  data() {
    return {};
  },
  props: {
    references: { type: Array, required: true },
  },
  watch: {},
  components: {},
  computed: {
    // Extract the non-product references
    selectedReferences() {
      const sourceTypes = ["shopify-products"];
      const references = this.references.filter((r) => {
        return !sourceTypes.includes(r.sourceType);
      });

      return references;
    },
  },
  created() {},
  methods: {},
};
</script>
      
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.references {
  margin-top: 1.25rem;

  label {
    display: block;
    font-size: $smallestFontSize;
    color: var(--agent-text-color);
    opacity: 0.45;
    margin-bottom: 0.25rem;
  }
}

.message a.reference {
  display: table;
  text-decoration: none;
  color: var(--agent-text-color);

  span {
    font-size: $smallestFontSize;
    color: var(--agent-text-color);
    opacity: 0.55;
  }

  /deep/ svg {
    height: auto;
    width: 1rem;
    transform: translateY(4px);
    margin-left: 0.25rem;
    fill: var(--agent-text-color);
    opacity: 0.25;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
}
</style>