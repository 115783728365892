<template>
  <div v-if="!lodash.isEmpty(widget)" class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton"
        @click="toggleShopifyWidget"
      >
        Save Changes
      </button>
      <!--END: Page Header-->
    </div>
    <!--END: Header Wrapper-->

    <div ref="settings" class="settings-main">
      <!--START: Settings Form-->
      <div class="settings-form card">
        <!--START: Form Content-->
        <div class="settings-form-wrapper">
          <div class="content-wrapper">
            <h3>{{ content.title }}</h3>
            <p>
              {{ content.description }}
            </p>
          </div>
          <div class="form-builder">
            <FormBuilder
              v-if="isShopifyIntegrated"
              :fields="fields"
              @fieldChanged="fieldChanged()"
            ></FormBuilder>

            <!--START: Script Tag-->
            <div class="field-main-item">
              <div class="script-tag-wrapper">
                <p class="script-description">
                  This is Macha script tag for your widget. Copy and paste the
                  below script tag either just before the closing
                  <code>&lt;/head&gt;</code> or <code>&lt;/body&gt;</code> tag.
                </p>
                <div class="script-tag alert-message">
                  <span>
                    <code class="alert-notification yellow">
                      &lt;!-- Macha Widget --&gt;<br />
                      &lt;script
                      src="https://app.getmacha.com/chat/widget.js?widgetID={{
                        widget.widgetID
                      }}" defer&gt;&lt;/script&gt; <br />&lt;!-- Macha Widget
                      --&gt;
                    </code>
                  </span>
                </div>
              </div>
            </div>
            <!--END: Script Tag-->
          </div>
        </div>
        <!--END: Form Content-->
      </div>
      <!--END: Settings Form-->
    </div>

    <!--START: Unsaved Changes Modal -->
    <UnsavedChangesModal
      :show="showOptions.unsavedChanges"
      @primaryEvent="continueRoute"
      @secondaryEvent="closeModal"
    ></UnsavedChangesModal>
    <!--END: Unsaved Changes Modal -->
  </div>
</template>
          
<script>
// Imoprt libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import FormBuilder from "@/components/form/FormBuilder";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal";

// Importing Services
import "external-svg-loader";

export default {
  name: "WidgetSettings",
  data() {
    return {
      lodash: _,
      showOptions: {
        headerShadow: false,
        unsavedChanges: false,
        disableButton: true,
      },
      regularHeader: {
        title: "Activation",
        description: "Use the Macha tag to launch the widget on your website",
      },
      content: {
        title: "Activation Code",
        description: "Use the script to load the Macha tag on your website",
      },
      fields: {
        isActive: {
          type: "toggle",
          title: "Enable widget on Shopify",
          additionalClass: "large-toggle",
          value: true,
        },
      },
    };
  },
  props: {
    widget: Object,
  },
  components: {
    RegularHeader,
    FormBuilder,
    UnsavedChangesModal,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },

    isShopifyIntegrated() {
      if (this.company?.integrations?.shopify?.isActive) return true;
      else return false;
    },
  },
  watch: {
    widget: {
      immediate: true,
      deep: true,
      handler() {
        this.populateData();
      },
    },
  },
  async created() {
    this.populateData();
  },
  methods: {
    // Toggle Shopify Widget
    async toggleShopifyWidget() {
      if (this.dataChanged) {
        await this.$emit("toggleShopifyWidget");
      }
    },

    // Populate the data for the fields
    populateData() {
      // Is Active fields
      const { isActive } = this.fields;
      isActive.value = this.widget.isActive;

      this.initFormData();
    },

    // Initialise the form data for comparisons
    initFormData() {
      this.disableSaveButton(true);
      this.initData = _.cloneDeep(this.fields);
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Event when form field changes
    fieldChanged() {
      this.disableSaveButton(this.isDataEqual(this.initData, this.fields));
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.unsavedChanges = false;
    },

    // Discard all the changes
    discardChanges() {
      this.fields = _.cloneDeep(this.initData);
      this.disableSaveButton(true);
      this.closeModal();
    },

    // Discard the changes and move to the selected route
    continueRoute() {
      this.closeModal();
      this.discardChanges();
      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
    },
  },
  // Check if there are any changes to be saved
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      this.toRoute = to;
      this.showOptions.unsavedChanges = true;
    } else {
      next();
    }
  },
};
</script>
          
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.settings-main {
  overflow-y: scroll;
  padding: 1.5rem 1rem;
  flex: 1;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.script-tag-wrapper {
  .script-description {
    font-size: $smallFontSize;

    > code {
      padding: 0.15rem 0.35rem;
      background: darken($lightWhiteColor, 1.5%);
      border-radius: 0.25rem;
    }
  }

  .script-tag {
    code {
      font-family: monospace;
      color: darken($warningYellowColor, 0%);
      font-size: $smallFontSize;
      font-weight: $mediumFontWeight;
      padding: 1rem 1.5rem;
    }
  }
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}
</style>