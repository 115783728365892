<template>
  <div>
    <!--START: Title Wrapper-->
    <div class="title-wrapper">
      <h1>Add A Company</h1>
      <button class="btn btn-primary" @click="addCompany">Add Company</button>
    </div>
    <!--END: Title Wrapper-->

    <div class="form-wrapper">
      <!--START: Field Wrapper-->
      <div class="scraper-wrapper">
        <div class="card">
          <h3 class="scraper-title">Company Details</h3>
          <FormBuilder :fields="companyFields"></FormBuilder>
        </div>

        <div class="card">
          <h3 class="scraper-title">Add Suggested Questions</h3>
          <!--START: Suggested Question-->
          <div
            v-for="(field, i) in suggestedQuestionFields"
            :key="field.id"
            class="chunk-field"
          >
            <FormBuilder
              :fields="{ field: field }"
              class="chunk-form"
            ></FormBuilder>
            <unicon
              v-if="suggestedQuestionFields.length > 1"
              name="times"
              @click="removeSuggestedQuestion(i)"
            ></unicon>
          </div>

          <!--START: Add Chunk-->
          <button class="btn btn-border" @click="addSuggestedQuestion">
            <span>Add Question</span>
          </button>
          <!--END: Add Chunk-->
          <!--END: Suggested Question-->
        </div>
      </div>

      <div class="scraper-wrapper">
        <div class="card">
          <h3 class="scraper-title">Shopify Products Chunks</h3>
          <!--START: Product Chunker-->
          <FormBuilder :fields="{ field: addProductChunk }"></FormBuilder>
          <FormBuilder
            v-if="addProductChunk.value"
            :fields="productChunkFields"
          ></FormBuilder>
          <!--END: Product Chunker-->

          <!--START: Additional Data-->
          <FormBuilder :fields="{ field: addAdditionalData }"></FormBuilder>
          <FormBuilder
            v-if="addAdditionalData.value"
            :fields="additionalDataFields"
          ></FormBuilder>
          <!--END: Additional Data-->

          <!--START: Chunk Selectors-->
          <FormBuilder :fields="{ field: addChunks }"></FormBuilder>
          <div v-if="addChunks.value">
            <div
              v-for="(field, i) in chunkFields"
              :key="field.id"
              class="chunk-field"
            >
              <FormBuilder
                :fields="{ field: field }"
                class="chunk-form"
              ></FormBuilder>
              <unicon
                v-if="chunkFields.length > 1"
                name="times"
                @click="removeChunkSelector(i)"
              ></unicon>
            </div>

            <!--START: Add Chunk-->
            <button class="btn btn-border" @click="addChunkSelector">
              <span>Add Chunk</span>
            </button>
            <!--END: Add Chunk-->
          </div>
          <!--END: Chunk Selectors-->
        </div>
        <div class="card">
          <h3 class="scraper-title">Shopify Pages</h3>
          <FormBuilder :fields="articlesFields"></FormBuilder>
        </div>
        <div class="card">
          <h3 class="scraper-title">Custom Blog Article</h3>
          <FormBuilder :fields="blogFields"></FormBuilder>
        </div>
      </div>
      <!--END: Field Wrapper-->
    </div>

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>

<script>
//Import libraries
import _ from "lodash";

// Importing Services
import { AdminService } from "@/services";

// Importing components
import FormBuilder from "@/components/form/FormBuilder";
import LineLoader from "@/components/loaders/LineLoader";

export default {
  name: "AddCompany",
  data() {
    return {
      showOptions: {
        lineLoader: false,
      },
      companyFields: {
        name: {
          type: "text",
          placeholder: "ACME Inc, Flintstone Builders, etc.",
          required: true,
          hasError: false,
          title: "Business Name*",
          value: "",
        },
        url: {
          type: "text",
          placeholder: "www.acmecorp.com",
          required: true,
          hasError: false,
          title: "Website URL*",
          value: "",
        },
        currency: {
          type: "text",
          placeholder: "$, ₹, £",
          required: true,
          hasError: false,
          title: "Currency*",
          value: "",
        },
      },
      addProductChunk: {
        type: "toggle",
        title: "Add Product Chunk",
        additionalClass: "large-toggle",
        value: false,
      },
      productChunkFields: {
        descriptionDelimiter: {
          type: "text",
          placeholder: "Single delimiter for product description",
          required: false,
          hasError: false,
          title: "Description Delimiter",
          value: "",
        },
      },
      addAdditionalData: {
        type: "toggle",
        title: "Add Additional Data",
        additionalClass: "large-toggle",
        value: false,
      },
      additionalDataFields: {
        additionalDataSelector: {
          type: "text",
          placeholder: "Comma-separated HTML selector of the product content",
          required: false,
          hasError: false,
          title: "Additional Data HTML Selector",
          value: "",
        },
      },
      addChunks: {
        type: "toggle",
        title: "Add Product Chunks",
        additionalClass: "large-toggle",
        value: false,
      },
      chunkFields: [
        {
          type: "text",
          placeholder: "Comma-separated HTML selector of the product content",
          required: false,
          hasError: false,
          title: "Chunk HTML Selector",
          value: "",
        },
      ],
      suggestedQuestionFields: [
        {
          type: "text",
          placeholder: `Something like - "Where's my order..."`,
          required: false,
          hasError: false,
          title: "Suggested Question",
          value: "",
        },
      ],
      articlesFields: {
        documents: {
          type: "textarea",
          placeholder:
            "Add comma-separated links - policies, about pages, articles, etc.",
          required: false,
          hasError: false,
          title: "Shopify Pages Links",
          value: "",
        },
        documentSelector: {
          type: "text",
          placeholder: "Add the HTML selector to extract data from",
          required: false,
          hasError: false,
          title: "HTML Selector",
          value: "",
        },
      },
      blogFields: {
        blogURL: {
          type: "text",
          placeholder: "Add the URL to the blog you've created",
          required: false,
          hasError: false,
          title: "Blog URL",
          value: "",
        },
      },
      suggestedQuestion: {
        type: "text",
        placeholder: `Something like - "Where's my order..."`,
        required: false,
        hasError: false,
        title: "Suggested Question",
        value: "",
      },
      chunkSelector: {
        type: "text",
        placeholder: "Comma-separated HTML selector of the product content",
        required: false,
        hasError: false,
        title: "Chunk HTML Selector",
        value: "",
      },
    };
  },
  props: {},
  watch: {},
  components: {
    FormBuilder,
    LineLoader,
  },
  computed: {},
  async created() {},
  mounted() {
  },
  methods: {
    // Adding a chunk selector
    addChunkSelector() {
      const chunkSelector = _.cloneDeep(this.chunkSelector);
      this.chunkFields.push(chunkSelector);
    },

    // Remove a chunk selector
    removeChunkSelector(i) {
      this.chunkFields.splice(i, 1);
    },

    // Add a suggested question
    addSuggestedQuestion() {
      const suggestedQuestion = _.cloneDeep(this.suggestedQuestion);
      this.suggestedQuestionFields.push(suggestedQuestion);
    },

    // Remove a suggested question
    removeSuggestedQuestion(i) {
      this.suggestedQuestionFields.splice(i, 1);
    },

    async addCompany() {
      // Check if the fields are valid
      const data = {
        ...this.companyFields,
        ...this.articlesFields,
        ...this.blogFields,
        ...this.additionalDataFields,
        ...this.productChunkFields,
      };
      var isFormValid = this.validateForm(data);

      if (isFormValid) {
        this.showOptions.lineLoader = true;

        // Add the chunks
        let chunkSelectors = [];
        if (this.addChunks.value);
        this.chunkFields.forEach((c) => {
          chunkSelectors.push(c.value);
        });

        // Add the suggested questions
        let suggestedQuestions = [];
        this.suggestedQuestionFields.forEach((q) => {
          suggestedQuestions.push(q.value);
        });

        // Creating the payload
        const payload = {
          ...this.parseData(data),
          chunkSelectors,
          suggestedQuestions,
        };
        console.log(payload);

        const response = await AdminService.AddCompany(payload);
        console.log(response.data);

        this.showOptions.lineLoader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.form-wrapper {
  overflow: hidden;
  margin: 0 15% 2rem;

  .scraper-wrapper {
    &:nth-child(1) {
      width: calc(60% - 2rem);
      margin-right: 2rem;
    }

    &:nth-child(2) {
      width: 40%;
    }
  }
}
.scraper-title {
  font-size: $mediumFontSize;
  margin-bottom: 1.5rem;
}

/deep/ .line-loader {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
}

.form-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4rem calc(15% + 0.5rem) 0;

  h1 {
    flex: 1;
  }
}

.scraper-wrapper .separator {
  display: block;
  margin: 1rem 0;
  border-top: 1px dashed $greyBorderColor;
}

.chunk-field {
  display: flex;
  flex-direction: row;
  align-items: center;

  .chunk-form {
    flex: 1;
  }

  /deep/ .unicon {
    cursor: hover;

    svg {
      margin-left: 1rem;

      &:hover {
        fill: $redColor;
      }
    }
  }
}
</style>