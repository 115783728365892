<template>
  <div class="dashboard-view">
    <div class="page-wrapper">
      <!--START: Menu-->
      <PageMenu
        :menu="menu"
        :highlightExactRoute="false"
        class="page-menu"
      ></PageMenu>
      <!--END: Menu-->

      <!--START: Settings View-->
      <div class="page-content">
        <!--START: Page Loader-->
        <div v-if="showOptions.preLoader" class="pre-loader-wrapper">
          <PreLoader :show="true"></PreLoader>
        </div>
        <!--START: Page Loader-->

        <keep-alive v-else
          ><router-view
            :aiAgent="aiAgent"
            @aiAgentSaved="aiAgentSaved"
          ></router-view
        ></keep-alive>
      </div>
      <!--END: Settings View-->
    </div>
  </div>
</template>
  
<script>
// Import libraries
import _ from "lodash";

// Importing components
import PageMenu from "@/components/dashboard/PageMenu";
import PreLoader from "@/components/loaders/PreLoader";

// Importing Services
import { AiAgentService } from "@/services";

export default {
  name: "AiAgent",
  data() {
    return {
      aiAgent: {},
      showOptions: {
        lineLoader: false,
        preLoader: true,
      },
      menu: [
        {
          name: "AI Agent",
          type: "category",
          menuItems: [
            {
              name: "Persona",
              icon: "users-alt",
              path: "/ai-agent/persona",
            },
            {
              name: "Answer Rules",
              icon: "comment-alt",
              path: "/ai-agent/answer-rules",
            },
            {
              name: "Workflows",
              icon: "repeat",
              path: "/ai-agent/workflows",
            },
          ],
        },
      ],
      status: {
        show: false,
        status: "success",
        title: "AI agent saved",
      },
      saveInProgress: false,
    };
  },
  components: {
    PageMenu,
    PreLoader,
  },
  computed: {},
  async created() {
    this.restrictAdminAccess();
    this.getAiAgent();
  },
  methods: {
    // Get the document
    async getAiAgent() {
      const response = await AiAgentService.GetAiAgent();

      // Check for errors
      if (!response.hasError) {
        this.aiAgent = response.data;
      } else this.showErrorMessage(this.status, response.message);

      this.showOptions.preLoader = false;
    },

    // Update AI agent after it's been saved
    aiAgentSaved(agent) {
      this.aiAgent = _.cloneDeep({});
      this.aiAgent = _.cloneDeep(agent);
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ {
  .page-header {
    margin-bottom: 3rem;
  }
  .line-loader {
    position: fixed;
    z-index: 101;
    bottom: 0;
    left: 0;
  }

  .pre-loader {
    margin: 3rem 5%;
  }
}
</style>