<template>
  <div>
    <!--START: Main Menu-->
    <PageMenu :menu="mainMenu" class="page-menu"></PageMenu>
    <!--END: Main Menu-->

    <!--START: Categories Menu-->
    <div
      class="page-menu"
      :class="{ expand: expandMenu.includes('ai-categories') }"
    >
      <div class="page-menu-category" @click="toggleMenu('ai-categories')">
        <div class="category-label">
          <svg
            :data-src="require('@/assets/images/icons/ai-sparkle.svg')"
            height="12px"
            width="12px"
            class="ai-icon"
          ></svg>
          <label>AI Conversations</label>
          <unicon name="angle-down" class="angle-icon"></unicon>
        </div>

        <div class="menu-items-wrapper">
          <router-link
            v-for="menuItem in aiCategories"
            :key="menuItem._id"
            :to="`/inbox?category=${menuItem.title}`"
            class="page-menu-item"
          >
            <span class="menu-title">{{ menuItem.title }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <!--END: Categories Menu-->

    <!--START: Custom Replies Menu-->
    <div
      class="page-menu"
      :class="{ expand: expandMenu.includes('custom-replies') }"
    >
      <div class="page-menu-category" @click="toggleMenu('custom-replies')">
        <div class="category-label">
          <unicon name="exchange"></unicon>
          <label>Custom Replies</label>
          <unicon name="angle-down" class="angle-icon"></unicon>
        </div>

        <div class="menu-items-wrapper">
          <router-link
            v-for="menuItem in customReplies"
            :key="menuItem._id"
            :to="`/inbox?customReply=${menuItem.name}`"
            class="page-menu-item"
          >
            <span class="menu-title">{{ menuItem.name }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <!--END: Custom Replies Menu-->
  </div>
</template>
  
<script>
import _ from "lodash";

// Import components
import PageMenu from "@/components/dashboard/PageMenu";

// Importing Services
import { ChatService } from "@/services";

export default {
  name: "CategoriesMenu",
  props: {},
  components: {
    PageMenu,
  },
  data() {
    return {
      mainMenu: [
        {
          name: "All Inboxes",
          type: "category",
          menuItems: [
            {
              name: "All messages",
              icon: "envelope",
              path: "/inbox",
            },
            {
              name: "Open",
              icon: "comment-alt",
              path: "/inbox?status=open",
            },
            {
              name: "Resolved",
              icon: "check-circle",
              path: "/inbox?status=resolved",
            },
            {
              name: "Handoffs",
              icon: "head-side",
              path: "/inbox?status=handoff",
            },
            {
              name: "Flagged",
              icon: "exclamation-circle",
              path: "/inbox?status=flagged",
            },
          ],
        },
      ],
      expandMenu: [],
    };
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },

    aiCategories() {
      return _.sortBy(this.categories?.aiCategories, [
        function (c) {
          return c.title;
        },
      ]);
    },

    customReplies() {
      return _.sortBy(this.categories?.customReplies, [
        function (c) {
          return c.name;
        },
      ]);
    },
  },
  async created() {
    this.initMenuAccordions();
    this.getCategories();
  },
  methods: {
    // Open accordions for the menu depending on query parameter
    initMenuAccordions() {
      if ("category" in this.$route.query) {
        this.toggleMenu("ai-categories");
      } else if ("customReply" in this.$route.query) {
        this.toggleMenu("custom-replies");
      }
    },

    // Get the categories
    async getCategories() {
      if (_.isEmpty(this.categories)) {
        const response = await ChatService.GetInboxCategories();
        if (!response.hasError) {
          this.$store.commit("updateCategories", response.data);
        }
      }
    },

    // Toggle the view of the menu items
    toggleMenu(menuItem) {
      let index = this.expandMenu.indexOf(menuItem);
      if (index == -1) this.expandMenu.push(menuItem);
      else this.expandMenu.splice(index, 1);
    },

    // Menu clicked event
    menuClicked(item) {
      this.$emit("menuClicked", item);
    },
  },
};
</script>
  
<style scoped lang="scss">
.page-menu-category {
  margin-bottom: 2.5rem;
  cursor: pointer;

  label {
    display: block;
    color: lighten($paragraphColor, 15%);
    font-weight: $mediumFontWeight;
    font-size: $smallestFontSize;
    margin-bottom: 1rem;
    cursor: pointer;
  }
}

.page-menu-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.45rem 0.85rem;
  margin-bottom: 0.15rem;
  margin-left: 0.85rem;
  border-radius: 0.35rem;
  cursor: pointer;
  @include transition;

  span {
    font-size: $smallerFontSize;
    line-height: auto;
    color: $paragraphColor;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .unicon /deep/ {
    svg {
      fill: $secondaryColor;
      height: auto;
      width: 1rem;
      margin-right: 0.75rem;
    }
  }

  &:hover {
    background-color: darken($lightWhiteColor, 5%);
    text-decoration: none;
  }
  &.router-link-exact-active {
    background-color: darken($lightWhiteColor, 5%);
    span {
      font-weight: $mediumFontWeight;
    }
  }

  .category-options {
    display: none;

    /deep/ svg {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }

  &.router-link-exact-active .category-options,
  &:hover .category-options {
    display: block;
  }
}

.highlight-matched-route {
  .router-link-active {
    background-color: darken($lightWhiteColor, 5%);
    span {
      font-weight: $mediumFontWeight;
    }
  }
}

.category-color {
  width: 0.55rem;
  height: 0.55rem;
  margin: 0 0.2rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.category-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.75rem;

  /deep/ svg {
    width: 12px;
    height: auto;
    fill: $purpleColor;
    margin-right: 0.5rem;
  }

  .angle-icon /deep/ svg {
    width: 1.25rem;
    height: auto;
    margin: 0;
    fill: $inputTextColor;
    opacity: 0.25;
  }

  label {
    flex: 1;
    font-size: $smallFontSize;
    color: $inputTextColor;
    margin-bottom: 0;
  }

  .add-category-btn {
    position: relative;
    .unicon {
      cursor: pointer;

      /deep/ svg {
        fill: $purpleColor;
        height: auto;
        width: 0.85rem;
        border-radius: 50%;
        padding: 2.5px;
        background-color: darken($lightWhiteColor, 5%);
        transform: translateX(0.25rem);
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          fill: $purpleColor;
        }
      }
    }

    span {
      display: none;
      position: absolute;
      color: $whiteColor;
      background-color: $darkBlackColor;
      font-size: $smallestFontSize;
      top: calc(100% + 0.5rem);
      left: 50%;
      transform: translateX(-50%);
      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem;
      white-space: nowrap;
    }

    &:hover {
      span {
        display: block;
      }
    }
  }
  .add-category-wrapper {
    position: relative;
  }
}

.menu-items-wrapper {
  overflow-y: scroll;
  height: 45vh;
}

.menu-title {
  flex: 1;
}

.delete-in-progress {
  .menu-title {
    opacity: 0.5;
  }

  .category-options {
    display: none !important;
  }
}

.page-menu {
  .menu-items-wrapper {
    display: none;
  }

  &.expand {
    .menu-items-wrapper {
      display: block;
    }
  }
}

/deep/ {
  .page-menu {
    .page-menu-category label {
      font-size: $smallFontSize;
      color: $inputTextColor;
    }
  }
}
</style>