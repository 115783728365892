<template>
  <div class="dashboard-view">
    <div class="page-wrapper">
      <PageMenu :menu="menu" :highlightExactRoute="false"></PageMenu>
      <div class="page-content">
        <keep-alive><router-view></router-view></keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import PageMenu from "@/components/dashboard/PageMenu";

export default {
  name: "KnowledgeBase",
  data() {
    return {
      menu: [
        {
          name: "Knowledge Base",
          type: "category",
          menuItems: [
            {
              name: "Sources",
              icon: "copy-alt",
              path: "/knowledge-base/sources",
            },
            {
              name: "Articles",
              icon: "file-alt",
              path: "/knowledge-base/articles",
            },
          ],
        },
      ],
    };
  },
  components: {
    PageMenu,
  },
  computed: {},
  async created() {
    this.restrictAdminAccess();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
</style>