<template>
  <div>
    <div class="title-wrapper">
      <img :src="getLogoImage(source.sourceType)" alt="Logo" />
      <h3 class="category-title">{{ source.title }}</h3>
      <span class="total-count">{{ source.documents.length }} documents</span>
    </div>
    <div class="card category-insights">
      <div class="category-title-wrapper">
        <span>Name</span>
        <span>Used in Answers</span>
        <span>Percentage</span>
        <span>Link</span>
      </div>
      <div v-if="source.documents.length > 0" class="category-item-wrapper">
        <div
          v-for="item in source.documents"
          :key="item.id"
          class="category-item"
        >
          <span class="name">{{ item.title }}</span>
          <span class="count">{{ item.timesUsed }} times</span>
          <span class="percentage">{{ getPercentage(item.timesUsed) }}%</span>
          <a v-if="item.url" :href="item.url" target="_blank" class="link"
            ><span>View</span> <unicon name="angle-right"></unicon
          ></a>
          <router-link
            v-else
            :to="`/knowledge-base/articles/add/${item.referenceID}`"
            class="link"
            ><span>View</span> <unicon name="angle-right"></unicon
          ></router-link>
        </div>
      </div>

      <span v-else class="empty-catgories"
        ><unicon name="comment-alt"></unicon>No documents in this source
        used</span
      >
    </div>
  </div>
</template>
    
<script>
export default {
  name: "SourcesInsights",
  props: {
    source: { type: Object, required: true },
  },
  data() {
    return {};
  },
  components: {},
  computed: {
    // Get the total count
    totalCount() {
      let totalCount = 0;

      this.source.documents.forEach((item) => {
        totalCount += item.timesUsed;
      });

      return totalCount;
    },
  },
  async created() {},
  methods: {
    // Get logo of source type
    getLogoImage(sourceType) {
      let icon = null;
      switch (sourceType) {
        case "macha-article":
          icon = "macha.jpg";
          break;
        case "shopify-products":
          icon = "shopify.jpg";
          break;
        case "zendesk-hc":
        case "zendesk-hc-api":
          icon = "zendesk.jpg";
          break;
      }

      return (this.imageSrc = `/assets/images/logos/${icon}`);
    },

    // Get the percentage of times used
    getPercentage(timesUsed) {
      return Math.trunc((timesUsed / this.totalCount) * 100);
    },
  },
};
</script>
      
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.category-insights.card {
  margin: 0 0 2rem;
  padding: 0.75rem 1.25rem;
  box-shadow: none;
  background-color: darken($whiteColor, 0.5%);
  border-color: $greyBorderColor2;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

  img {
    width: 1.5rem;
    border-radius: 0.5rem;
    margin-right: 0.75rem;
  }

  h3 {
    flex: 1;
    font-size: $normalFontSize;
    font-weight: $mediumFontWeight;
    margin-bottom: 0;
  }

  .total-count {
    color: $secondaryColor;
    font-size: $smallerFontSize;
    font-weight: $mediumFontWeight;
  }
}

.category-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $greyBorderColor2;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  span {
    color: $paragraphColor;
    font-size: $smallestFontSize;
    font-weight: $mediumFontWeight;
    opacity: $lightOpacity;
    letter-spacing: 0.05rem;
    text-transform: uppercase;

    &:nth-child(1) {
      min-width: 40%;
    }

    &:nth-child(2),
    &:nth-child(3) {
      text-align: center;
      width: 20%;
    }

    &:last-child {
      width: calc(20% - 5rem);
      margin-left: 5rem;
      text-align: right;
    }
  }
}

.category-item {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    font-size: $smallFontSize;
  }
  .name {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
    min-width: 40%;
  }
  .count {
    text-align: center;
    color: $purpleColor;
    width: 20%;
    font-weight: $mediumFontWeight;
  }
  .percentage {
    text-align: center;
    width: 20%;
    opacity: $lightOpacity;
  }
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
    width: calc(20% - 5rem);
    margin-left: 5rem;
    font-size: $smallestFontSize;
    color: $greyColor;
    opacity: $lightOpacity;

    .unicon /deep/ svg {
      height: auto;
      width: $normalFontSize;
      transform: translateY(2px);
      fill: $greyColor;
      opacity: $lightOpacity;
    }
  }
}

.empty-catgories {
  display: block;
  text-align: center;
  color: $paragraphColor;
  opacity: $lightOpacity;
  font-weight: $mediumFontWeight;
  font-size: $smallerFontSize;

  .unicon /deep/ {
    svg {
      display: inline-block;
      vertical-align: middle;
      height: auto;
      opacity: 0.5;
      width: 0.85rem;
      margin-right: 0.5rem;
    }
  }
}
</style>