<template>
  <div class="date-selector-main">
    <div class="selected-range-wrapper">
      <label class="label-small">Select date range</label>
      <div
        v-if="selectorType === 'monthOnly'"
        @click="showDateSelector = !showDateSelector"
        class="date-selector"
      >
        <b>{{ `${selectedMonth.month} ${selectedMonth.year}` }}</b>
        <unicon name="angle-down"></unicon>
      </div>
      <div
        v-else
        @click="showDateSelector = !showDateSelector"
        class="date-selector"
      >
        <b>{{
          moment(this.startDate).format(
            `${selectorType === "monthOnly" ? "MMMM YYYY" : "Do MMMM YYYY"}`
          )
        }}</b>
        <span>to</span>
        <b>{{
          moment(this.endDate).format(
            `${selectorType === "monthOnly" ? "MMMM YYYY" : "Do MMMM YYYY"}`
          )
        }}</b>
        <unicon name="angle-down"></unicon>
      </div>
    </div>
    <div
      v-if="showDateSelector"
      class="date-selector-wrapper"
      v-click-outside="closeSelector"
    >
      <div class="date-selector-block">
        <div
          v-if="selectorType === 'monthOnly'"
          class="month-selector month-selector-wrapper"
        >
          <span
            v-for="(date, index) in getDates"
            :key="date.id"
            :class="{ selected: index == selectedMonthIndex }"
            @click="selectMonthRange(date.month, date.year, index)"
          >
            {{ date.month }} {{ date.year }}
          </span>
        </div>

        <div v-else class="date-selector-block">
          <!--START: Month Selector-->
          <div v-if="showSelector.month" class="month-selector">
            <span
              v-for="date in getDates"
              :key="date.id"
              @click="selectMonth(date.month, date.year)"
            >
              {{ date.month }} {{ date.year }}
            </span>
          </div>
          <!--END: Month Selector-->

          <!--START: Date Range Selector-->
          <div v-if="showSelector.custom" class="date-range-selector">
            <FormBuilder class="date-form" :fields="dateRange"></FormBuilder>
            <button
              type="button"
              class="btn btn-primary btn-small"
              @click="selectDateRange"
            >
              Get Insights
            </button>
          </div>
          <!--START: Date Range Selector-->

          <div class="option-selector-list">
            <span
              v-for="(option, index) in selectorOption"
              :key="option.id"
              :class="{ selected: index == selectedOptionIndex }"
              @click="optionSelected(option.val, index)"
            >
              {{ option.title }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// Importing libraries
import vClickOutside from "v-click-outside";

import FormBuilder from "@/components/form/FormBuilder";

export default {
  name: "DateSelector",
  data() {
    return {
      showBackground: false,
      showLoader: false,
      startDate: new Date().setDate(new Date().getDate() - 30),
      endDate: new Date(),
      selectedOptionIndex: 0,
      selectedMonthIndex: 0,
      selectedMonth: {
        month: this.moment().format("MMMM"),
        year: this.moment().format("YYYY"),
      },
      showDateSelector: false,
      showSelector: {
        month: false,
        custom: false,
      },

      selectorOption: [
        { title: "Today", val: "today" },
        { title: "Yesterday", val: "yesterday" },
        { title: "Last Week", val: "last-week" },
        { title: "Custom Range", val: "custom" },
      ],
      dateRange: {
        startDate: {
          type: "date",
          title: "Start Date",
          class: "top-fixed",
          placeholder: "Mention a start date",
          additionalClass: "small-input",
          required: false,
          hasError: false,
          value: new Date().setDate(new Date().getDate() - 30),
          disabledDates: {
            from: new Date(),
          },
        },
        endDate: {
          type: "date",
          title: "End Date",
          class: "top-fixed",
          placeholder: "Mention an end date",
          additionalClass: "small-input",
          required: false,
          hasError: false,
          value: new Date(),
          disabledDates: {
            from: new Date(),
          },
        },
      },
    };
  },
  components: {
    FormBuilder,
  },
  props: {
    selectorType: String,
  },
  computed: {
    getDates: function () {
      let dates = [];
      let startDate = this.moment("01 Mar 2024");
      const currentDate = this.moment();

      for (var d = startDate; d <= currentDate; d.add(1, "M")) {
        dates.push({
          month: d.format("MMMM"),
          year: d.format("YYYY"),
        });
      }

      return dates.reverse();
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    selectDateRange() {
      this.startDate = this.dateRange.startDate.value;
      this.endDate = this.dateRange.endDate.value;

      this.changeDates();
    },

    selectMonth(selectedMonth, selectYear) {
      this.startDate = this.moment(
        `01 ${selectedMonth} ${selectYear}`
      ).toDate();
      this.endDate = new Date(
        this.startDate.getFullYear(),
        this.startDate.getMonth() + 1,
        0
      );

      this.changeDates();
    },

    optionSelected(option, index) {
      this.selectedOptionIndex = index;
      this.hideSelectors();

      switch (option) {
        case "today":
          this.startDate = this.moment().startOf("day");
          this.endDate = this.moment();
          this.changeDates();
          break;
        case "yesterday":
          this.startDate = this.moment().subtract(1, "days").startOf("day");
          this.endDate = this.startDate.endOf("day");
          this.changeDates();
          break;
        case "last-week":
          this.startDate = this.moment().subtract(7, "days").startOf("day");
          this.endDate = this.moment();
          this.changeDates();
          break;
        case "month":
          this.showSelector.month = true;
          break;
        case "custom":
          this.showSelector.custom = true;
          break;
        default:
      }
    },

    hideSelectors() {
      this.showSelector = {
        month: false,
        custom: false,
      };
    },

    selectMonthRange(month, year, index) {
      this.selectedMonthIndex = index;
      this.selectedMonth = { month, year };
      this.$emit("monthRangeUpdated", this.selectedMonth, this.selectedMonth);
      this.showDateSelector = false;
    },

    changeDates() {
      this.$emit("dateRangeUpdated", this.startDate, this.endDate);
      this.showDateSelector = false;
    },

    closeSelector() {
      this.showDateSelector = false;
    },
  },
};
</script>
  
<style scoped lang="scss">
.selected-range-wrapper {
  text-align: right;

  label {
    display: block;
    color: $greyColor;
    font-size: $smallerFontSize;
    margin-bottom: 0.5rem;
    font-weight: $mediumFontWeight;
  }

  .date-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $whiteColor;
    font-size: $smallerFontSize;
    transition: all 0.3s;
    padding: 0.5rem 1rem;
    border-radius: 0.75rem;
    background-color: $darkBlackColor;
    cursor: pointer;

    .unicon /deep/ {
      vertical-align: middle;
      margin-left: 0;
      margin-right: -0.25rem;
      margin-top: 0.25rem;
      svg {
        fill: $whiteColor;
        opacity: $mediumOpacity;
        height: 1.25rem;
      }
    }

    span {
      margin: 0 0.5rem;
      opacity: 0.5;
    }

    b {
      font-weight: $mediumFontWeight;
    }

    &:hover {
      background-color: $secondaryColor;
      color: $whiteColor;

      .unicon /deep/ svg {
        fill: $whiteColor;
      }
    }
  }
}

.date-selector-main {
  position: relative;
}

.date-selector-wrapper {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: $whiteColor;
  border-radius: 0.5rem;
  z-index: 100;
  border: 1px solid $cardBorderColor;
  box-shadow: 0 0.5rem 1rem -0.15rem rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .date-selector-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: max-content;
  }
}

.date-range-selector {
  padding: 1rem;
  background-color: $whiteColor;
  margin: 0;

  .date-form /deep/ {
    flex: 1;

    .field-main-item {
      flex: 1;
    }
  }
}
.month-selector {
  padding: 1rem;
  background-color: $whiteColor;
  span {
    transition: all 0.3s;
    cursor: pointer;
    display: block;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e6e6e6;

    &:last-child {
      border-bottom: none;
    }

    &.selected,
    &:hover {
      color: $whiteColor;
      background-color: $purpleColor;
    }
  }
}

.month-selector-wrapper {
  .month-column {
    flex: 1;
  }

  label {
    display: block;
    font-size: $smallestFontSize;
    opacity: 0.75;
    margin-bottom: 0.5rem;
  }

  span {
    font-size: $smallerFontSize;
    padding: 0.5rem 0.75rem;
    
    &.selected,
    &:hover {
      border-radius: 0.5rem;
    }
  }

  .btn-primary {
    width: auto;
    font-size: $smallestFontSize;
    padding: 0.5rem 0.5rem;
  }
}

.option-selector-list {
  width: 10rem;
  background-color: $lightWhiteColor;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid $cardBorderColor;

  span {
    cursor: pointer;
    font-size: $smallerFontSize;
    display: block;
    padding: 0.5rem 1.5rem;
    color: $inputTextColor;

    &:hover {
      background-color: $secondaryColor;
      color: $whiteColor;
    }

    &:last-child {
      border-bottom: none;
    }

    &.selected {
      color: lighten($blackColor, 0%);
      color: $blackColor;
      background-color: $whiteColor;
      border-bottom-color: $purpleColor;
    }
  }
}

.btn-primary {
  display: block;
  width: 100%;
}
</style>