<template>
  <div>
    <!--START: Order-->
    <div class="order-wrapper">
      <!--START: Order Info-->
      <div class="order-info">
        <h3 class="order-name">{{ order.name }}</h3>
        <div class="created-date">
          {{ getOrderDate(order.createdAt) }}
        </div>
      </div>
      <!--END: Order Info-->

      <!--START: Order Details-->
      <div class="order-details">
        <div class="financial-status-wrapper">
          <label>{{
            languages[$store.getters.widgetLanguage].widget.chat.orderDetails
              .paymentLabel
          }}</label>
          <span class="financial-status" :class="order.financialStatus">
            {{ order.financialStatus.replace("_", " ") }}
          </span>
        </div>
      </div>
      <!--END: Order Details-->
    </div>
    <!--END: Order-->

    <!--START: Customer-->
    <div class="customer-wrapper">
      <span class="name">{{ customer.name }}</span>
      <span class="email">{{ customer.email }}</span>
    </div>
    <!--END: Customer-->
  </div>
</template>
    
<script>
export default {
  name: "OrderDetails",
  data() {
    return {};
  },
  props: {
    order: { type: Object, required: true },
    customer: { type: Object, required: true },
  },
  watch: {},
  components: {},
  computed: {},
  created() {},
  methods: {
    getOrderDate(orderDate) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      return new Date(orderDate).toLocaleTimeString([], options);
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/chat.scss";

.order-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.order-info {
  flex: 1;
  .order-name {
    color: $darkBlackColor;
    font-size: $normalFontSize;
  }

  .created-date {
    font-size: $smallerFontSize;
    color: lighten($darkBlackColor, 25%);
  }
}

.financial-status-wrapper {
  label {
    font-size: $smallestFontSize;
    color: lighten($darkBlackColor, 45%);
    margin-right: 0.5rem;
  }
}
.financial-status {
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  text-transform: capitalize;
  color: $warningYellowColor;
  background-color: darken($warningYellowBackgroundColor, 5%);
  border: 1px solid $warningYellowBorderColor;

  &.paid {
    color: $greenMessageTextColor;
    background-color: lighten($greenMessageColor, 0%);
    border: 1px solid $greenMessageBorderColor;
  }
}

.customer-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px dashed $greyBorderColor2;
  padding-bottom: 1rem;
  margin: 0.5rem 0 0;

  .name {
    flex: 1;
    display: block;
    color: $darkBlackColor;
    font-size: $smallFontSize;
    font-weight: $mediumFontWeight;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 1.5rem;
    text-transform: capitalize;
  }

  .email {
    font-size: $smallerFontSize;
    color: lighten($darkBlackColor, 25%);
  }
}
</style>