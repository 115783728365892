<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button class="btn btn-small btn-grey" @click="addCategory(false)">
        Add Category
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Zero State -->
    <ZeroState
      v-if="categories.length == 0 && showOptions.initCategories"
      class="zero-state"
      :imageURL="zeroStateData.imageURL"
      :title="zeroStateData.title"
      :message="zeroStateData.message"
      :buttonTitle="zeroStateData.buttonTitle"
      @eventHandler="addCategory(false)"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Question Categories Table-->
    <QuestionCategoriesTable
      v-else
      :categories="categories"
      :customReplies="customReplies"
      @categorySelected="addCategory"
      @showCategoryIntents="showCategoryIntents"
      @deleteCategory="deleteCategory"
    ></QuestionCategoriesTable>
    <!--END: Question Categories Table-->

    <!--START: Loader-->
    <PageLoader
      v-if="!showOptions.initCategories"
      :showLoader="true"
    ></PageLoader>
    <!--END: Loader-->

    <!--START: Add Category-->
    <ViewCategory
      v-if="showOptions.addCategory"
      :show="showOptions.addCategory"
      :categoryID="selectedCategoryID"
      :customReplies="customReplies"
      @categoryAdded="categoryAdded"
      @closeModal="closeModal"
    ></ViewCategory>
    <!--END: Add Category-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
        
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import ViewCategory from "@/components/dashboard/automation/ViewCategory";
import QuestionCategoriesTable from "@/components/dashboard/tables/QuestionCategoriesTable";
import ZeroState from "@/components/misc/ZeroState";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";

// Importing Services
import { QuestionCategoryService, CustomReplyService } from "@/services";

export default {
  name: "KnowledgeBaseSources",
  data() {
    return {
      showOptions: {
        initCategories: false,
        addCategory: false,
        lineLoader: false,
      },
      regularHeader: {
        title: "Question Categories",
        description:
          "Question categories allow you to set rules for the AI to answer",
      },
      zeroStateData: {
        imageURL: "services.jpg",
        title: "Add Question Categories",
        message: "Add categories to create rules that will help the AI answer",
        buttonTitle: "Add Category",
      },
      status: {
        show: false,
        status: "success",
        title: "Category deleted",
      },
      categories: [],
      customReplies: [],
      selectedCategoryID: "",
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    ViewCategory,
    QuestionCategoriesTable,
    ZeroState,
    NotificationMessage,
    LineLoader,
    PageLoader,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },

    hasCompanyLoaded() {
      return !_.isEmpty(this.company);
    },
  },
  async created() {
    await this.getQuestionCategories();
    this.showOptions.initCategories = true;

    await this.getCustomReplies();
  },
  methods: {
    async getQuestionCategories() {
      const response = await QuestionCategoryService.GetCategories();
      if (!response.hasError) this.categories = response.data;
    },

    async getCustomReplies() {
      const response = await CustomReplyService.GetAllReplies();
      if (!response.hasError) this.customReplies = response.data;
    },

    addCategory(categoryID) {
      if (categoryID) this.selectedCategoryID = categoryID;
      else this.selectedCategoryID = "";
      this.showOptions.addCategory = true;
    },

    categoryAdded(category) {
      let tempCategories = _.cloneDeep(this.categories);
      const index = tempCategories.findIndex((i) => i._id === category._id);

      if (index !== -1) tempCategories[index] = category;
      else tempCategories.push(category);

      this.categories.splice(0);
      this.categories = _.cloneDeep(tempCategories);
    },

    showCategoryIntents(categoryID) {
      this.$router.push({
        path: `/automation/question-categories/${categoryID}`,
      });
    },

    async deleteCategory(categoryID) {
      this.showOptions.lineLoader = true;

      const data = { questionCategoryID: categoryID };
      await QuestionCategoryService.DeleteCategory(data);

      // Remove from the table
      const index = this.categories.findIndex((s) => s._id == categoryID);
      this.categories.splice(index, 1);

      this.status.title = "Category deleted";
      this.showStatusMessage(this.status, 2500);
      this.showOptions.lineLoader = false;
    },

    closeModal() {
      this.showOptions.addCategory = false;
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
</style>