<template>
  <!--START: Form Wrapper-->
  <form v-on:submit.prevent="saveData()" class="read-only">
    <FormBuilder :fields="formFields"></FormBuilder>
  </form>
  <!--END: Form Wrapper-->
</template>
  
<script>
// Importing components
import FormBuilder from "@/components/form/FormBuilder";

export default {
  name: "DynamicForm",
  props: { fields: Array, messageData: Object },
  data() {
    return {
      formSubmitted: false,
      formFields: {},
    };
  },
  watch: {},
  components: {
    FormBuilder,
  },
  computed: {
    widgetLanguage() {
      return this.languages[this.$store.getters.widgetLanguage].widget;
    },
  },
  async created() {
    this.initFields();
  },
  methods: {
    initFields() {
      this.fields.forEach((f) => {
        const field = {
          title: `${f.title}${f.required ? "*" : ""}`,
          type: f.fieldType,
          required: f.required,
          placeholder: "Not added",
          hasError: false,
          readOnly: true,
          value: "",
        };

        this.formFields[f.key] = field;
      });

      this.populateFields();
    },

    // Populate the fields
    populateFields() {
      if (this.messageData?.responseFields) {
        this.readOnlyFields();

        for (var key in this.messageData?.responseFields) {
          this.formFields[key].value = this.messageData?.responseFields[key];
        }
      }
    },

    // Make fields readonly
    readOnlyFields() {
      this.formSubmitted = true;
      for (var key in this.formFields) {
        this.formFields[key].readOnly = true;
      }
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.form.read-only /deep/ {
  .field-main-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .field-title {
    margin-bottom: 0;
    margin-right: 1rem;
  }

  .field-wrapper {
    flex: 1;
  }

  .dropdown-info-wrapper {
    border: none;
    padding: 0;
    margin: 0 0.25rem;
  }

  .field-placeholder {
    font-size: $smallFontSize;
    color: lighten($darkBlackColor, 50%);

    &.has-value {
      color: $inputTextColor;
    }
  }

  input[readonly],
  input:disabled {
    background: transparent;
    color: $inputTextColor;
    border: none !important;
    padding: 0 !important;
    margin: 0 0.25rem;
  }

  .select-country-container {
    width: 2rem !important;
  }

  .country-selector__country-flag,
  .country-selector__toggle,
  .country-selector__label,
  .input-tel__label,
  .field-icon {
    display: none;
  }
}
</style>