<template>
  <div>
    <!--START: Message Details-->
    <div class="message-details">
      <span class="message-time-wrapper"
        >{{ widgetLanguage.chat.messages.sentLabel }}
        <span class="message-time">
          {{ getMessageTime(message.timestamp) }}
        </span>
      </span>
      <span class="message-ai"
        >{{ widgetLanguage.chat.messages.aiLabel }}
        <svg
          :data-src="require('@/assets/images/icons/ai-sparkle.svg')"
          height="12px"
          width="12px"
          class="ai-icon"
        ></svg>
      </span>
    </div>
    <!--END: Message Details-->
    <div class="message" :class="message.messageType">
      <p v-html="replaceURLs(message.message)"></p>

      <References
        v-if="showSources && message.references"
        :references="message.references"
      ></References>
    </div>
    <div class="clear"></div>

    <!--START: AI Analysis-->
    <div v-if="getAiAnalysis" class="ai-analysis">
      <div
        v-if="showOptions.analysis"
        class="analysis"
        v-click-outside="hideAnalysis"
      >
        <div
          v-for="(value, key) in getAiAnalysis"
          :key="key"
          class="analysis-item"
        >
          <div v-if="getAnalysisKey(key)">
            <span>{{ getAnalysisKey(key) }}</span>
            <p>{{ value }}</p>
          </div>
        </div>
      </div>
      <div class="view-btn" @click="showAnalysis">
        <unicon name="eye"></unicon>
        <span>AI Interpretation</span>
      </div>
    </div>
    <!--END: AI Analysis-->

    <!--START: Images-->
    <ImageReferences
      v-if="showSources && message.references"
      :references="message.references"
    ></ImageReferences>
    <!--END: Images-->
  </div>
</template>
    
<script>
// Importing libraries
import vClickOutside from "v-click-outside";

// Importing components
import References from "../References/Index";
import ImageReferences from "../References/ImageReferences";
import { replaceURLs } from "@/utils/chat.utils";

export default {
  name: "ChatMessage",
  data() {
    return {
      showOptions: {
        analysis: false,
      },
    };
  },
  props: {
    message: { type: Object, required: true },
    aiAnalysis: { type: Array, required: true },
    showSources: { type: Boolean, required: true },
  },
  watch: {},
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    References,
    ImageReferences,
  },
  computed: {
    // Widget language
    widgetLanguage() {
      return this.languages[this.$store.getters.widgetLanguage].widget;
    },

    // Check if the AI analysis
    getAiAnalysis() {
      if (
        this.message.messageType === "question" &&
        this.message?.messageData?.analysisID
      ) {
        const aiAnalysis = this.aiAnalysis.find(
          (a) => a._id === this.message.messageData.analysisID
        );
        return aiAnalysis;
      }
      return false;
    },
  },
  created() {},
  methods: {
    replaceURLs,

    // Check if the keys are allowed
    getAnalysisKey(key) {
      let response = false;
      switch (key) {
        case "shortenedCustomerMessage":
          response = "Interpreted Message";
          break;
        case "questionCategory":
          response = "Question Category";
          break;
        case "selectedIntent":
          response = "Intent";
          break;
        default:
          break;
      }

      return response;
    },

    // Show the message analysis
    showAnalysis() {
      this.showOptions.analysis = true;
    },

    // Hide the message analysis
    hideAnalysis() {
      this.showOptions.analysis = false;
    },

    // Time for the message
    getMessageTime(responseTime) {
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      if (responseTime)
        return new Date(responseTime)
          .toLocaleTimeString([], options)
          .toUpperCase();
      return new Date().toLocaleTimeString([], options).toUpperCase();
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/chat.scss";

.ai-analysis {
  position: relative;
  margin-top: 0.25rem;

  .view-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    font-size: $smallestFontSize;
    color: darken($whiteColor, 50%);
    cursor: pointer;

    .unicon /deep/ svg {
      height: auto;
      width: 0.85rem;
      margin-right: 0.25rem;
      fill: darken($whiteColor, 50%);
    }

    &:hover {
      color: darken($whiteColor, 70%);

      .unicon /deep/ svg {
        fill: darken($whiteColor, 70%);
      }
    }
  }

  .analysis {
    position: absolute;
    background-color: rgba(34, 34, 34, 0.95);
    border: 1px solid #474747;
    box-shadow: 0 0.5rem 1.7rem -0.15rem #35343473;
    top: 1.5rem;
    left: 0;
    backdrop-filter: blur(5px);
    z-index: 100;
    padding: 0.75rem 1rem 0;
    border-radius: 0.5rem;
    max-width: 20rem;

    .analysis-item {
      margin-bottom: 0.75rem;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        display: block;
        font-size: $smallestFontSize;
        color: darken($whiteColor, 30%);
        margin-bottom: 0.25rem;
      }

      p {
        font-size: $smallerFontSize;
        color: darken($whiteColor, 0%);
      }
    }
  }
}
</style>