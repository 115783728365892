<template>
  <div v-if="selectedReferences.length > 0" class="references">
    <label>Sources ({{ selectedReferences.length }})</label>
    <div class="references-wrapper">
      <a
        v-for="r in selectedReferences"
        :key="r.id"
        :href="r.url"
        target="_blank"
        class="reference"
      >
        <img :src="r.image" :alt="r.title" />
        <span>{{ r.title }}</span>
      </a>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "ImageReferences",
  data() {
    return {};
  },
  props: {
    references: { type: Array, required: true },
  },
  watch: {},
  components: {},
  computed: {
    // Extract product references
    selectedReferences() {
      const sourceTypes = ["shopify-products"];
      const references = this.references.filter((r) => {
        return sourceTypes.includes(r.sourceType);
      });

      return references;
    },
  },
  created() {},
  methods: {},
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.references {
  float: right;
  clear: both;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;

  label {
    display: block;
    font-size: $smallestFontSize;
    color: darken($whiteColor, 45%);
    margin: 0 0.25rem 0.25rem;
  }
}

.reference {
  background-color: $whiteColor;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  border-radius: 1rem;
  padding: 0.5rem;
  width: 6rem;
  overflow: hidden;
  margin-right: 0.5rem;
  border: 1px solid #f1f1f1;
  box-shadow: 0 0 1rem -0.5rem #e0e0e0;
  text-decoration: none;
  transition: all 0.3s;

  img {
    display: block;
    width: 100%;
    border-radius: 0.5rem;
  }

  span {
    display: block;
    font-weight: 500;
    font-size: 10px;
    color: #5b5b5b;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0.5rem 0.25rem 0;
  }

  &:hover {
    box-shadow: 0 0.5rem 1rem -0.5rem #bebebe;
  }
}

.references-wrapper {
  margin-left: -1.5rem;
  white-space: nowrap;
  overflow-x: scroll;
  width: calc(100% + 3rem);

  .reference:first-child {
    margin-left: 2rem;
  }
}
</style>