<template>
  <div class="field-wrapper">
    <!--START: Input Selector-->
    <div
      class="input-selector-wrapper"
      :class="{ 'input-selected': selectedIcon }"
    >
      <i
        @click="showIconPicker"
        :class="[
          `icon-preview fa fa-fw fa-${!selectedIcon ? 'plus' : selectedIcon}`,
          { 'icon-add': !selectedIcon },
        ]"
      ></i>

      <input
        type="text"
        :name="field.value.title"
        :placeholder="field.placeholder"
        :required="field.required"
        :readonly="field.readOnly != undefined ? field.readOnly : false"
        :class="{ error: field.hasError }"
        @focus="field.hasError = false"
        @input="formChanged"
        v-model="field.value.title"
      />
    </div>
    <!--START: Input Selector-->

    <!--START: Icon Selector-->
    <div
      v-if="!hideIconPicker"
      class="icon-loader-wrapper"
      v-click-outside="closeModal"
    >
      <div class="title-wrapper">
        <label class="label-small">Icon Selector</label>
        <unicon name="times" class="modal-close" @click="closeModal"></unicon>
      </div>
      <div class="icons-wrapper">
        <i
          v-for="icon in icons"
          :key="icon._id"
          :class="[`fa fa-fw fa-${icon}`, { selected: selectedIcon == icon }]"
          @click="iconSelected(icon)"
        ></i>
      </div>
    </div>
    <!--END: Icon Selector-->
  </div>
</template>

<script>
//Importing libraries
import vClickOutside from "v-click-outside";

export default {
  name: "IconSelector",
  data() {
    return {
      icons: [
        "address-book",
        "address-book-o",
        "address-card",
        "address-card-o",
        "adjust",
        "american-sign-language-interpreting",
        "anchor",
        "archive",
        "area-chart",
        "arrows",
        "arrows-h",
        "arrows-v",
        "assistive-listening-systems",
        "asterisk",
        "at",
        "audio-description",
        "balance-scale",
        "ban",
        "barcode",
        "bars",
        "bath",
        "battery-empty",
        "battery-full",
        "battery-half",
        "battery-quarter",
        "battery-three-quarters",
        "bed",
        "beer",
        "bell",
        "bell-o",
        "bell-slash",
        "bell-slash-o",
        "bicycle",
        "binoculars",
        "birthday-cake",
        "blind",
        "bluetooth",
        "bluetooth-b",
        "bolt",
        "bomb",
        "book",
        "bookmark",
        "bookmark-o",
        "braille",
        "briefcase",
        "bug",
        "building",
        "building-o",
        "bullhorn",
        "bullseye",
        "bus",
        "calculator",
        "calendar",
        "calendar-check-o",
        "calendar-minus-o",
        "calendar-o",
        "calendar-plus-o",
        "calendar-times-o",
        "camera",
        "camera-retro",
        "car",
        "caret-square-o-down",
        "caret-square-o-left",
        "caret-square-o-right",
        "caret-square-o-up",
        "cart-arrow-down",
        "cart-plus",
        "cc",
        "certificate",
        "check",
        "check-circle",
        "check-circle-o",
        "check-square",
        "check-square-o",
        "child",
        "circle",
        "circle-o",
        "circle-o-notch",
        "circle-thin",
        "clock-o",
        "clone",
        "cloud",
        "cloud-download",
        "cloud-upload",
        "code",
        "code-fork",
        "coffee",
        "cog",
        "cogs",
        "comment",
        "comment-o",
        "commenting",
        "commenting-o",
        "comments",
        "comments-o",
        "compass",
        "copyright",
        "creative-commons",
        "credit-card",
        "credit-card-alt",
        "crop",
        "crosshairs",
        "cube",
        "cubes",
        "cutlery",
        "database",
        "deaf",
        "desktop",
        "diamond",
        "dot-circle-o",
        "download",
        "edit",
        "ellipsis-h",
        "ellipsis-v",
        "envelope",
        "envelope-o",
        "envelope-open",
        "envelope-open-o",
        "envelope-square",
        "eraser",
        "exchange",
        "exclamation",
        "exclamation-circle",
        "exclamation-triangle",
        "external-link",
        "external-link-square",
        "eye",
        "eye-slash",
        "eyedropper",
        "fax",
        "female",
        "fighter-jet",
        "file",
        "file-archive-o",
        "file-audio-o",
        "file-code-o",
        "file-excel-o",
        "file-image-o",
        "file-pdf-o",
        "file-powerpoint-o",
        "file-text",
        "file-text-o",
        "file-video-o",
        "file-word-o",
        "film",
        "filter",
        "fire",
        "fire-extinguisher",
        "flag",
        "flag-checkered",
        "flag-o",
        "flask",
        "folder",
        "folder-o",
        "folder-open",
        "folder-open-o",
        "frown-o",
        "futbol-o",
        "gamepad",
        "gavel",
        "gift",
        "glass",
        "globe",
        "graduation-cap",
        "hand-lizard-o",
        "hand-o-down",
        "hand-o-left",
        "hand-o-right",
        "hand-o-up",
        "hand-paper-o",
        "hand-peace-o",
        "hand-pointer-o",
        "hand-rock-o",
        "hand-scissors-o",
        "hand-spock-o",
        "handshake-o",
        "hashtag",
        "hdd-o",
        "headphones",
        "heart",
        "heart-o",
        "heartbeat",
        "history",
        "home",
        "hourglass",
        "hourglass-end",
        "hourglass-half",
        "hourglass-o",
        "hourglass-start",
        "i-cursor",
        "id-badge",
        "id-card",
        "id-card-o",
        "image",
        "inbox",
        "industry",
        "info",
        "info-circle",
        "key",
        "keyboard-o",
        "language",
        "laptop",
        "leaf",
        "lemon-o",
        "level-down",
        "level-up",
        "life-ring",
        "lightbulb-o",
        "line-chart",
        "location-arrow",
        "lock",
        "low-vision",
        "magic",
        "magnet",
        "mail-forward",
        "mail-reply",
        "mail-reply-all",
        "male",
        "map",
        "map-marker",
        "map-o",
        "map-pin",
        "map-signs",
        "meh-o",
        "microchip",
        "microphone",
        "microphone-slash",
        "minus",
        "minus-circle",
        "minus-square",
        "minus-square-o",
        "mobile",
        "money",
        "moon-o",
        "motorcycle",
        "mouse-pointer",
        "music",
        "newspaper-o",
        "object-group",
        "object-ungroup",
        "paint-brush",
        "paper-plane",
        "paper-plane-o",
        "paw",
        "pencil",
        "pencil-square",
        "pencil-square-o",
        "percent",
        "phone",
        "phone-square",
        "picture-o",
        "pie-chart",
        "plane",
        "plug",
        "plus",
        "plus-circle",
        "plus-square-o",
        "podcast",
        "power-off",
        "print",
        "puzzle-piece",
        "qrcode",
        "question",
        "question-circle",
        "question-circle-o",
        "quote-left",
        "quote-right",
        "random",
        "recycle",
        "refresh",
        "registered",
        "reply",
        "reply-all",
        "retweet",
        "road",
        "rocket",
        "rss",
        "rss-square",
        "search",
        "search-minus",
        "search-plus",
        "server",
        "share",
        "share-alt",
        "share-alt-square",
        "share-square",
        "share-square-o",
        "shield",
        "ship",
        "shopping-bag",
        "shopping-basket",
        "shopping-cart",
        "shower",
        "sign-in",
        "sign-language",
        "sign-out",
        "signal",
        "sitemap",
        "sliders",
        "smile-o",
        "snowflake-o",
        "sort",
        "sort-alpha-asc",
        "sort-alpha-desc",
        "sort-amount-asc",
        "sort-amount-desc",
        "sort-asc",
        "sort-desc",
        "sort-numeric-asc",
        "sort-numeric-desc",
        "space-shuttle",
        "spinner",
        "spoon",
        "square",
        "square-o",
        "star",
        "star-half",
        "star-half-o",
        "star-o",
        "sticky-note",
        "sticky-note-o",
        "street-view",
        "suitcase",
        "sun-o",
        "support",
        "tablet",
        "tachometer",
        "tag",
        "tags",
        "tasks",
        "taxi",
        "television",
        "terminal",
        "thermometer-empty",
        "thermometer-full",
        "thermometer-half",
        "thermometer-quarter",
        "thermometer-three-quarters",
        "thumb-tack",
        "thumbs-down",
        "thumbs-o-down",
        "thumbs-o-up",
        "thumbs-up",
        "ticket",
        "times",
        "times-circle",
        "times-circle-o",
        "tint",
        "toggle-off",
        "toggle-on",
        "trademark",
        "trash",
        "trash-o",
        "tree",
        "trophy",
        "truck",
        "tty",
        "umbrella",
        "universal-access",
        "university",
        "unlock",
        "unlock-alt",
        "upload",
        "user",
        "user-circle",
        "user-circle-o",
        "user-o",
        "user-plus",
        "user-secret",
        "user-times",
        "users",
        "video-camera",
        "volume-control-phone",
        "volume-down",
        "volume-off",
        "volume-up",
        "warning",
        "wheelchair",
        "wheelchair-alt",
        "wifi",
        "window-close",
        "window-close-o",
        "window-maximize",
        "window-minimize",
        "window-restore",
        "wrench",
        "ambulance",
        "h-square",
        "hospital-o",
        "medkit",
        "plus-square",
        "stethoscope",
        "user-md",
        "wheelchair-alt",
        "hand-grab-o",
        "hand-stop-o",
        "hand-o-down",
        "hand-o-left",
        "hand-o-right",
        "hand-o-up",
        "ambulance",
        "automobile",
        "bicycle",
        "bus",
        "car",
        "fighter-jet",
        "motorcycle",
        "plane",
        "rocket",
        "ship",
        "space-shuttle",
        "subway",
        "taxi",
        "train",
        "truck",
        "genderless",
        "mars",
        "mars-double",
        "mars-stroke",
        "mars-stroke-h",
        "mars-stroke-v",
        "mercury",
        "neuter",
        "transgender",
        "transgender-alt",
        "venus",
        "venus-double",
        "venus-mars",
        "cc-amex",
        "cc-diners-club",
        "cc-discover",
        "cc-jcb",
        "cc-mastercard",
        "cc-paypal",
        "cc-stripe",
        "cc-visa",
        "google-wallet",
        "paypal",
        "area-chart",
        "bar-chart",
        "line-chart",
        "pie-chart",
        "btc",
        "eur",
        "gbp",
        "gg",
        "gg-circle",
        "ils",
        "inr",
        "jpy",
        "krw",
        "money",
        "rub",
        "usd",
        "viacoin",
        "align-center",
        "align-justify",
        "align-left",
        "align-right",
        "bold",
        "chain",
        "chain-broken",
        "clipboard",
        "columns",
        "copy",
        "cut",
        "dedent",
        "eraser",
        "file",
        "file-o",
        "file-text",
        "file-text-o",
        "files-o",
        "floppy-o",
        "font",
        "header",
        "indent",
        "italic",
        "link",
        "list",
        "list-alt",
        "list-ol",
        "list-ul",
        "outdent",
        "paperclip",
        "paragraph",
        "paste",
        "repeat",
        "rotate-left",
        "rotate-right",
        "save",
        "scissors",
        "strikethrough",
        "subscript",
        "superscript",
        "table",
        "text-height",
        "text-width",
        "th",
        "th-large",
        "th-list",
        "underline",
        "undo",
        "unlink",
        "angle-double-down",
        "angle-double-left",
        "angle-double-right",
        "angle-double-up",
        "angle-down",
        "angle-left",
        "angle-right",
        "angle-up",
        "arrow-circle-down",
        "arrow-circle-left",
        "arrow-circle-o-down",
        "arrow-circle-o-left",
        "arrow-circle-o-right",
        "arrow-circle-o-up",
        "arrow-circle-right",
        "arrow-circle-up",
        "arrow-down",
        "arrow-left",
        "arrow-right",
        "arrow-up",
        "arrows",
        "arrows-alt",
        "arrows-h",
        "arrows-v",
        "caret-down",
        "caret-left",
        "caret-right",
        "caret-up",
        "chevron-circle-down",
        "chevron-circle-left",
        "chevron-circle-right",
        "chevron-circle-up",
        "chevron-down",
        "chevron-left",
        "chevron-right",
        "chevron-up",
        "hand-o-down",
        "hand-o-left",
        "hand-o-right",
        "hand-o-up",
        "long-arrow-down",
        "long-arrow-left",
        "long-arrow-right",
        "long-arrow-up",
        "backward",
        "compress",
        "eject",
        "expand",
        "fast-backward",
        "fast-forward",
        "forward",
        "pause",
        "pause-circle",
        "pause-circle-o",
        "play",
        "play-circle",
        "play-circle-o",
        "step-backward",
        "step-forward",
        "stop",
        "stop-circle",
        "stop-circle-o",
        "youtube-play",
        "500px",
        "adn",
        "amazon",
        "android",
        "angellist",
        "apple",
        "bandcamp",
        "behance",
        "behance-square",
        "bitbucket",
        "bitbucket-square",
        "bitcoin",
        "black-tie",
        "bluetooth",
        "bluetooth-b",
        "btc",
        "buysellads",
        "cc-amex",
        "cc-diners-club",
        "cc-discover",
        "cc-jcb",
        "cc-mastercard",
        "cc-paypal",
        "cc-stripe",
        "cc-visa",
        "chrome",
        "codepen",
        "codiepie",
        "connectdevelop",
        "contao",
        "css3",
        "dashcube",
        "delicious",
        "deviantart",
        "digg",
        "dribbble",
        "dropbox",
        "drupal",
        "edge",
        "empire",
        "expeditedssl",
        "facebook",
        "facebook-official",
        "facebook-square",
        "firefox",
        "flickr",
        "fonticons",
        "fort-awesome",
        "forumbee",
        "foursquare",
        "ge",
        "get-pocket",
        "gg",
        "gg-circle",
        "git",
        "git-square",
        "github",
        "github-alt",
        "github-square",
        "gitlab",
        "gittip",
        "glide",
        "glide-g",
        "google",
        "google-plus",
        "google-plus-circle",
        "google-plus-official",
        "google-plus-square",
        "google-wallet",
        "gratipay",
        "grav",
        "hacker-news",
        "houzz",
        "html5",
        "imdb",
        "instagram",
        "internet-explorer",
        "ioxhost",
        "joomla",
        "jsfiddle",
        "lastfm",
        "lastfm-square",
        "leanpub",
        "linkedin",
        "linkedin-square",
        "linux",
        "maxcdn",
        "meanpath",
        "medium",
        "mixcloud",
        "modx",
        "odnoklassniki",
        "odnoklassniki-square",
        "opencart",
        "openid",
        "opera",
        "optin-monster",
        "pagelines",
        "pied-piper",
        "pied-piper-alt",
        "pinterest",
        "pinterest-p",
        "pinterest-square",
        "product-hunt",
        "qq",
        "quora",
        "rebel",
        "reddit",
        "reddit-alien",
        "reddit-square",
        "renren",
        "safari",
        "scribd",
        "sellsy",
        "share-alt",
        "share-alt-square",
        "shirtsinbulk",
        "simplybuilt",
        "skyatlas",
        "skype",
        "slack",
        "slideshare",
        "snapchat",
        "snapchat-ghost",
        "snapchat-square",
        "soundcloud",
        "spotify",
        "stack-exchange",
        "stack-overflow",
        "steam",
        "steam-square",
        "stumbleupon",
        "stumbleupon-circle",
        "superpowers",
        "telegram",
        "tencent-weibo",
        "themeisle",
        "trello",
        "tripadvisor",
        "tumblr",
        "tumblr-square",
        "twitch",
        "twitter",
        "twitter-square",
        "usb",
        "viacoin",
        "viadeo",
        "viadeo-square",
        "vimeo",
        "vimeo-square",
        "vine",
        "vk",
        "wechat",
        "weibo",
        "weixin",
        "whatsapp",
        "wikipedia-w",
        "windows",
        "wordpress",
        "wpbeginner",
        "wpforms",
        "xing",
        "xing-square",
        "y-combinator",
        "y-combinator-square",
        "yahoo",
        "yelp",
        "yoast",
        "youtube",
        "youtube-play",
        "youtube-square",
      ],
      selectedIcon:
        this.field.value.icon == null ? null : this.field.value.icon,
      hideIconPicker: true,
    };
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    this.addFontAwesome();
  },
  methods: {
    // Add font awesome styles from CDN
    addFontAwesome() {
      let fontAwesomeLink = document.createElement("link");
      fontAwesomeLink.setAttribute("rel", "stylesheet");
      fontAwesomeLink.setAttribute(
        "href",
        "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      );
      document.head.appendChild(fontAwesomeLink);
    },

    showIconPicker() {
      this.hideIconPicker = false;
    },

    closeModal() {
      this.hideIconPicker = true;
    },

    iconSelected(icon) {
      this.selectedIcon = icon;
      this.field.value.icon = this.selectedIcon;
      this.formChanged();
      this.closeModal();
    },

    formChanged() {
      this.$emit("fieldChanged");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.field-wrapper {
  position: relative;
}

.input-selector-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-preview {
  display: block;
  position: relative;
  background-color: $whiteColor;
  padding: 0.5rem;
  border: 1px solid #e7e6ef;
  border-radius: 0.5rem;
  -webkit-appearance: none;
  color: $darkBlackColor;
  font-size: 1rem;
  margin-right: 1rem;
  cursor: pointer;

  &.icon-add {
    font-size: $normalFontSize;
    color: darken($whiteColor, 35%);
    padding: 0.5rem 0.5rem;
    margin-left: 0.6rem;
  }

  &:hover {
    border-color: $blackColor;
    &.icon-add {
      color: $blackColor;
    }
  }
}

.icons-wrapper {
  position: relative;
  height: 13rem;
  overflow-y: scroll;

  &.input-selected {
    &::after {
      display: none;
    }
  }

  i {
    display: inline-block;
    position: relative;
    background-color: $whiteColor;
    padding: 0.5rem;
    border: 1px solid #e7e6ef;
    border-radius: 0.5rem;
    -webkit-appearance: none;
    color: $darkBlackColor;
    font-size: 1rem;
    margin: 0.5rem;
    cursor: pointer;

    &:hover {
      border-color: $blackColor;
      &.icon-add {
        color: $blackColor;
      }
    }

    &.selected {
      border-color: lighten($blackColor, 5%);
      background-color: $darkBlackColor;
      color: lighten($blackColor, 75%);
    }
  }

  input {
    flex: 1;
  }
}

.icon-loader-wrapper {
  background-color: $whiteColor;
  padding: 0.5rem;
  border: 1px solid $greyBorderColor;
  box-shadow: 0 0 0.7rem -0.1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  position: absolute !important;
  z-index: 10;
  bottom: 3rem;
  left: -20%;
  width: 21rem;

  .title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0.5rem !important;
    padding: 0.15rem !important;

    .label-small {
      display: block;
      font-size: $smallerFontSize;
      font-weight: $mediumFontWeight;
      opacity: 0.75;
      flex: 1;
    }

    .modal-close {
      cursor: pointer;
      /deep/ {
        svg {
          height: auto;
          width: 1.25rem;
          fill: darken($whiteColor, 55%) !important;
        }
      }
    }
  }
  /deep/ {
    .search > div {
      display: none !important;
    }

    ul {
      background: darken($whiteColor, 1.5%);
      border: 1px solid darken($whiteColor, 15%);
      border-radius: 0.5rem;
    }

    li {
      transition: none;
      color: darken($whiteColor, 55%);
      width: calc(14.28% - 10px);
      margin: 0;
      padding: 5px;
      text-align: center;
      border: none;

      &:hover {
        transform: none;
        color: $blackColor;
        border: none;
        background-color: transparent;
      }

      &.selected {
        transform: none;
        color: $whiteColor;
        background-color: $darkBlackColor;
        border-radius: 0.5rem;
        border: none;
      }
    }
  }
}
</style>