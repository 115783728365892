<template>
  <div class="shipping-status">
    <unicon
      :name="getShippingIcon(shipping)"
      :class="getShippingIcon(shipping)"
    ></unicon>
    <div class="shipping-info">
      <div class="status-wrapper">
        <span v-if="shipping.updatedAt" class="updated-date">
          {{ getShippingDate(shipping.updatedAt) }}
        </span>
        <span class="status">{{ getStatus(shipping) }}</span>
        <p class="status-description">
          {{ getStatusDescription(shipping) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShippingStatus",
  data() {
    return {};
  },
  props: { shipping: { type: Object, required: true } },
  watch: {},
  components: {},
  computed: {},
  created() {},
  methods: {
    // Find the status type
    getStatusType(shipping) {
      let status = shipping.status;
      let showShipmentStatus = false;
      switch (shipping.shipmentStatus) {
        case "in_transit":
        case "out_for_delivery":
        case "delivered":
          showShipmentStatus = true;
          status = shipping.shipmentStatus;
          break;
      }

      return { status, showShipmentStatus };
    },

    // Get the icon depending on the status
    getShippingIcon(shipping) {
      let icon = "";
      switch (shipping.status) {
        case "pending":
        case "processing":
        case "label_printed":
        case "label_purchased":
        case "ready_for_pickup":
        case "confirmed":
          icon = "export";
          break;

        case "open":
        case "success":
        case "attempted_delivery":
        case "in_transit":
        case "out_for_delivery":
          icon = "truck";
          break;

        case "delivered":
          icon = "check";
          break;

        case "cancelled":
        case "failure":
        case "error":
          icon = "times";
          break;

        case "refund":
          icon = "exchange";
          break;
      }

      return icon;
    },

    // Get the shipment status
    getStatus(shipping) {
      const statusType = this.getStatusType(shipping);

      if (statusType.showShipmentStatus)
        return this.languages[this.$store.getters.widgetLanguage].widget.chat
          .orderDetails.shipmentStatus[statusType.status].title;
      else
        return this.languages[this.$store.getters.widgetLanguage].widget.chat
          .orderDetails.fulfillmentStatus[statusType.status].title;
    },

    // Get the shipment status
    getStatusDescription(shipping) {
      const statusType = this.getStatusType(shipping);

      if (statusType.showShipmentStatus)
        return this.languages[this.$store.getters.widgetLanguage].widget.chat
          .orderDetails.shipmentStatus[statusType.status].description;
      else
        return this.languages[this.$store.getters.widgetLanguage].widget.chat
          .orderDetails.fulfillmentStatus[statusType.status].description;
    },

    getShippingDate(shippingDate) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      return new Date(shippingDate).toLocaleTimeString([], options);
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/chat.scss";
.card {
  margin-top: 1rem !important;
  border-radius: 0.5rem !important;
}

.shipping-status {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1rem;

  .unicon /deep/ {
    svg {
      fill: darken($whiteColor, 45%);
      background-color: darken($whiteColor, 10%);
      padding: 8.5px;
      height: auto;
      width: 1rem;
      border-radius: 0.5rem;
    }

    &.truck svg,
    &.check svg {
      fill: $greenMessageTextColor;
      background-color: lighten($greenMessageColor, 0%);
      border: 1px solid $greenMessageBorderColor;
    }

    &.exchange svg,
    &.export svg {
      fill: darken($warningYellowBackgroundColor, 70%);
      background-color: darken($warningYellowBackgroundColor, 5%);
      border: 1px solid $warningYellowBorderColor;
    }

    &.times svg {
      fill: $redMessageTextColor;
      background-color: lighten($redMessageColor, 5%);
      border: 1px solid $redMessageBorderColor;
    }
  }

  .shipping-info {
    flex: 1;
    margin-left: 1rem;

    .status {
      font-size: $smallFontSize;
      font-weight: $mediumFontWeight;
      text-transform: capitalize;
      white-space: nowrap;
    }

    .status-description {
      display: block;
      font-size: $smallerFontSize;
      color: lighten($darkBlackColor, 25%);
    }
  }

  .updated-date {
    display: block;
    color: lighten($darkBlackColor, 35%);
    font-size: $smallestFontSize;
    font-weight: $mediumFontWeight;
    margin: -0.15rem 0 0.15rem;
  }
}

.logistics-partner {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 1.5rem;

  .unicon /deep/ {
    svg {
      fill: darken($whiteColor, 45%);
      padding: 2px;
      height: auto;
      width: 1rem;
    }
  }

  .logistics-info {
    flex: 1;
    margin-left: 1rem;

    label {
      display: block;
      color: lighten($darkBlackColor, 35%);
      font-size: $smallestFontSize;
      font-weight: $mediumFontWeight;
      margin: -0.15rem 0 0.15rem;
    }

    .name {
      color: $darkBlackColor;
      font-weight: $mediumFontWeight;
      font-size: $smallFontSize;
      margin-right: 1rem;
    }

    .tracking-link {
      color: $blueColor;
      font-size: $smallFontSize;
    }
  }
}

.btn-track {
  display: block;
  margin-top: 1rem;
}
</style>