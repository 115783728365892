<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button class="btn btn-small btn-grey" @click="addRule">Add Rule</button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Zero State -->
    <ZeroState
      v-if="showOptions.initRules && aiAgent.answerRules.length == 0"
      class="zero-state"
      :imageURL="zeroStateData.imageURL"
      :title="zeroStateData.title"
      :message="zeroStateData.message"
      :buttonTitle="zeroStateData.buttonTitle"
      @eventHandler="addRule"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: AI Answer Rules Table-->
    <AiAnswerRulesTable
      v-else
      :rules="aiAgent.answerRules"
      @ruleSelected="showRule"
      @deleteRule="deleteRule"
    ></AiAnswerRulesTable>
    <!--END: AI Answer Rules Table-->

    <!--START: Loader-->
    <PageLoader v-if="!showOptions.initRules" :showLoader="true"></PageLoader>
    <!--END: Loader-->

    <!--START: Add Rule-->
    <ViewRule
      v-if="showOptions.addRule"
      :show="showOptions.addRule"
      :selectedRule="selectedRule"
      @ruleAdded="ruleAdded"
      @closeModal="closeModal"
    ></ViewRule>
    <!--END: Add Rule-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
        
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import ViewRule from "@/components/dashboard/aiAgent/ViewRule";
import AiAnswerRulesTable from "@/components/dashboard/tables/AiAnswerRulesTable";
import ZeroState from "@/components/misc/ZeroState";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";

// Importing Services
import { AiAgentService } from "@/services";

export default {
  name: "AiAnswerRules",
  data() {
    return {
      showOptions: {
        initRules: false,
        addRule: false,
        lineLoader: false,
      },
      regularHeader: {
        title: "Answer Rules",
        description: "Define rules for the AI when creating answers",
      },
      zeroStateData: {
        imageURL: "services.jpg",
        title: "Add answer rules",
        message: "Add answer rules for the AI to follow",
        buttonTitle: "Add Rule",
      },
      status: {
        show: false,
        status: "success",
        title: "Rule deleted",
      },
      selectedRule: {},
    };
  },
  props: {
    aiAgent: { type: Object, required: true },
  },
  watch: {},
  components: {
    RegularHeader,
    ViewRule,
    AiAnswerRulesTable,
    ZeroState,
    NotificationMessage,
    LineLoader,
    PageLoader,
  },
  computed: {},
  async created() {
    this.showOptions.initRules = true;
  },
  methods: {
    
    addRule() {
      this.showOptions.addRule = true;
    },

    showRule(rule) {
      this.selectedRule = rule;
      this.addRule();
    },

    ruleAdded(rule) {
      let tempRules = _.cloneDeep(this.aiAgent.answerRules);
      const index = tempRules.findIndex((i) => i._id === rule._id);

      if (index !== -1) tempRules[index] = rule;
      else tempRules.push(rule);

      this.$parent.aiAgent.answerRules.splice(0);
      this.$parent.aiAgent.answerRules = _.cloneDeep(tempRules);
    },

    async deleteRule(ruleID) {
      this.showOptions.lineLoader = true;

      const data = { ruleID: ruleID };
      await AiAgentService.DeleteAnswerRule(data);

      // Remove from the table
      const index = this.aiAgent.answerRules.findIndex((r) => r._id == ruleID);
      this.$parent.aiAgent.answerRules.splice(index, 1);

      this.status.title = "Rule deleted";
      this.showStatusMessage(this.status, 2500);
      this.showOptions.lineLoader = false;
    },

    closeModal() {
      this.selectedRule = {};
      this.showOptions.addRule = false;
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
</style>