<template>
  <div class="key-metrics">
    <!--START: Deflections-->
    <div class="stat-item">
      <b class="stat">{{ getDeflections }}</b>
      <div class="stat-label">
        <span>Deflections</span>
        <div class="stat-info">
          <span>i</span>
          <p>
            Conversations that the AI has handled which has ensured the customer
            didn't need to speak to a human
          </p>
        </div>
      </div>
    </div>
    <!--END: Deflections-->

    <!--START: Resolutions-->
    <div class="stat-item">
      <b class="stat">{{ insights.totalResolutions }}</b>
      <div class="stat-label">
        <span>Resolutions</span>
        <div class="stat-info">
          <span>i</span>
          <p>
            Conversations succesfully resolved by the AI, which the customer has
            acknowledged
          </p>
        </div>
      </div>
    </div>
    <!--END: Resolutions-->

    <!--START: Handoffs-->
    <div class="stat-item">
      <b class="stat">{{ insights.totalHandoffs }}</b>
      <div class="stat-label">
        <span>Handoffs</span>
        <div class="stat-info">
          <span>i</span>
          <p>
            The number of times the AI has successfully transitioned a
            conversation to human support
          </p>
        </div>
      </div>
    </div>
    <!--END: Handoffs-->
  </div>
</template>

<script>
export default {
  name: "KeyMetrics",
  props: {
    insights: { type: Object, required: true },
  },
  data() {
    return {};
  },
  components: {},
  computed: {
    // Get the deflections
    getDeflections() {
      const { totalHandoffs, totalResolutions, totalConversations } =
        this.insights;
      const deflections = totalConversations - totalHandoffs - totalResolutions;

      return deflections;
    },
  },
  async created() {},
  methods: {},
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.key-metrics {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.stat {
  margin-left: -0.5rem;
}
.stat-item {
  margin-left: 2.5rem;
  padding-left: 2.5rem;
  border-left: 1px solid $greyBorderColor;

  &:first-child {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    .stat-info p {
      left: auto;
      right: 0;
      transform: none;
    }
  }
}
</style>