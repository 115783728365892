<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
    </div>
    <!--END: Header Wrapper-->

    <!--START: Loader-->
    <PageLoader
      v-if="!showOptions.initCustomReply"
      :showLoader="true"
    ></PageLoader>
    <!--END: Loader-->

    <!--START: Custom Reply-->
    <div id="drawflow" ref="drawflow">
      <div class="actions-wrapper">
        <unicon name="lock"></unicon>
        <unicon
          name="plus"
          class="action-btn"
          @click="zoomChart('plus')"
        ></unicon>
        <unicon
          name="minus"
          class="action-btn"
          @click="zoomChart('minus')"
        ></unicon>
        <span class="beta-mode"
          ><em>BETA</em> Editing custom replies are in beta</span
        >
      </div>
    </div>
    <!--END: Custom Reply-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->

    <!--START: Unsaved Changes Modal -->
    <UnsavedChangesModal
      :show="showOptions.unsavedChanges"
      @primaryEvent="continueRoute"
      @secondaryEvent="closeModal"
    ></UnsavedChangesModal>
    <!--END: Unsaved Changes Modal -->
  </div>
</template>
          
<script>
// Import libraries
import _ from "lodash";
import Drawflow from "drawflow";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal";

// Importing Services
import { CustomReplyService } from "@/services";

export default {
  name: "CustomReply",
  data() {
    return {
      showOptions: {
        lineLoader: false,
        initCustomReply: false,
        disableButton: true,
        unsavedChanges: false,
      },
      regularHeader: {
        title: "Custom Reply",
        description:
          "Achieve complex replies and workflows for categories and intents",
      },
      customReplyID: null,
      customReply: {},
      initData: {},
      editor: {},
      dataChanged: false,
      status: {
        show: false,
        status: "success",
        title: "Custom Reply deleted",
      },
      nodeConnections: [],
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    NotificationMessage,
    LineLoader,
    PageLoader,
    UnsavedChangesModal,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },

    hasCompanyLoaded() {
      return !_.isEmpty(this.company);
    },
  },
  async created() {
    await this.getCustomReply();
    this.showOptions.initCustomReply = true;
  },
  mounted() {
    this.initDrawflow();
  },
  methods: {
    initDrawflow() {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href =
        "https://cdn.jsdelivr.net/gh/jerosoler/Drawflow@0.0.48/dist/drawflow.min.css";
      document.head.appendChild(link);
    },

    async getCustomReply() {
      this.customReplyID = this.$route.params.customReplyID;

      if (this.customReplyID) {
        const data = { customReplyID: this.customReplyID };
        const response = await CustomReplyService.GetCustomReply(data);

        // Check for errors
        if (!response.hasError) {
          this.customReply = response.data;
          this.regularHeader.title = this.customReply.name;
          this.constructCustomReply();
        } else this.showErrorMessage(this.status, response.message);
      }

      // Hide the pre loader
      this.initFormData();
      this.showOptions.preLoader = false;
    },

    // Construct the custom reply
    constructCustomReply() {
      const { nodes } = this.customReply;

      let nodeBufferX = 50;
      let nodeBufferY = 50;
      const nodeBuffer = 300;
      const actionBuffer = 350;
      const branchBuffer = 120;
      const drawFlowData = {};

      nodes.forEach((node, nodeIndex) => {
        node.actions.forEach((action, actionIndex) => {
          let drawflowNodeID = "";
          let html = "";
          let inputs = {};
          let outputs = {};
          let posX = 0;
          let posY = 0;

          if (action.actionType !== "condition") {
            html = this.constructActionHtml(
              action,
              actionIndex,
              node.actions.length === actionIndex + 1
            );

            if (actionIndex > 0) {
              inputs = {
                input_1: {
                  connections: [
                    {
                      node: `${node._id}-${node.actions[actionIndex - 1]._id}`,
                      input: `output_1`,
                    },
                  ],
                },
              };
            }
            if (
              node.actions.length > 0 &&
              node.actions.length !== actionIndex + 1
            ) {
              outputs = {
                output_1: {
                  connections: [
                    {
                      node: `${node._id}-${node.actions[actionIndex + 1]._id}`,
                      output: `input_1`,
                    },
                  ],
                },
              };
            }

            drawflowNodeID = `${node._id}-${action._id}`;
            posX = nodeBufferX + actionBuffer * actionIndex + nodeIndex * 100;
            posY = nodeBufferY + nodeBuffer * nodeIndex;
            drawFlowData[drawflowNodeID] = {
              id: drawflowNodeID,
              class: "node",
              html,
              typenode: false,
              data: {},
              inputs: inputs,
              outputs: outputs,
              pos_x: posX,
              pos_y: posY,
            };
          } else {
            action.branches.forEach((branch, branchIndex) => {
              html = this.constructBranchHtml(
                branch,
                branchIndex,
                nodeIndex,
                actionIndex
              );

              inputs = {
                input_1: {
                  connections: [
                    {
                      node: `${node._id}-${node.actions[actionIndex - 1]._id}`,
                      input: `output_1`,
                    },
                  ],
                },
              };

              if (!_.isEmpty(branch.nextNode)) {
                this.nodeConnections.push({
                  inputNode: `${branch.nextNode.nodeID}-${branch.nextNode.actionID}`,
                  incomingConnection: `${node._id}-${action._id}-${branchIndex}`,
                });
                outputs = {
                  output_1: {
                    connections: [
                      {
                        node: `${branch.nextNode.nodeID}-${branch.nextNode.actionID}`,
                        output: `input_1`,
                      },
                    ],
                  },
                };
              }

              drawflowNodeID = `${node._id}-${action._id}-${branchIndex}`;
              posX = nodeBufferX + actionBuffer * actionIndex + nodeIndex * 100;
              posY =
                nodeBufferY +
                nodeBuffer * nodeIndex +
                branchBuffer * branchIndex;
              drawFlowData[drawflowNodeID] = {
                id: drawflowNodeID,
                class: "node",
                html,
                typenode: false,
                data: {},
                inputs: inputs,
                outputs: outputs,
                pos_x: posX,
                pos_y: posY,
              };
            });
          }
        });
      });

      this.nodeConnections.forEach((connection) => {
        const selectedNode = drawFlowData[connection.inputNode];
        const nodeConnection = {
          node: connection.incomingConnection,
          input: `output_1`,
        };

        if (selectedNode.inputs?.input_1?.connections) {
          selectedNode.inputs?.input_1?.connections.push(nodeConnection);
        } else {
          selectedNode.inputs = {
            input_1: { connections: [nodeConnection] },
          };
        }
      });

      const dataToImport = {
        drawflow: {
          Home: {
            data: drawFlowData,
          },
        },
      };

      this.editor = new Drawflow(this.$refs.drawflow);
      this.editor.reroute = true;
      this.editor.start();
      this.editor.editor_mode = "fixed";
      this.editor.import(dataToImport);
    },

    // Construct the HTML for the node
    constructActionHtml(action, index, isEndAction) {
      let html = `<div class='action ${isEndAction ? "end-action" : ""}'>`;

      switch (action.actionType) {
        case "message_content":
          html += `<div class='${action.actionType}'>
              <label class='action-title'>${index + 1}. Message</label>
              <p>${action.message.content}</p>
              </div>`;
          break;
        case "message_file":
          html += `<div class='${action.actionType}'>
              <label class='action-title'>${index + 1}. File</label>
              <p>${action.message.content}</p>
              <label class="file-label">File</label>
              <div class="file-preview">
                <img src='/assets/images/file-icon.png' alt='File'/>
                <div class="file-details">
                  <span class="file-url">${action.message.file.fileUrl}</span>
                  <span class="file-type">${action.message.file.fileType}</span>
                </div>
              </div>
              </div>`;
          break;
        case "message_ai_answer":
          html += `<div class='${action.actionType}'>
              <label class='action-title'>${index + 1}. AI Answer</label>
              <div class="ai-action">
                <img src='/assets/images/ai-sparkle.png' alt='AI'/>
                <span>Generate AI answer</span>
              </div>`;

          if (action.message.aiAnswer.sources.length > 0) {
            html += `<label class="ai-answer-label">The AI will use the following sources</label>`;
            action.message.aiAnswer.sources.forEach((s) => {
              html += `<div class="source-option">`;
              s.sourceIDs.forEach((sourceID) => {
                const source = this.company.sources.find(
                  (cs) => cs._id === sourceID
                );

                if (sourceID === this.company._id) {
                  html += `<div class="source-item">
                  <img src="${this.getLogoImage("macha-article")}" />
                  <span class="source-name">Macha Articles</span>
                  </div>`;
                } else {
                  html += `<div class="source-item">
                  <img src="${this.getLogoImage(source.sourceType)}" />
                  <span class="source-name">${source.name}</span>
                  </div>`;
                }
              });
              html += `<span class="source-count">${s.docCount} documents</span></div>`;
            });
          }

          if (action.message.aiAnswer.instruction) {
            html += `<div class="ai-instruction">
            <span>Instructions</span>
            <p>${action.message.aiAnswer.instruction}</p>
            </div>`;
          }

          html += `</div>`;
          break;
        case "answer_bubbles":
          html += `<div class='${action.actionType}'>
              <label class='action-title'>${index + 1}. Answer Bubbles</label>
              <p>${action.answerBubbles.content}</p>
              <div class="bubbles"><label>Options - <b>${
                action.dataModel.fields[0].key
              }</b></label>`;
          action.answerBubbles.bubbles.forEach((b) => {
            html += `<div class="bubble-option">
              <span class="bubble">${b.message}</span>
              <span class="bubble-connector"></span>
              <span class="bubble-value">${b.value}</span>
              </div>`;
          });
          html += "</div></div>";
          break;
        case "form":
          html += `<div class='${action.actionType}'>
              <label class='action-title'>${index + 1}. Form</label>
              ${action.form.title ? `<h3>${action.form.title}</h3>` : ""}
              ${
                action.form.description
                  ? `<p>${action.form.description}</p>`
                  : ""
              }`;
          action.dataModel.fields.forEach((f) => {
            html += `<div class="input-block">
                  <span class='input-field'>${f.title}</span>
                  ${
                    f.required
                      ? `<span class='input-required'>Required</span>`
                      : ""
                  }
                  </div>`;
          });

          html += `</div>`;

          break;
        case "integration_event":
          html += `<div class='${action.actionType}'>
              <label class='action-title'>${
                index + 1
              }. App Integration</label>`;

          switch (action.integrationEvent.appType) {
            case "shopify":
              switch (action.integrationEvent.event) {
                case "get_order":
                  html += `<div class="shopify-event get-order">
                    <img src='/assets/images/logos/shopify.jpg' alt='Shopify'/>
                    <span><b>Getting order</b> from Shopify</span>
                    </div>`;
                  break;
                case "show_order":
                  html += `<div class="shopify-event get-order">
                    <img src='/assets/images/logos/shopify.jpg' alt='Shopify'/>
                    <span><b>Showing order</b> from Shopify</span>
                    </div>`;
                  break;
              }
              break;
            default:
              break;
          }

          html += `</div>`;
          break;
        case "email_trigger":
          html += `<div class='${action.actionType} email-trigger'>
            <label class='action-title'>${index + 1}. Trigger Email</label>
              <div class="email-action">
                <img src='/assets/images/email-icon.png' alt='Email'/>
                <span>Send an email</span>
              </div>
              <div class="attribute">
                <span>To</span>
                <p>${action.emailTrigger.recipients}</p>
              </div>
              <div class="attribute">
                <span>Subject</span>
                <p>${action.emailTrigger.subject}</p>
              </div>
              <div class="attribute">
                <span>Body</span>
                <p>${action.emailTrigger.body}</p>
              </div>`;

          if (action.dataModel.fields.length > 0)
            html += `<label class="email-label">Fields</label>`;
          action.dataModel.fields.forEach((f) => {
            html += `<div class="input-block">
                  <span class='input-field'>${f.title}</span>
                  ${
                    f.required
                      ? `<span class='input-required'>Required</span>`
                      : ""
                  }
                  </div>`;
          });
          html += ` </div>`;
          break;
        case "widget_event":
          switch (action.widgetEvent.event) {
            case "end_chat":
              html += `<div class='${
                action.actionType
              } end-chat'><label class='action-title'>${
                index + 1
              }. End Chat</label>
                      <span>End the customer chat</span>
                      </div>`;
              break;
            case "handoff":
              html += `<div class='${action.actionType} handoff'>`;
              switch (action.widgetEvent.handoff.appType) {
                case "whatsapp":
                  html += `
                      <label class='action-title'>${
                        index + 1
                      }. Agent Handoff</label>
                      <div class='attribute'>
                        <span>App</span>
                        <div class='app-type'>
                          <img src='/assets/images/logos/whatsapp.jpg' alt='WhatsApp'/>
                          WhatsApp
                        </div>
                      </div>
                      <div class='attribute'>
                        <span>Number</span>
                        <p>${action.widgetEvent.handoff.whatsapp.number}</p>
                        </div>
                      <div class='attribute'>
                        <span>Delay</span>
                        <p>${action.widgetEvent.handoff.delay} ms</p>
                        </div>`;
                  break;
                case "zendesk-classic":
                  html += `
                      <label class='action-title'>${
                        index + 1
                      }. Agent Handoff</label>
                      <div class='attribute'><span>App</span>
                        <div class='app-type'>
                          <img src='/assets/images/logos/zendesk.jpg' alt='Zendesk'/>
                          Zendesk Classic
                        </div>
                      </div>
                      <div class='attribute'>
                        <span>Delay</span>
                        <p>${action.widgetEvent.handoff.delay} ms</p>
                        </div>`;
                  break;
              }
              html += `</div>`;
          }

          break;
        default:
          break;
      }

      html += "</div>";

      return html;
    },

    // Construct the HTML for the branch
    constructBranchHtml(branch, branchIndex, nodeIndex, actionIndex) {
      let html = `<div class='action condition-action ${
        branch.conditions.length == 0 ? "end-condition" : ""
      }'>
        <div class="branch">
          <label class='action-title'>${actionIndex + 1}. Condition ${
        branchIndex + 1
      }</label>`;

      branch.conditions.forEach((c) => {
        html += `<div class="condition-wrapper">
            <span class="parameter">${c.parameter.key}</span>
            <span class="operator">${c.operator}</span>
            <span class="compareValue">${c.compareValue.value}</span>
            </div>`;
      });

      if (branch.conditions.length == 0 && _.isEmpty(branch.nextNode)) {
        html += `<div class="condition-wrapper no-condition">
            <b>Else</b>
            <span class="condition-close">End Custom Reply</span>
            </div>`;
      } else if (branch.conditions.length == 0) {
        html += `<div class="condition-wrapper no-condition">
            <b>Else</b>
            <span class="condition-close">Goto next node</span>
            </div>`;
      }

      html += `</div>`;

      return html;
    },

    // Zoom the chart
    zoomChart(level) {
      switch (level) {
        case "plus":
          this.editor.zoom_in();
          break;
        case "minus":
          this.editor.zoom_out();
          break;
        default:
          break;
      }
    },

    // Get logo of source type
    getLogoImage(sourceType) {
      let icon = null;
      switch (sourceType) {
        case "macha-article":
          icon = "macha.jpg";
          break;
        case "shopify-products":
          icon = "shopify.jpg";
          break;
        case "zendesk-hc":
        case "zendesk-hc-api":
          icon = "zendesk.jpg";
          break;
      }

      return `/assets/images/logos/${icon}`;
    },

    // Initialise the form data for comparisons
    initFormData() {
      // Init the data
      const data = { ...this.customReply };
      this.initData = _.cloneDeep(data);

      this.disableSaveButton(true);
    },

    // Event when form field changes
    fieldChanged() {
      const data = { ...this.customReply };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.unsavedChanges = false;
    },

    // Discard all the changes
    discardChanges() {
      this.fields = _.cloneDeep(this.initData);
      this.disableSaveButton(true);
      this.closeModal();
    },

    // Discard the changes and move to the selected route
    continueRoute() {
      this.closeModal();
      this.discardChanges();
      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
    },
  },
  // Check if there are any changes to be saved
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      this.toRoute = to;
      this.showOptions.unsavedChanges = true;
    } else {
      next();
    }
  },
};
</script>
          
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

#drawflow {
  position: relative;
  width: calc(100% + 6rem);
  height: 100%;
  margin-left: -3rem;
  background: #fcfbff;
  background-size: 25px 25px;
  background-image: linear-gradient(to right, #f4f1ff 1px, transparent 1px),
    linear-gradient(to bottom, #f4f1ff 1px, transparent 1px);
}

/deep/ {
  .page-loader-wrapper {
    position: absolute;
    z-index: 10;
    top: 20%;
    left: 0;
    right: 0;
    margin: auto;
  }
}

/deep/ #drawflow {
  .node {
    padding: 0;
    background: none;
    border: none;
    width: auto;

    .input,
    .output {
      background: $purpleColor;
      border: 1px solid lighten($purpleColor, 5%);
      left: -4px;
      width: 8px;
      height: 8px;
    }

    .output {
      left: auto;
      right: 3px;
    }

    .action {
      background-color: $whiteColor;
      margin: 0;
      border-radius: 1rem;
      padding: 1.5rem;
      width: calc(300px - 3rem);
      border: 1px solid darken($cardBorderColor, 2.5%);
      box-shadow: $cardBoxShadow;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      .action-title {
        display: block;
        font-size: $smallestFontSize;
        background: darken($whiteColor, 2.5%);
        border-bottom: 1px solid darken($whiteColor, 5%);
        color: $greyColor;
        margin-bottom: 1rem;
        margin-top: -1.5rem;
        margin-left: -1.5rem;
        width: calc(100% + 1.5rem);
        padding: 0.75rem;
        border-top-left-radius: 0.9rem;
        border-top-right-radius: 0.9rem;
      }

      .attribute {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.5rem;

        span {
          flex: 1;
          margin-right: 0.5rem;
          color: $greyColor;
          font-weight: $mediumFontWeight;
          font-size: $smallestFontSize;
        }

        p {
          color: $blackColor;
          font-size: $smallerFontSize;
          text-align: right;
        }
      }

      .input-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: darken($whiteColor, 2%);
        border: 1px solid darken($whiteColor, 7.5%);
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        font-size: $smallerFontSize;
        margin-top: 0.5rem;
        margin-left: 0.5rem;

        .input-field {
          font-weight: $mediumFontWeight;
          flex: 1;
        }

        .input-required {
          font-size: $smallestFontSize;
          color: $redColor;
        }
      }

      .ai-instruction p,
      .answer_bubbles p,
      .message_content p,
      .message_file p {
        font-size: $smallFontSize;
        background: darken($whiteColor, 2.5%);
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        border: 1px solid darken($whiteColor, 5%);
      }

      .file-label {
        display: block;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
        opacity: $lightOpacity;
        font-size: $smallerFontSize;
      }

      .file-preview {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: darken($whiteColor, 2%);
        border: 1px solid darken($whiteColor, 7.5%);
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        overflow: hidden;

        img {
          width: 1rem;
          border-radius: 0.25rem;
          margin-right: 0.85rem;
        }

        .file-details {
          overflow: hidden;
        }

        .file-url {
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: $smallerFontSize;
          color: $orangeColor;
          width: 80%;
        }

        .file-type {
          display: block;
          font-size: $smallestFontSize;
          font-weight: $mediumFontWeight;
          opacity: $lightOpacity;
          text-transform: uppercase;
          margin-top: -.25rem;
        }
      }

      .answer_bubbles {
        .bubbles {
          margin-top: 0.5rem;

          label {
            font-size: $smallestFontSize;
            color: $greyColor;
          }

          .bubble-option {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 0.5rem;

            .bubble {
              font-size: $smallestFontSize;
              display: table;
              padding: 0.5rem 0.75rem;
              border-radius: 0.5rem;
              background-color: $purpleColor;
              color: $whiteColor;
              border: 1px solid lighten($purpleColor, 10%);
            }

            .bubble-connector {
              flex: 1;
              height: 1px;
              background: darken($whiteColor, 35%);
            }

            .bubble-value {
              font-size: $smallestFontSize;
              display: table;
              padding: 0.5rem 0.75rem;
              border-radius: 0.5rem;
              color: $greyColor;
              font-weight: $mediumFontWeight;
              border: 1px dashed darken($whiteColor, 35%);
            }
          }
        }
      }

      .end-chat {
        span {
          display: block;
          font-size: $smallFontSize;
          color: $purpleColor;
          font-weight: $mediumFontWeight;
          background: lighten($purpleColor, 40%);
          border: 1px dashed lighten($purpleColor, 20%);
          border-radius: 0.5rem;
          padding: 0.5rem 0.75rem;
          text-align: center;
        }
      }

      .handoff {
        .app-type {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: $smallerFontSize;
          color: $blackColor;
          background: darken($whiteColor, 2.5%);
          padding: 0.25rem 0.75rem 0.25rem 0.25rem;
          border-radius: 0.5rem;
          border: 1px solid darken($whiteColor, 5%);

          img {
            display: block;
            width: 1.25rem;
            margin-right: 0.5rem;
            border-radius: 0.25rem;
          }
        }
      }

      .form {
        h3 {
          font-size: $smallFontSize;
        }
        p {
          font-size: $smallerFontSize;
        }
      }

      .email-trigger {
        .email-action {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background: lighten($emeraldColor, 42.5%);
          border: 1px dashed lighten($emeraldColor, 20%);
          padding: 0.5rem 0.5rem 0.5rem 0.75rem;
          border-radius: 0.5rem;
          margin-bottom: 0.5rem;

          img {
            display: block;
            width: 0.85rem;
            margin-right: 0.5rem;
          }

          span {
            font-size: $smallerFontSize;
            color: darken($emeraldColor, 30%);
            font-weight: $mediumFontWeight;
          }
        }

        .email-label {
          display: block;
          color: $greyColor;
          font-size: $smallestFontSize;
          font-weight: $mediumFontWeight;
          margin-top: 0.5rem;
        }
      }

      .message_ai_answer {
        .ai-action {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: $darkBlackColor;
          border: 1px solid lighten($darkBlackColor, 20%);
          padding: 0.5rem 0.5rem 0.5rem 0.75rem;
          border-radius: 0.5rem;

          img {
            display: block;
            width: 1rem;
            opacity: 0.75;
            border-radius: 0.5rem;
            margin-right: 0.5rem;
          }

          span {
            font-size: $smallerFontSize;
            color: $whiteColor;
            font-weight: $mediumFontWeight;
          }
        }

        .ai-answer-label {
          display: block;
          font-size: $smallerFontSize;
          color: $greyColor;
          margin-top: 1.25rem;
          margin-bottom: 0.75rem;
        }

        .ai-instruction {
          margin-top: 1rem;

          span {
            display: block;
            font-size: $smallestFontSize;
            font-weight: $mediumFontWeight;
            color: $greyColor;
            margin-bottom: 0.5rem;
          }

          p {
            font-size: $smallerFontSize;
            max-height: 5rem;
            overflow-y: scroll;
          }
        }

        .source-option {
          background: darken($whiteColor, 2%);
          border: 1px solid darken($whiteColor, 7.5%);
          padding: 0.5rem 0.75rem;
          border-radius: 0.5rem;
          font-size: $smallerFontSize;
          margin-top: 0.5rem;

          .source-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0.25rem;
          }

          img {
            width: 1.15rem;
            border-radius: 0.25rem;
            margin-right: 0.5rem;
            border: 1px solid darken($whiteColor, 5%);
          }

          .source-name {
            flex: 1;
            font-weight: $mediumFontWeight;
          }

          .source-count {
            display: block;
            opacity: $lightOpacity;
            font-size: $smallestFontSize;
            margin-top: 0.5rem;
            text-align: right;
          }
        }
      }

      .shopify-event {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: lighten($emeraldColor, 42.5%);
        border: 1px dashed lighten($emeraldColor, 20%);
        padding: 0.5rem 0.5rem 0.5rem 0.75rem;
        border-radius: 0.5rem;

        img {
          display: block;
          width: 2.25rem;
          border-radius: 0.5rem;
          margin-right: 0.5rem;
        }

        span {
          font-size: $smallerFontSize;
          color: darken($emeraldColor, 30%);
          font-weight: $mediumFontWeight;
        }

        b {
          text-decoration: underline;
        }
      }

      &.condition-action {
        width: 200px;
        border: 2px solid $yellowColor;
        background-color: lighten($yellowColor, 37.5%);
        padding: 1rem;

        .action-title {
          background-color: lighten($yellowColor, 20.5%);
          color: darken($yellowColor, 40.5%);
          margin-left: -1rem;
          margin-top: -1rem;
          margin-bottom: 0.5rem;
          width: calc(100% + 0.5rem);
        }

        .condition-wrapper span,
        b {
          font-size: $smallerFontSize;
          color: darken($yellowColor, 50%);

          &.parameter,
          &.compareValue {
            font-weight: $boldFontWeight;
          }
        }
      }

      &.end-action {
        border: 2px solid lighten($purpleColor, 25%);
        background: lighten($purpleColor, 42.5%);

        .action-title {
          background-color: $purpleColor;
          color: $whiteColor;
        }
      }
    }
  }

  .drawflow {
    > .parent-node:first-child > .node .action {
      border: 1px solid $emeraldColor;
      border-top-left-radius: 0;

      &::before {
        content: "Starting Node";
        position: absolute;
        bottom: 100%;
        left: -1px;
        font-size: $smallestFontSize;
        background: $emeraldColor;
        color: lighten($emeraldColor, 45%);
        font-weight: $mediumFontWeight;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        padding: 0.5rem;
      }
    }

    .connection .main-path {
      stroke-width: 2px;
      stroke: #ddd5f0;
    }
  }
}

.actions-wrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;

  .unicon {
    /deep/ svg {
      height: auto;
      width: 1rem;
      padding: 0.5rem;
      border-radius: 0.5rem;
      background: #1e1e1e;
      border: 1px solid lighten(#1e1e1e, 10%);
      box-shadow: 0 0 0.7rem -0.15rem rgba(0, 0, 0, 0.15);
      fill: darken($whiteColor, 30%);
    }
  }

  .action-btn {
    margin-left: 0.5rem;

    &:hover {
      cursor: pointer;
      /deep/ svg {
        fill: $whiteColor;
        border: 1px solid lighten(#1e1e1e, 20%);
      }
    }
  }

  span {
    font-size: $smallestFontSize;
    color: darken($whiteColor, 60%);
    padding: 0.5rem 0.85rem 0.5rem 0.5rem;
    border-radius: 0.5rem;
    background: $whiteColor;
    border: 1px solid darken($whiteColor, 10%);
    box-shadow: 0 0 0.7rem -0.15rem rgba(0, 0, 0, 0.15);
    margin-left: 0.5rem;
  }

  .beta-mode em {
    background: $emeraldColor;
    border: 1px solid lighten($emeraldColor, 15%);
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    color: $whiteColor;
    font-weight: $mediumFontWeight;
    margin-right: 0.25rem;
  }
}
</style>