<template>
  <div>
    <div class="card">
      <h3>Pre Chat Form</h3>
      <div
        v-for="(value, key) in this.chat.workflows.preChat.fields"
        :key="key"
        class="field"
      >
        <span>{{ key }}</span>
        <p>{{ value }}</p>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "PreChatForm",
  props: { chat: { type: Object, required: true } },
};
</script>
      
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.card {
  padding: 1.25rem;
  margin: 1rem 0;
  width: 45%;

  h3 {
    font-size: $normalFontSize;
  }

  .field {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.25rem;

    span {
      font-size: $smallFontSize;
      margin-right: 1rem;
      color: $greyColor;
      opacity: $lightOpacity;
    }

    p {
      color: $inputTextColor;
      font-size: $smallFontSize;
      font-weight: $mediumFontWeight;
    }
  }
}
</style>