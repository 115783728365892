import Vue from "vue";
import VueRouter from "vue-router";

// START: Authentication Views
import Register from "@/views/Authentication/Register";
import Login from "@/views/Authentication/Login";
import Logout from "@/views/Authentication/Logout";
import AuthenticateLogin from "@/views/Authentication/AuthenticateLogin";
import SelectCompany from "@/views/Authentication/SelectCompany";
import Onboarding from "@/views/Authentication/Onboarding";
// END: Authentication Views

// START: Dashboard Views
import Dashboard from "@/views/Dashboard";

import Inbox from "@/views/Inbox/Index";

import AiAgent from "@/views/AiAgent/Index";
import Persona from "@/views/AiAgent/Persona";
import AnswerRules from "@/views/AiAgent/AnswerRules";
import Workflows from "@/views/AiAgent/Workflows";

import KnowledgeBase from "@/views/KnowledgeBase/Index";
import ViewArticles from "@/views/KnowledgeBase/Articles/ViewArticles";
import AddArticle from "@/views/KnowledgeBase/Articles/AddArticle";
import Sources from "@/views/KnowledgeBase/Sources/Sources";
import Documents from "@/views/KnowledgeBase/Sources/Documents";

import Automation from "@/views/Automation/Index";
import QuestionCategories from "@/views/Automation/QuestionCategories/Categories";
import QuestionCategoryIntents from "@/views/Automation/QuestionCategories/Intents";
import CustomReplies from "@/views/Automation/CustomReplies/Index";
import CustomReply from "@/views/Automation/CustomReplies/CustomReply";

import Widget from "@/views/Widget/Index";
import WidgetAppearance from "@/views/Widget/Appearance";
import WidgetHandoff from "@/views/Widget/Handoff";
import SelfServePortal from "@/views/Widget/SelfServePortal";
import WidgetActiveHours from "@/views/Widget/ActiveHours";
import WidgetActivation from "@/views/Widget/Activation";

import Analytics from "@/views/Analytics/Index";
import Insights from "@/views/Analytics/Insights";
import SourcesInsights from "@/views/Analytics/SourcesInsights";
import TokenUsage from "@/views/Analytics/TokenUsage";

import Settings from "@/views/Settings/Index";
import Integrations from "@/views/Settings/Integrations";
import UserAccess from "@/views/Settings/UserAccess";

import ZendeskSuccess from "@/views/Misc/Zendesk/Success";
import ZendeskError from "@/views/Misc/Zendesk/Error";

// END: Dashboard Views

// START: Admin Views
import AddCompany from "@/views/Admin/AddCompany";
import DeleteCompany from "@/views/Admin/DeleteCompany";
// END: Admin Views

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/sign-in",
      meta: { authRoute: true },
    },
    {
      path: "/sign-in",
      name: "Login",
      component: Login,
      meta: { authRoute: true },
    },{
      path: "/logout",
      name: "Logout",
      component: Logout,
      meta: { authRoute: true },
    },
    {
      path: "/authenticate-login",
      name: "AuthenticateLogin",
      component: AuthenticateLogin,
      meta: { authRoute: true },
    },
    {
      path: "/select-company",
      name: "SelectCompany",
      component: SelectCompany,
      meta: { authRoute: true },
    },
    {
      path: "/sign-up",
      name: "Register",
      component: Register,
      meta: { authRoute: true },
    },

    {
      path: "/onboarding",
      name: "Onboarding",
      component: Onboarding,
      meta: { authRoute: true },
    },

    {
      path: "/",
      name: "Dashboard",
      component: Dashboard,
      children: [
        {
          path: "inbox",
          name: "Inbox",
          component: Inbox,
        },
        {
          path: "ai-agent",
          name: "AI Agent",
          component: AiAgent,
          redirect: "ai-agent/persona",
          children: [
            {
              path: "persona",
              name: "AI Personas",
              component: Persona,
            },
            {
              path: "answer-rules",
              name: "Answer Rules",
              component: AnswerRules,
            },
            {
              path: "workflows",
              name: "Agent Workflows",
              component: Workflows,
            },
          ],
        },
        {
          path: "knowledge-base",
          name: "Knowledge Base",
          component: KnowledgeBase,
          redirect: "knowledge-base/sources",
          children: [
            {
              path: "articles",
              name: "View Articles",
              component: ViewArticles,
            },
            {
              path: "articles/add/:referenceID?",
              name: "Add Article",
              component: AddArticle,
            },
            {
              path: "sources",
              name: "Sources",
              component: Sources,
            },
            {
              path: "sources/:sourceID",
              name: "Source Documents",
              component: Documents,
            },
          ],
        },
        {
          path: "automation",
          name: "Automation",
          component: Automation,
          redirect: "automation/question-categories",
          children: [
            {
              path: "question-categories",
              name: "Question Categories",
              component: QuestionCategories,
            },
            {
              path: "question-categories/:categoryID",
              name: "Question Category Intents",
              component: QuestionCategoryIntents,
            },
            {
              path: "custom-replies",
              name: "Custom Replies",
              component: CustomReplies,
            },
            {
              path: "custom-replies/:customReplyID?",
              name: "Custom Reply",
              component: CustomReply,
            },
          ],
        },
        {
          path: "chat-widget",
          name: "Widget",
          component: Widget,
          redirect: "chat-widget/appearance",
          children: [
            {
              path: "appearance",
              name: "Appearance",
              component: WidgetAppearance,
            },
            {
              path: "handoff",
              name: "Handoff",
              component: WidgetHandoff,
            },
            {
              path: "self-serve-portal",
              name: "SelfServePortal",
              component: SelfServePortal,
            },
            {
              path: "active-hours",
              name: "WidgetActiveHours",
              component: WidgetActiveHours,
            },
            {
              path: "activation",
              name: "WidgetActivation",
              component: WidgetActivation,
            },
          ],
        },
        {
          path: "analytics",
          name: "Analytics",
          component: Analytics,
          redirect: "analytics/insights",
          children: [
            {
              path: "insights",
              name: "Insights",
              component: Insights,
            },
            {
              path: "sources",
              name: "SourcesInsights",
              component: SourcesInsights,
            },
            {
              path: "token-usage",
              name: "Token Usage",
              component: TokenUsage,
            },
          ],
        },
        {
          path: "settings",
          name: "Settings",
          component: Settings,
          redirect: "settings/user-access",
          children: [
            {
              path: "integrations",
              name: "Integrations",
              component: Integrations,
            },
            {
              path: "user-access",
              name: "User Access",
              component: UserAccess,
            },
          ],
        },
      ],
    },

    {
      path: "",
      name: "Dashboard",
      component: Dashboard,
      children: [
        {
          path: "settings",
          name: "Settings - Dashboard",
          component: Dashboard,
          children: [
            {
              path: "zendesk/oauth/success",
              name: "ZendeskSuccess",
              component: ZendeskSuccess,
              meta: {
                hideHeader: true,
              },
            },
            {
              path: "zendesk/oauth/error",
              name: "Error",
              component: ZendeskError,
              meta: {
                hideHeader: true,
              },
            },
          ],
        },
      ],
    },

    {
      path: "/admin/add-company",
      name: "AddCompany",
      component: AddCompany,
    },
    {
      path: "/admin/delete-company",
      name: "DeleteCompany",
      component: DeleteCompany,
    },

    {
      path: "*",
      beforeEnter() {
        location.href = "https://www.getmacha.com";
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
  },
});
