<template>
  <div v-if="chat.analysis" class="chat-summary-wrapper">
    <!--START: Customer-->
    <div v-if="customer" class="customer-details">
      <h3 class="name">{{ getCustomerName().name }}</h3>
      <span v-if="getCustomerName().email" class="email">{{
        getCustomerName().email
      }}</span>
      <span v-if="customer.number" class="phone">{{
        `+${customer.number.countryCallingCode} ${customer.number.number}`
      }}</span>
      <span class="location">{{ customer.location }}</span>
      <a
        v-if="isShopifyCustomer"
        :href="shopifyURL"
        target="_blank"
        class="shopify-customer-link"
      >
        <unicon name="external-link-alt"></unicon>
        <span>View customer on Shopify</span>
      </a>
    </div>
    <!--END: Customer-->

    <div class="summary-card">
      <!--START: AI Analysis-->
      <div v-if="chat.analysis.summary" class="analysis">
        <div class="summary-title">
          <svg
            :data-src="require('@/assets/images/icons/ai-sparkle.svg')"
            height="12px"
            width="12px"
            class="ai-icon"
          ></svg>
          <h3>Chat Summary</h3>
        </div>
        <p class="summary">{{ getSummary(chat.analysis.summary) }}</p>
      </div>
      <!--END: AI Analysis-->

      <!--START: Resolution-->
      <div class="resolution-details">
        <span v-if="chat.resolved" class="resolved"
          >Chat resolved by AI in {{ resolutionTime }}</span
        >
        <span v-else-if="chat.status == 'ticket'" class="ticket"
          >Ticket raised at {{ ticketRaisedTime }}</span
        >
        <span v-else class="open"
          >Chat started at
          {{ moment(chat.createdAt).local().format("MMM D, h:mm A") }}</span
        >
      </div>
      <!--END: Resolution-->
    </div>
  </div>
</template>

<script>
// Imoprt libraries
import _ from "lodash";

// Importing Services
import { ChatService } from "@/services";
import "external-svg-loader";

export default {
  name: "ChatActions",
  data() {
    return {
      lodash: _,
      isShopifyCustomer: false,
      shopifyURL: null,
    };
  },
  props: { chat: { type: Object, required: true } },
  watch: {},
  components: {},
  computed: {
    company() {
      return this.$store.getters.company;
    },

    // Customer details
    customer() {
      let customer = {};
      if (this.chat.customer) customer = this.chat.customer;
      return customer;
    },

    // Check if Shopify is integrated
    hasShopifyCredentials() {
      if (this.company?.sources?.shopify?.credentials) return true;
      return false;
    },

    // Calculate the resolution time
    resolutionTime() {
      const startTime = this.moment(this.chat.createdAt);
      const lastMessage = this.chat.messages[this.chat.messages.length - 1];
      const lastMessageTime = this.moment(lastMessage.timestamp);

      var duration = this.moment.duration(lastMessageTime.diff(startTime));
      return `${Math.ceil(duration.asMinutes())} minutes`;
    },

    // Calculate the time the ticket was raised
    ticketRaisedTime() {
      const lastMessage = this.chat.messages[this.chat.messages.length - 1];
      const lastMessageTime = this.moment(lastMessage.timestamp);
      return this.moment(lastMessageTime).local().format("MMM D, h:mm A");
    },
  },
  async created() {
    this.getShopifyCustomer();
  },

  methods: {
    // Get the customer name or email
    getCustomerName() {
      let name = "Customer";
      let email = null;
      if (this.customer && this.customer.name) {
        name = this.customer.name;
        email = this.customer.email;
      } else if (this.customer && this.customer.email)
        name = this.customer.email;

      return { name, email };
    },

    // Shorten the summary
    getSummary(summary) {
      if (summary?.length > 10) {
        summary = summary.substring(0, 500);
        summary += "...";
      }

      return summary;
    },

    // Get the Shopify customer
    async getShopifyCustomer() {
      if (this.hasShopifyCredentials) {
        const data = { customer: this.customer };
        const response = await ChatService.GetShopifyCustomer(data);

        if (!response.hasError && !_.isEmpty(response.data)) {
          const customer = response.data;
          const { shopName } = this.company.sources?.shopify?.credentials;
          this.isShopifyCustomer = true;
          this.shopifyURL = `https://admin.shopify.com/store/${shopName}/customers/${customer.id}`;
        } else {
          this.isShopifyCustomer = false;
          this.shopifyURL = null;
        }
      }
    },
  },
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.chat-summary-wrapper {
  width: 20rem;
  height: 100%;
  background-color: $whiteColor;
  border-left: 1px solid $greyBorderColor;

  .summary-card {
    margin: 2rem;

    .summary-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;

      .ai-icon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        fill: $purpleColor;
        width: 12px;
        height: auto;
        margin-right: 0.5rem;
      }

      h3 {
        font-weight: $mediumFontWeight;
        font-size: $smallFontSize;
        margin: 0;
      }
    }

    .summary {
      font-size: $smallerFontSize;
    }
  }
}

.resolution-details {
  display: block;
  border: 1px solid darken($lightWhiteColor, 5%);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  width: fit-content;
  background-color: $lightWhiteColor;
  margin-top: 1rem;

  span {
    font-size: $smallestFontSize;

    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 0.75rem;
      margin-right: 0.5rem;
      border-radius: 0.5rem;
      width: 0.25rem;
      background-color: $yellowColor;
      margin-top: -2px;
    }

    &.resolved::before {
      background-color: $emeraldColor;
    }

    &.ticket::before {
      background-color: $redColor;
    }
  }
}

.customer-details {
  margin: 2rem;

  .name {
    font-weight: $mediumFontWeight;
    font-size: $normalFontSize;
    margin-bottom: 0;
  }

  .email,
  .phone {
    display: block;
    font-size: $smallerFontSize;
    color: $paragraphColor;
  }
}

.shopify-customer-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: $smallerFontSize;
  color: $blueColor;
  margin-top: .25rem;

  .unicon /deep/ {
    svg {
      fill: $blueColor;
      height: auto;
      width: $smallestFontSize;
      margin-right: 0.25rem;
    }
  }
}
</style>