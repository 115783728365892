<template>
  <div class="card-block card">
    <!--START: Order details-->
    <OrderDetails
      :order="order.order"
      :customer="order.customer"
    ></OrderDetails>
    <!--END: Order details-->

    <!--START: Fulfillment-->
    <div v-if="order.fulfillments.length">
      <FulfillmentDetails
        v-for="(fulfillment, index) in order.fulfillments"
        :key="fulfillment.id"
        :index="index"
        :fulfillment="fulfillment.fulfillment"
      >
      </FulfillmentDetails>
    </div>
    <!--END: Fulfillment-->

    <!--START: Order Items-->
    <div
      v-if="order.processingItems.length"
      class="order-items"
      :class="{ 'pending-items': order.fulfillments.length }"
    >
      <label v-if="order.fulfillments.length">{{
        languages[$store.getters.widgetLanguage].widget.chat.orderDetails
          .pendingShipmentLabel
      }}</label>
      <ShippingStatus :shipping="{ status: 'processing' }"></ShippingStatus>
      <div class="card">
        <OrderItem
          v-for="item in order.processingItems"
          :key="item.id"
          :item="item"
        >
        </OrderItem>
      </div>
    </div>
    <!--END: Order Items-->

    <!--START: Refund Items-->
    <div v-if="order.refunds.length" class="order-items">
      <label>{{
        languages[$store.getters.widgetLanguage].widget.chat.orderDetails
          .refundedProductsLabel
      }}</label>
      <ShippingStatus :shipping="{ status: 'refund' }"></ShippingStatus>
      <div class="card" v-for="refund in order.refunds" :key="refund.id">
        <OrderItem v-for="item in refund.products" :key="item.id" :item="item">
        </OrderItem>
      </div>
    </div>
    <!--END: Refund Items-->
  </div>
</template>
  
<script>
// Importing Services
import "external-svg-loader";

// Importing components
import OrderDetails from "./OrderDetails";
import OrderItem from "./OrderItem";
import ShippingStatus from "./ShippingStatus";
import FulfillmentDetails from "./FulfillmentDetails";

export default {
  name: "OrderMessage",
  data() {
    return {};
  },
  props: {
    order: { type: Object, required: true },
  },
  watch: {},
  components: { OrderDetails, OrderItem, ShippingStatus, FulfillmentDetails },
  computed: {},
  created() {},
  methods: {
    // Get the total cost of the items
    getItemTotalCost(item) {
      const quantity = item.quantity;
      const { amount, currency_code } = item.price_set.presentment_money;

      const totalAmount = `${this.getCurrency(currency_code)}${(
        quantity * parseInt(amount)
      ).toLocaleString()}`;

      return totalAmount;
    },

    // Get the item cost
    getItemCost(item) {
      const { amount, currency_code } = item.price_set.presentment_money;

      const itemCost = `${this.getCurrency(currency_code)}${parseInt(
        amount
      ).toLocaleString()}`;

      return itemCost;
    },
  },
};
</script>
      
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/chat.scss";

.card-block {
  width: 60%;
  float: right;
}

.card {
  padding: 1rem !important;
  border-radius: 0.5rem !important;
  margin: 0.5rem 0 1rem !important;
}

.item-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .item-info {
    flex: 1;
  }
  .title {
    color: $darkBlackColor;
    font-size: $smallFontSize;
    font-weight: $mediumFontWeight;
    margin-bottom: 0.25rem;
  }
  .variant-title {
    display: table;
    font-size: $smallestFontSize;
    font-weight: $mediumFontWeight;
    color: lighten($darkBlackColor, 15%);
    background-color: darken($whiteColor, 10%);
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
  }

  .total-cost {
    font-size: $smallFontSize;
    color: lighten($darkBlackColor, 15%);
  }
}

.item-quantity {
  display: block;
  font-size: $smallFontSize;
  color: lighten($darkBlackColor, 15%);
  margin-top: 0.5rem;
}

.order-items {
  margin-top: 1rem;

  label {
    display: block;
    font-size: $smallestFontSize;
    font-weight: $mediumFontWeight;
    color: lighten($darkBlackColor, 45%);
    text-transform: uppercase;
    letter-spacing: 0.015rem;
    margin: 1.25rem 0 0.5rem;
  }

  &.pending-items {
    margin-top: 2rem;
  }
  .card {
    border-radius: 0.5rem !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
  }
}
</style>