<template>
  <div class="card category-insights">
    <div class="category-title-wrapper">
      <h3 class="category-title">{{ title }}</h3>
    </div>
    <div v-if="modelsArray.length > 0" class="category-item-wrapper">
      <div v-for="model in modelsArray" :key="model.id" class="category-item">
        <span class="name">{{ model.model }}</span>
        <span class="count"
          >{{ model.tokensUsed.toLocaleString("en-US") }} tokens</span
        >
      </div>
    </div>

    <span v-else class="empty-catgories">No usage in this category</span>
  </div>
</template>
    
  <script>
// Imoprt libraries
import _ from "lodash";

export default {
  name: "ModelUsage",
  props: {
    usage: { type: Array, required: true },
    title: { type: String, required: true },
  },
  data() {
    return {};
  },
  components: {},
  computed: {
    // Get the total count
    totalCount() {
      let totalCount = 0;
      // Calculate the total count of messages
      for (const item in this.category) {
        totalCount += this.category[item];
      }

      return totalCount;
    },

    // Create categories according to thcategory object
    modelsArray() {
      return _.orderBy(this.usage, "tokensUsed", "desc");
    },
  },
  async created() {},
  methods: {},
};
</script>
      
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.category-insights.card {
  margin: 0 0 2rem;
  padding: 0.75rem 1.25rem;
  box-shadow: none;
  background-color: darken($whiteColor, 0.5%);
  border-color: $greyBorderColor2;
}

.category-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $greyBorderColor2;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  h3 {
    flex: 1;
    color: $paragraphColor;
    font-size: $smallestFontSize;
    font-weight: $mediumFontWeight;
    opacity: $lightOpacity;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
  }

  .total-count {
    color: $secondaryColor;
    font-size: $smallerFontSize;
    font-weight: $mediumFontWeight;
  }
}

.category-item {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    font-size: $smallFontSize;
  }
  .name {
    flex: 1;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .count {
    flex: 1;
    text-align: right;
    opacity: $lightOpacity;
    font-weight: $mediumFontWeight;
  }
  .percentage {
    flex: 1;
    text-align: right;
    opacity: $lightOpacity;
  }
}

.empty-catgories {
  display: block;
  text-align: center;
  color: $paragraphColor;
  opacity: $lightOpacity;
  font-weight: $mediumFontWeight;
  font-size: $smallerFontSize;

  .unicon /deep/ {
    svg {
      display: inline-block;
      vertical-align: middle;
      height: auto;
      opacity: 0.5;
      width: 0.85rem;
      margin-right: 0.5rem;
    }
  }
}
</style>