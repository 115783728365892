require("dotenv").config();
import axios from "axios";

//Importing Variable mixins
const apiURL = `${process.env.VUE_APP_API_URL}/ai-agent`;

class AiAgentService {
  // Get AI agent
  static GetAiAgent() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Save AI agent
  static SaveAiAgent(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/save`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Save answer rule
  static SaveAnswerRule(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/answer-rule/save`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Delete answer rule
  static DeleteAnswerRule(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/answer-rule/delete`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default AiAgentService;
