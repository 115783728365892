<template>
  <div>
    <div class="custom-reply-wrapper">
      <div v-for="reply in customReplies" :key="reply._id" class="custom-reply">
        <i class="fa fa-fw custom-icon" :class="`fa-${reply.icon}`"></i>
        <div class="title-wrapper">
          <span class="title">{{ reply.title }}</span>
          <p>{{ reply.description }}</p>
        </div>
        <div class="actions">
          <unicon name="pen" @click="showReply(reply)"></unicon>
          <unicon
            name="trash-alt"
            @click="confirmQuestionDelete(reply)"
          ></unicon>
        </div>
      </div>
    </div>
    <button
      class="btn btn-text btn-small btn-no-padding"
      @click="addCustomReply"
    >
      <unicon name="plus"></unicon>
      <span>Add custom reply</span>
    </button>

    <!--START: Add custom reply-->
    <ViewCustomReply
      v-if="showOptions.addCustomReply"
      :reply="selectedReply"
      @replyUpdated="replyUpdated"
      @closeModal="closeModal"
    ></ViewCustomReply>
    <!--END: Add custom reply-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->

    <!--START: Loader-->
    <LineLoader
      :show="showOptions.lineLoader"
      class="line-loader-wrapper"
    ></LineLoader>
    <!--END: Loader-->
  </div>
</template>
    
<script>
// Importing components
import ViewCustomReply from "./ViewCustomReply";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import LineLoader from "@/components/loaders/LineLoader";

// Importing Services
import { WidgetSettingsService } from "@/services";

export default {
  name: "CustomReplies",
  data() {
    return {
      initComplete: false,
      showOptions: {
        addCustomReply: false,
        lineLoader: false,
      },
      selectedReply: {},
      replies: [],
      deleteID: null,
      confirmationFields: {
        show: false,
        title: "",
        type: "delete",
        modalType: "fixed",
        message: "This action cannot be undone. This reply will be removed.",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  props: { customReplies: Array },
  watch: {
    customReplies: {
      immediate: true,
      deep: true,
      handler() {
        this.initCustomReplies();
      },
    },
  },
  components: {
    ViewCustomReply,
    ConfirmationModal,
    LineLoader,
  },
  computed: {},
  mounted() {
    this.addFontAwesome();
  },
  async created() {},
  methods: {
    // Add font awesome styles from CDN
    addFontAwesome() {
      let fontAwesomeLink = document.createElement("link");
      fontAwesomeLink.setAttribute("rel", "stylesheet");
      fontAwesomeLink.setAttribute(
        "href",
        "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      );
      document.head.appendChild(fontAwesomeLink);
    },

    // Initialise the questions
    initCustomReplies() {
      this.replies.splice(0);
      this.customReplies.forEach((r) => {
        this.replies.push(r);
      });

      if (!this.initComplete) {
        this.initComplete = true;
        this.$emit("initCompleted");
      }
    },

    // Update the reply in the array
    replyUpdated(reply) {
      this.$emit("replyUpdated", reply);
    },

    // Confirm deleting custom reply
    confirmQuestionDelete(reply) {
      this.showDeleteModal(reply._id, reply.title);
    },

    // Delete the custom reply
    async deleteReply(replyID) {
      this.showOptions.lineLoader = true;
      await WidgetSettingsService.DeleteCustomReply({ replyID });
      this.showOptions.lineLoader = false;

      this.$emit("deleteReply", replyID);
    },

    // Show the delete modal
    showDeleteModal(itemID, itemTitle) {
      this.deleteID = itemID;
      this.confirmationFields.confirmValue = itemTitle;
      this.confirmationFields.show = true;
      this.confirmationFields.title = `Delete "${itemTitle}"?`;
    },

    // Delete the item
    async deleteItem() {
      this.deleteReply(this.deleteID);
      this.$emit("deleteCustomReply", this.deleteID);
      this.closeModal();
    },

    // Reply has been selected
    showReply(reply) {
      this.selectedReply = reply;
      this.addCustomReply();
    },

    // Show the add custom reply modal
    addCustomReply() {
      this.showOptions.addCustomReply = true;
    },

    // Close all modals
    closeModal() {
      this.selectedReply = {};
      this.confirmationFields.show = false;
      this.showOptions.addCustomReply = false;
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.btn {
  float: right;
  margin-top: 1.5rem;
}

.custom-reply {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: $smallFontSize;
  border: 1px solid $greyBorderColor;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;

  .custom-icon {
    width: 1.05rem;
    font-size: 0.75rem;
    height: auto;
    font-weight: bold;
    color: lighten($darkBlackColor, 45%);
    background: darken($whiteColor, 7.5%);
    padding: 0.65rem calc(0.5rem + 0.125rem);
    border-radius: 0.5rem;
    margin-right: 1rem;
  }

  .title {
    flex: 1;
    font-size: $smallFontSize;
  }

  p {
    font-size: $smallestFontSize;
  }

  .title-wrapper {
    flex: 1;
  }

  .actions {
    .unicon /deep/ {
      svg {
        fill: $greyColor;
        opacity: $lightOpacity;
        height: auto;
        width: 1rem;
        padding: 0 0.5rem;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.line-loader-wrapper {
  position: fixed;
  bottom: 0;
  z-index: 100;

  /deep/ .line-loader {
    top: auto;
  }
}
</style>