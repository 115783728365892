// Importing moment.js for time based functions
import moment from "moment-timezone";

// Get the last message time
const getLastMessageTime = (timestamp) => {
  let time = "";
  timestamp = moment(timestamp).local();

  const TODAY = moment().startOf("day");
  const YESTERDAY = moment()
    .subtract(1, "days")
    .startOf("day");
  const isToday = TODAY.isSame(timestamp, "day");
  const isYesterday = YESTERDAY.isSame(timestamp, "day");

  if (isToday) time = timestamp.format("hh:mm A");
  else if (isYesterday) time = "Yesterday";
  else time = timestamp.format("D MMMM");

  return time;
};

// Scroll chat window to view
const showLastMessage = (ele) => {
  if (ele)
    setTimeout(
      function() {
        ele.scrollTop = ele.scrollHeight;
      }.bind(this),
      0
    );
};

// Construct the selected chat with split messages
let messageDates = [];
const constructChat = (chat) => {
  let chatArray = [];
  chatArray.splice(0);

  // Iterate through every message and split the relevant ones
  chat.forEach((message) => {
    if (message.messageType == "answer") {
      let answerMessages = splitMessages(message.message);

      answerMessages.forEach((a) => {
        const answer = {
          message: replaceURLs(a),
          messageType: message.messageType,
          messageData: message.messageData,
          senderRole: message.senderRole,
          timestamp: message.timestamp,
          eventType: message.eventType,
        };

        chatArray.push(answer);
      });
    } else {
      chatArray.push(message);
    }
  });

  return chatArray;
};

// Extract the user chat messages only
const extractChat = (c) => {
  const chat = [];
  c.forEach((message) => {
    if (message.messageType == "question" || message.messageType == "answer")
      chat.push(message);
  });

  return chat;
};

// Splitting the messages into smaller chunks
const splitMessages = (message, messages = []) => {
  if (message?.length > 200 && message?.indexOf(". ", 200) !== -1) {
    const breakPoint = message.indexOf(". ", 200);

    messages.push(replaceURLs(message.slice(0, breakPoint + 1)));
    splitMessages(message.slice(breakPoint + 2), messages);
  } else messages.push(replaceURLs(message));
  return messages;
};

// Get the date of the message
const createMessageDates = (chat) => {
  let dates = [];
  messageDates.splice(0);

  chat.messages.forEach((message, index) => {
    let date = moment(message.timestamp).format("Do MMMM");

    if (!dates.includes(date)) {
      dates.push(date);
      messageDates.push(index);
    }
  });

  return messageDates;
};

// Get the message time
const getMessageTime = (timestamp) => {
  let time = moment(timestamp)
    .local()
    .format("h:mm A");
  return time;
};

// Replace string with hrefs in the messsage
const replaceURLs = (message) => {
  if (!message) return;

  const regex = /\[(.*?)\]\((.*?)\)/g;
  return message.replace(regex, '<a href="$2" target="_blank">$1</a>');
};

// Function to get the sync date
const getUpdatedDate = (time) => {
  const syncDate = moment(time);
  var NOW = moment().startOf("day");
  var YESTERDAY = NOW.clone()
    .subtract(1, "days")
    .startOf("day");

  if (syncDate.isSame(NOW, "d")) return syncDate.format("hh:mm A");
  else if (syncDate.isSame(YESTERDAY, "d")) return "yesterday";
  else return syncDate.format("Do MMM");
};

export {
  getLastMessageTime,
  showLastMessage,
  constructChat,
  extractChat,
  createMessageDates,
  getMessageTime,
  replaceURLs,
  splitMessages,
  getUpdatedDate,
};
