<template>
  <div class="data-table">
    <!--START: Documents List-->
    <ul class="grid-table">
      <li
        class="grid-row grid-header"
        :class="{ 'grid-header-shadow': showOptions.headerShadow }"
      >
        <span class="grid-column">Name</span>
        <span class="grid-column">Description</span>
        <span class="grid-column">Status</span>
      </li>
    </ul>
    <ul
      class="grid-table grid-item-clickable"
      ref="table"
      @scroll="tableScroll"
    >
      <li
        v-for="(r, index) in rules"
        :key="index"
        class="grid-row"
        @click="gotoItem(r)"
      >
        <div class="grid-column">
          <h3 class="name">{{ r.title }}</h3>
        </div>

        <div class="grid-column">
          <p class="description">{{ r.description }}</p>
        </div>

        <div class="grid-column">
          <span class="status" :class="{ inactive: !r.isActive }">{{
            r.isActive ? "Active" : "Inactive"
          }}</span>
        </div>

        <div class="grid-column grid-actions">
          <button
            class="btn btn-icon btn-delete"
            @click.stop="showDeleteModal(r._id, r.title)"
          >
            <unicon name="trash-alt"></unicon>
          </button>
        </div>
      </li>
    </ul>
    <!--END: Documents List-->

    <!--START: Documents Count-->
    <span class="grid-count"
      >Showing {{ rules.length }}
      {{ rules.length == 1 ? "rule" : "rules" }}</span
    >
    <!--END: Documents Count-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->
  </div>
</template>
              
<script>
// Importing Components
import ConfirmationModal from "@/components/modals/ConfirmationModal";

export default {
  name: "AiAnswerRulesTable",
  props: {
    rules: Array,
  },
  data() {
    return {
      showOptions: {
        headerShadow: false,
      },
      deleteID: null,
      confirmationFields: {
        show: false,
        title: "",
        type: "delete",
        modalType: "fixed",
        message: "This action cannot be undone. This rule will be removed.",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  computed: {},
  components: {
    ConfirmationModal,
  },
  methods: {
    // Event on table scroll
    tableScroll() {
      const scrollTop = this.$refs.table.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Goto view a particular rule
    gotoItem(rule) {
      this.$emit("ruleSelected", rule);
    },

    // Show the delete modal
    showDeleteModal(itemID, itemTitle) {
      this.deleteID = itemID;
      this.confirmationFields.confirmValue = itemTitle;
      this.confirmationFields.show = true;
      this.confirmationFields.title = `Delete "${itemTitle}"?`;
    },

    // Delete the item
    async deleteItem() {
      this.$emit("deleteRule", this.deleteID);
      this.closeModal();
    },

    // Close all the modals
    closeModal() {
      this.confirmationFields.show = false;
    },
  },
};
</script>
              
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.data-table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  margin-bottom: 0.5rem;
}

.grid-table.grid-item-clickable {
  flex: 1;
  overflow-y: scroll;
}

.grid-column {
  &:nth-child(1) {
    width: calc(35% - 7rem);
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:nth-child(2) {
    width: calc(50% - 5rem);
    margin-right: 5rem;
  }

  &:nth-child(3) {
    width: 7rem;
    text-align: center;
  }
}

.grid-actions {
  width: 25rem;

  .btn {
    margin-left: 1.5rem;
  }
}

.name,
.description {
  color: $greyColor;
  font-size: $smallerFontSize;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
}

.name {
  font-size: $smallFontSize;
  color: $blackColor;
  font-weight: $mediumFontWeight;
}

.status {
  position: relative;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  color: $greenMessageTextColor;
  background-color: lighten($greenMessageColor, 0%);
  border: 1px solid $greenMessageBorderColor;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  text-transform: capitalize;

  &.inactive {
    color: $redMessageTextColor;
    background-color: lighten($redMessageColor, 5%);
    border: 1px solid $redMessageBorderColor;
  }
}
</style>