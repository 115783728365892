<template>
  <div>
    <div class="form-wrapper">
      <!--START: Field Wrapper-->
      <h1>Delete Company</h1>
      <div class="scraper-wrapper card">
        <FormBuilder :fields="fields"></FormBuilder>
        <button class="btn btn-primary" @click="deleteCompany">
          Delete Company
        </button>
      </div>
      <!--END: Field Wrapper-->
    </div>

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
  
  <script>
// Importing Services
import { AdminService } from "@/services";

// Importing components
import FormBuilder from "@/components/form/FormBuilder";
import LineLoader from "@/components/loaders/LineLoader";

export default {
  name: "DeleteCompany",
  data() {
    return {
      showOptions: {
        lineLoader: false,
      },
      fields: {
        companyID: {
          type: "text",
          placeholder: "Add the ID of the company",
          required: true,
          hasError: false,
          title: "Company ID*",
          value: "",
        },
      },
    };
  },
  props: {},
  watch: {},
  components: {
    FormBuilder,
    LineLoader,
  },
  computed: {},
  async created() {},
  methods: {
    async deleteCompany() {
      // Check if the fields are valid
      const data = { ...this.fields };
      var isFormValid = this.validateForm(this.fields);

      if (isFormValid) {
        this.showOptions.lineLoader = true;

        const payload = this.parseData(data);
        const response = await AdminService.DeleteCompany(payload);
        console.log(response.data);

        this.showOptions.lineLoader = false;
      }
    },
  },
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.form-wrapper {
  overflow: hidden;
  margin: 4rem auto 0;
  max-width: 34rem;
}
.scraper-title {
  font-size: $mediumFontSize;
  margin-bottom: 1.5rem;
}

/deep/ .line-loader {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>