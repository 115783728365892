<template>
  <div class="page-main flex-page">
    <!--START: Breadcrumb-->
    <div class="breadcrumb">
      <router-link to="/knowledge-base/sources">Sources</router-link>
      <unicon name="angle-right"></unicon>
      <span>{{ source.name }}</span>
    </div>
    <!--END: Breadcrumb-->

    <!--START: Header Wrapper-->
    <div class="header-wrapper">
      <div class="left-column">
        <!--START: Page Header-->
        <RegularHeader
          class="regular-header"
          :data="regularHeader"
        ></RegularHeader>
        <!--END: Page Header-->

        <!--START: Table Actions-->
        <div class="table-actions-wrapper">
          <div class="search-wrapper" @keyup.enter="searchDocuments">
            <unicon name="search"></unicon>
            <FormBuilder :fields="searchFields"></FormBuilder>
          </div>
          <div class="document-count">
            {{ totalCount ? `${totalCount} documents` : null }}
          </div>
        </div>
        <!--END: Table Actions-->
      </div>

      <div class="right-column">
        <span class="source-type">
          <img :src="getLogoImage(source.sourceType)" alt="Logo" />
          {{ getSourceType(source.sourceType) }}</span
        >
        <a :href="source.url" target="_blank" class="preview-url">
          <unicon name="link"></unicon>
          <span>{{ getSourceUrl(source) }}</span>
        </a>
        <div class="actions-block">
          <FormBuilder
            :fields="frequencyFields"
            class="frequency-form"
            @fieldChanged="UpdateSource"
          ></FormBuilder>
          <FormBuilder
            :fields="activeFields"
            @fieldChanged="UpdateSource"
          ></FormBuilder>
          <button
            class="btn btn-icon btn-delete btn-small"
            @click="showDeleteModal()"
          >
            <unicon name="trash-alt"></unicon>
            <span>Delete Source</span>
          </button>
        </div>
      </div>
    </div>
    <!--END: Header Wrapper-->

    <!--START: All Documents Table-->
    <DocumentsTable
      :documents="documents"
      :totalCount="totalCount"
      :showInfinteSroll="gettingDocuments"
      @searchDocuments="searchDocuments"
      @getDocuments="getDocuments"
      @documentSelected="showDocumentModal"
      @deleteDocument="deleteDocument"
    ></DocumentsTable>
    <!--END: All Documents Table-->

    <!--START: Zero State -->
    <ZeroState
      v-if="showOptions.zeroState"
      class="zero-state"
      :imageURL="zeroStateData.imageURL"
      :title="zeroStateData.title"
      :message="zeroStateData.message"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Loader-->
    <PageLoader v-if="!showOptions.documents" :showLoader="true"></PageLoader>
    <!--END: Loader-->

    <!--START: View Document-->
    <ViewDocument
      v-if="showOptions.viewDocument"
      :selectedDocument="selectedDocument"
      :show="showOptions.viewDocument"
      :documentUpdated="documentUpdated"
      @updateDocument="updateDocument"
      @changeStatus="changeDocumentStatus"
      @closeModal="closeModal"
    ></ViewDocument>
    <!--END: View Document-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="deleteSource"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
      
<script>
// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import ZeroState from "@/components/misc/ZeroState";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";
import FormBuilder from "@/components/form/FormBuilder";
import DocumentsTable from "@/components/dashboard/tables/DocumentsTable";
import ViewDocument from "@/components/dashboard/knowledgeBase/ViewDocument";
import ConfirmationModal from "@/components/modals/ConfirmationModal";

// Importing Services
import { DocumentService } from "@/services";

export default {
  name: "ViewDocuments",
  data() {
    return {
      showOptions: {
        addLocation: false,
        lineLoader: false,
        documents: false,
        zeroState: false,
        viewDocument: false,
      },
      regularHeader: {
        title: "Source Documents",
        description: "The documents that belong to this knowledge base source",
      },
      activeFields: {
        isActive: {
          type: "toggle",
          title: "Status",
          additionalClass: "large-toggle",
          value: false,
        },
      },
      frequencyFields: {
        updateFrequency: {
          type: "dropdown",
          placeholder: "Select frequency",
          title: "Update frequency",
          required: true,
          hasError: false,
          fields: [
            { name: "None", value: "none" },
            { name: "Weekly", value: "weekly" },
            { name: "Monthly", value: "monthly" },
          ],
          value: [],
        },
      },
      searchFields: {
        search: {
          type: "text",
          placeholder: "Search this source for documents",
          required: false,
          hasError: false,
          additionalClass: "small-input",
          value: "",
        },
      },
      confirmationFields: {
        show: false,
        title: "",
        type: "delete",
        modalType: "fixed",
        message:
          "This action cannot be undone. All documents for this source will be deleted.",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
      zeroStateData: {
        imageURL: "services.jpg",
        title: "No documents found",
        message: "This source doesn't seem to have any documents",
      },
      status: {
        show: false,
        status: "success",
        title: "Document deleted",
      },
      sourceID: null,
      selectedDocument: [],
      documents: [],
      documentUpdated: false,
      totalCount: 0,
      documentType: null,
      searchTerm: "null",
      gettingDocuments: false,
      documentLimitReached: false,
      pageNumber: 0,
      pageSize: 20,
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    ZeroState,
    NotificationMessage,
    LineLoader,
    PageLoader,
    FormBuilder,
    DocumentsTable,
    ViewDocument,
    ConfirmationModal,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },

    source() {
      const { sources } = this.$store.getters.company;
      return sources.find((s) => s._id === this.sourceID);
    },
  },
  async created() {
    this.initDocuments();
    await this.getDocuments();
  },

  methods: {
    // Update meta data
    initDocuments() {
      this.sourceID = this.$route.params.sourceID;
      this.regularHeader.title = this.source.name;
      this.activeFields.isActive.value = this.source.isActive;
      this.frequencyFields.updateFrequency.value = [
        this.capitalizeFirstLetter(this.source.updateFrequency),
        this.source.updateFrequency,
      ];
    },

    // Get source name
    getSourceType(sourceType) {
      let source = null;
      switch (sourceType) {
        case "shopify-products":
          source = "Shopify Products";
          break;
        case "zendesk-hc":
        case "zendesk-hc-api":
          source = "Zendesk Helpcenter";
          break;
      }

      return source;
    },

    // Get logo of source type
    getLogoImage(sourceType) {
      let icon = null;
      switch (sourceType) {
        case "shopify-products":
          icon = "shopify.jpg";
          break;
        case "zendesk-hc":
        case "zendesk-hc-api":
          icon = "zendesk.jpg";
          break;
      }

      return (this.imageSrc = `/assets/images/logos/${icon}`);
    },

    // Get source URL
    getSourceUrl(source) {
      const zendeskSources = ["zendesk-macro", "zendesk-hc-api"];
      if (zendeskSources.includes(source.sourceType)) {
        return this.company.integrations?.zendesk?.credentials?.domainName;
      } else return source.url;
    },

    // Functionality to search documents
    searchDocuments() {
      this.clearDocuments();

      const searchTerm = this.searchFields.search.value;
      this.searchTerm = searchTerm;

      this.getDocuments();
    },

    // Function to get knowledge base
    async getDocuments() {
      if (!this.documentLimitReached && !this.gettingDocuments) {
        this.gettingDocuments = true;
        this.showOptions.zeroState = false;

        this.pageNumber = this.pageNumber + 1;
        const data = {
          sourceID: this.sourceID,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          searchTerm: this.searchTerm,
        };
        const response = await DocumentService.GetDocuments(data);

        // Check for errors
        if (!response.hasError) {
          const { documents, totalCount } = response.data;
          this.totalCount = totalCount;
          this.documents.push(...documents);

          if (this.documents.length == 0) this.showOptions.zeroState = true;
          if (documents.length == 0) this.documentLimitReached = true;

          this.gettingDocuments = false;
          this.showOptions.documents = true;
        } else this.showErrorMessage(this.status, response.message);
      }
    },

    // Get the selected document
    async getDocument(referenceID) {
      const data = {
        sourceID: this.sourceID,
        referenceID,
      };
      const response = await DocumentService.GetDocument(data);

      if (!response.hasError) this.selectedDocument = response.data;
      else this.showErrorMessage(this.status, response.message);
    },

    // Update source attributes
    async UpdateSource() {
      this.showOptions.lineLoader = true;

      const data = {
        sourceID: this.source._id,
        ...this.parseData(this.activeFields),
        ...this.parseData(this.frequencyFields),
      };
      await DocumentService.UpdateSource(data);
      await this.searchDocuments();

      this.showOptions.lineLoader = false;
    },

    // Show the delete modal
    showDeleteModal() {
      this.confirmationFields.confirmValue = this.source.name;
      this.confirmationFields.show = true;
      this.confirmationFields.title = `Delete ${this.source.name}?`;
    },

    // Delete source
    async deleteSource() {
      this.showOptions.lineLoader = true;

      const data = { sourceID: this.source._id };
      await DocumentService.DeleteSource(data);

      // Remove from the table
      const index = this.company.sources.findIndex(
        (s) => s._id == this.source._id
      );
      this.company.sources.splice(index, 1);
      this.$store.commit("updateCompany", this.company);
      this.showOptions.lineLoader = false;

      this.$router.push({ path: `/knowledge-base/sources` });
    },

    // Update the product and resync it
    async updateProduct(referenceID) {
      const data = { referenceID };
      this.selectedDocument.splice(0);

      await DocumentService.UpdateProduct(data);
      this.getDocument(referenceID);
    },

    // Update the document and resync it
    async updateDocument(document) {
      document.sourceID = this.sourceID;
      const data = { document };
      this.selectedDocument.splice(0);

      await DocumentService.UpdateDocument(data);
      this.getDocument(document.referenceID);
    },

    // Change the active status of a document
    async changeDocumentStatus(referenceID, isActive) {
      this.documentUpdated = false;
      const data = { sourceID: this.sourceID, referenceID, isActive };
      await DocumentService.ToggleDocumentStatus(data);

      var document = this.documents.find((d) => d.reference_id == referenceID);
      document.is_active = isActive;
    },

    // Delete document from Supabase
    async deleteDocument(data) {
      this.showOptions.lineLoader = true;

      data.sourceID = this.sourceID;
      await DocumentService.DeleteDocument(data);

      // Remove from the table
      const index = this.documents.findIndex(
        (d) => d.reference_id == data.referenceID
      );
      this.documents.splice(index, 1);

      this.showStatusMessage(this.status, 2500);
      this.showOptions.lineLoader = false;
    },

    // Clear the documents
    clearDocuments() {
      this.pageNumber = 0;
      this.documents.splice(0);
      this.documentLimitReached = false;
    },

    // Show the selected document details
    async showDocumentModal(referenceID) {
      this.getDocument(referenceID);
      this.showOptions.viewDocument = true;
    },

    // Close all the modals
    closeModal() {
      this.selectedDocument.splice(0);
      this.status.show = false;
      this.confirmationFields.show = false;
      this.showOptions.viewDocument = false;
    },
  },
};
</script>
      
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.header-wrapper {
  margin-bottom: 0.5rem !important;
}

.left-column {
  flex: 1;
}

.right-column {
  text-align: right;
}

.source-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-transform: capitalize;
  font-size: $smallerFontSize;
  margin-bottom: 0.5rem;
  color: $greyColor;

  img {
    width: 1.5rem;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
  }
}

.zero-state {
  margin-top: 2.5rem;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.actions-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5rem;

  /deep/ {
    .field-main-item {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
}

.table-actions-wrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 2rem;

  .document-count {
    flex: 1;
    font-weight: $mediumFontWeight;
    color: $paragraphColor;
    font-size: $smallFontSize;
    opacity: $lightOpacity;
  }

  .search-wrapper {
    position: relative;
    margin-right: 1.5rem;

    .unicon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1ch;
      z-index: 2;

      /deep/ svg {
        fill: $greyColor;
        opacity: $lightOpacity;
        width: 1rem;
      }
    }

    /deep/ {
      .field-main-item {
        width: 18rem;
      }
      input {
        padding-left: 2rem;
      }
    }
  }

  /deep/ {
    .field-main-item {
      margin-bottom: 0 !important;

      .field-radio-wrapper {
        margin-bottom: 0;
      }
    }
  }
}

.frequency-form /deep/ {
  .field-main-item {
    display: flex;

    .field-title {
      font-size: $smallestFontSize;
      margin-left: 0;
      margin-bottom: 0;
      margin-right: 0.75rem;
    }

    .field-dropdown-wrapper {
      flex: 1;
    }

    .dropdown-info-wrapper {
      min-width: 5rem;
      padding: 0.25rem 0.5rem;
      text-align: left;

      .field-placeholder {
        font-size: $smallerFontSize;
      }
    }
  }
}
</style>