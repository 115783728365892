<template>
  <div class="field-scheduler-item">
    <div class="days-wrapper" :class="field.additionalClass">
      <!--START: Unique Times-->
      <div class="unique-times">
        <label v-if="!field.hideLabel" class="time-selector-label"
          >Set Available Hours</label
        >
        <div
          class="day-item"
          v-for="(day, dayIndex) in uniqueSlots"
          :key="dayIndex"
        >
          <div class="day-info">
            <label>{{ day.day }}</label>
            <ToggleButton
              class="specific-day-toggle"
              :field="day.isActive"
              toggleName="specific-day-toggle"
              @fieldChanged="dayToggled(dayIndex)"
              @input="fieldChanged"
            ></ToggleButton>
          </div>
          <div v-if="day.isActive.value" class="day-slots-wrapper">
            <div
              class="time-slot"
              v-for="(slot, index) in day.timeSlots"
              :key="slot.id"
            >
              <FormBuilder
                class="time-slot-form"
                :fields="slot"
                @fieldChanged="fieldChanged"
                @input="fieldChanged"
              ></FormBuilder>
              <div class="btn-slot-wrapper">
                <unicon
                  class="btn-slot"
                  name="plus"
                  @click="addTimeSlot(dayIndex, index)"
                ></unicon>
                <unicon
                  v-if="day.timeSlots.length > 1"
                  class="btn-slot"
                  name="minus"
                  @click="removeTimeSlot(dayIndex, index)"
                ></unicon>
              </div>
            </div>
          </div>
          <div v-else class="inactive-day">
            <span>Unavailable</span>
          </div>
        </div>
      </div>
      <!--END: Unique Times-->
    </div>
  </div>
</template>

<script>
//Import libraries
import _ from "lodash";

//Import components
import ToggleButton from "./ToggleButton";

export default {
  name: "SchedulerField",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      initOfferingPopulated: false,
      initComplete: false,
      uniqueSlots: [
        {
          day: "Monday",
          isActive: {
            type: "toggle",
            value: true,
            activeText: "Available",
          },
          timeSlots: [],
        },
        {
          day: "Tuesday",
          isActive: {
            type: "toggle",
            value: true,
            activeText: "Available",
          },
          timeSlots: [],
        },
        {
          day: "Wednesday",
          isActive: {
            type: "toggle",
            value: true,
            activeText: "Available",
          },
          timeSlots: [],
        },
        {
          day: "Thursday",
          isActive: {
            type: "toggle",
            value: true,
            activeText: "Available",
          },
          timeSlots: [],
        },
        {
          day: "Friday",
          isActive: {
            type: "toggle",
            value: true,
            activeText: "Available",
          },
          timeSlots: [],
        },
        {
          day: "Saturday",
          isActive: {
            type: "toggle",
            value: false,
            activeText: "Available",
          },
          timeSlots: [],
        },
        {
          day: "Sunday",
          isActive: {
            type: "toggle",
            value: false,
            activeText: "Available",
          },
          timeSlots: [],
        },
      ],
      timeSlotFields: {
        timeSlot: {
          type: "group",
          nestFields: false,
          fields: {
            startTime: {
              type: "time",
              placeholder: "Start time",
              required: true,
              hasError: false,
              value: "",
            },
            endTime: {
              type: "time",
              placeholder: "End time",
              required: true,
              hasError: false,
              value: "",
            },
          },
        },
      },
    };
  },
  watch: {
    uniqueSlots: {
      deep: true,
      handler: function () {
        if (this.initComplete) this.fieldChanged();
      },
    },
  },
  components: {
    FormBuilder: () => import("./FormBuilder"),
    ToggleButton,
  },
  created() {
    this.populateSlots();
  },
  computed: {},
  methods: {
    populateSlots() {
      //Extract existing offering data
      let daySlots = this.field.value;

      //START: Create the temporary time slot
      const timeSlot = this.createTimeSlot();
      this.timeSlotFields.timeSlot.fields.startTime.fields = timeSlot;
      this.timeSlotFields.timeSlot.fields.endTime.fields = timeSlot;

      let timeSlotData = this.timeSlotFields;
      //END: Create the temporary time slot

      let tempTimeSlot;

      for (let i = 0; i < this.uniqueSlots.length; i++) {
        this.uniqueSlots[i].timeSlots.splice(0);
        this.uniqueSlots[i].isActive.value = daySlots[i]?.isActive;

        //Populate the unique days time slots
        if (daySlots[i]?.timeSlots.length > 0)
          for (let j = 0; j < daySlots[i].timeSlots.length; j++) {
            tempTimeSlot = _.cloneDeep(timeSlotData);
            tempTimeSlot.timeSlot.fields.startTime.value =
              daySlots[i].timeSlots[j].startTime;
            tempTimeSlot.timeSlot.fields.endTime.value =
              daySlots[i].timeSlots[j].endTime;

            this.uniqueSlots[i].timeSlots.push(_.cloneDeep(tempTimeSlot));
          }
        else {
          this.uniqueSlots[i].timeSlots.push(_.cloneDeep(this.timeSlotFields));
        }
      }

      this.field.uniqueSlots = this.uniqueSlots;
    },

    createTimeSlot() {
      var hours, minutes, ampm;
      var time = [];
      for (var i = 0; i <= 1440; i += 15) {
        hours = Math.floor(i / 60);
        minutes = i % 60;
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        ampm = hours % 24 < 12 ? "AM" : "PM";
        hours = hours % 12;
        if (hours === 0) {
          hours = 12;
        }

        time.push({
          name: hours + ":" + minutes + " " + ampm,
          value: hours + ":" + minutes + " " + ampm,
        });
      }

      return time;
    },

    isEnabledDay(day) {
      let isEnabledDay = true;
      if (
        this.field.disabledDays &&
        this.field.disabledDays.some((res) => res.includes(day))
      ) {
        isEnabledDay = false;
      }

      return isEnabledDay;
    },

    dayToggled(index) {
      const isActive = this.uniqueSlots[index].isActive.value;
      this.uniqueSlots[index].timeSlots.forEach(function (day) {
        day.timeSlot.fields.startTime.required = isActive;
        day.timeSlot.fields.endTime.required = isActive;
      });
      this.fieldChanged();
    },

    addTimeSlot(dayIndex, index) {
      this.uniqueSlots[dayIndex].timeSlots.splice(
        index + 1,
        0,
        _.cloneDeep(this.timeSlotFields)
      );
      this.fieldChanged();
    },

    removeTimeSlot(dayIndex, index) {
      this.uniqueSlots[dayIndex].timeSlots.splice(index, 1);
      this.fieldChanged();
    },

    fieldChanged() {
      this.field.uniqueSlots = this.uniqueSlots;
      if (!this.initOfferingPopulated) this.initOfferingPopulated = true;
      this.$emit("fieldChanged");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.active-days-toggle.field-toggle-item {
  flex-direction: row !important;

  /deep/ {
    label {
      margin-left: 0.75rem !important;
    }
  }
}

.active-days {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;

  .active-day {
    margin-right: 0.5rem;
    padding: 0.5rem;

    &:hover,
    &.active {
      background-color: $blackColor;
      color: $whiteColor;
    }
  }
}

input[readonly] {
  cursor: default;
  border: 1px solid #e7e6ef;
}

.time-selector-label {
  display: block;
  font-size: $normalFontSize;
  font-weight: $mediumFontWeight;
  opacity: 0.75;
  margin: 2rem 0 1rem;
}

.unique-times {
  .time-selector-label {
    margin: 1rem 0 2rem;
  }
}

.time-slot {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;

  label {
    width: 4rem;
  }

  .btn {
    margin-left: 0.5rem;
  }

  /deep/ {
    .time-slot-form {
      margin: 0 1rem 0 0;

      .field-group-wrapper,
      .field-main-item {
        margin-bottom: 0;
      }
    }
  }
}

.day-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1rem;

  .day-info {
    min-width: 9rem;
    margin-right: 4rem;

    label {
      display: block;
      font-weight: $mediumFontWeight;
      font-size: $smallFontSize;
      margin-bottom: 0.75rem;
    }
  }

  .specific-day-toggle {
    flex-direction: row !important;
    margin: 0.5rem 0 0 !important;

    /deep/ {
      label {
        font-size: $smallFontSize !important;
        margin-left: 0.75rem;
      }
    }
  }
}

.btn-slot {
  /deep/ svg {
    fill: $titleColor;
    width: $largeFontSize;
    cursor: pointer;
    opacity: 0.45;

    &:hover {
      opacity: 1;
      fill: $secondaryColor;
    }
  }
  margin: 0 0.5rem;
}

.large-scheduler {
  .day-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 16rem;
    margin-top: 0.5rem;

    label {
      margin-bottom: 0;
      width: 10rem;
    }

    .specific-day-toggle {
      margin-top: 0 !important;
    }
  }

  .day-slots-wrapper {
    flex: 1;
  }

  .time-slot {
    justify-content: flex-end;

    &:first-child {
      margin-top: 0;
    }
  }

  .day-item {
    padding-bottom: 1rem;
    border-bottom: 1px solid $greyBorderColor;
  }
}

.inactive-day {
  flex: 1;
  text-align: center;

  span {
    display: block;
    font-size: $smallFontSize;
    color: $greyColor;
    margin: 0.5rem 0;
    opacity: 0.5;
  }
}

.btn-slot-wrapper {
  width: 4.5rem;
}

//Mobile Styles
@media screen and (max-width: $mobileWidth) {
  .active-days {
    .btn {
      font-size: 0;

      &:first-letter {
        font-size: 1rem;
      }
    }
  }

  .day-item {
    display: block;

    .day-info {
      margin-bottom: 1rem;
    }
  }

  .time-slot {
    display: block;

    .time-slot-form {
      width: auto;
      margin-bottom: -1rem;
    }

    .btn {
      display: inline-block;
      margin: 0 0.5rem 1.5rem 0;
    }
  }
}

.settings-form .field-main-item .field-toggle-item {
  display: flex;
}

.field-scheduler-item {
  flex: 1;
}
</style>
