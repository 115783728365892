<template>
  <div v-if="!lodash.isEmpty(widget)" class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton"
        @click="saveWidget"
      >
        Save Changes
      </button>
    </div>
    <!--END: Header Wrapper-->

    <div ref="settings" class="settings-main" @scroll="settingsScroll">
      <!--START: Form Blocks-->
      <div class="settings-form-wrapper">
        <SettingsForm
          v-for="field in fields"
          :key="field._id"
          :fields="field.fields"
          :content="field.content"
          @fieldChanged="fieldChanged"
        ></SettingsForm>
      </div>
      <!--END: Form Blocks-->

      <!--START: Custom Replies-->
      <div class="settings-form card">
        <div class="settings-form-wrapper">
          <div class="content-wrapper">
            <h3>{{ customRepliesFields.content.title }}</h3>
            <p>{{ customRepliesFields.content.description }}</p>
          </div>

          <CustomReplies
            :customReplies="customReplies"
            @initCompleted="initFormData"
            @replyUpdated="updateCustomReply"
            @deleteReply="deleteCustomReply"
            class="suggested-questions"
          ></CustomReplies>
        </div>
      </div>
      <!--END: Custom Replies-->

      <!--START: Suggested Questions-->
      <div class="settings-form card">
        <div class="settings-form-wrapper">
          <div class="content-wrapper">
            <h3>{{ suggestedQuestionsFields.content.title }}</h3>
            <p>{{ suggestedQuestionsFields.content.description }}</p>
          </div>

          <SuggestedQuestions
            :suggestedQuestions="suggestedQuestions"
            @initCompleted="initFormData"
            @questionUpdated="updateSuggestedQuestion"
            @itemsMoved="constructSuggestedQuestions"
            @deleteSuggestedQuestion="deleteSuggestedQuestion"
            class="suggested-questions"
          ></SuggestedQuestions>
        </div>
      </div>
      <!--END: Suggested Questions-->
    </div>

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->

    <!--START: Unsaved Changes Modal -->
    <UnsavedChangesModal
      :show="showOptions.unsavedChanges"
      @primaryEvent="continueRoute"
      @secondaryEvent="closeModal"
    ></UnsavedChangesModal>
    <!--END: Unsaved Changes Modal -->
  </div>
</template>
          
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import CustomReplies from "@/components/dashboard/widget/CustomReplies";
import SuggestedQuestions from "@/components/dashboard/widget/SuggestedQuestions";
import SettingsForm from "@/components/form/SettingsForm";
import LineLoader from "@/components/loaders/LineLoader";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal";

export default {
  name: "SelfServePortal",
  data() {
    return {
      lodash: _,
      dataPopulated: false,
      showOptions: {
        headerShadow: false,
        unsavedChanges: false,
        lineLoader: false,
        disableButton: true,
      },
      regularHeader: {
        title: "Self Serve Portal",
        description: "Configure the self service portal for your customers",
      },
      fields: {
        appearance: {
          content: {
            title: "Appearance",
            description:
              "Control the appearance of how the self serve portal will look",
          },
          fields: {
            isActive: {
              type: "toggle",
              title: "Enable the portal",
              additionalClass: "large-toggle",
              value: true,
            },
            thumbnailURL: {
              type: "profile-image",
              title: "Add your brand logo",
              fileList: [],
              imagesList: [],
              showPreview: true,
              cropType: "auto",
              required: false,
              hasError: false,
              value: "",
            },
            messages: {
              type: "group",
              nestFields: true,
              fields: {
                title: {
                  type: "text",
                  title: "Title for the portal",
                  placeholder: "Add a message",
                  showEditButton: true,
                  required: true,
                  hasError: false,
                  value: "",
                },
                description: {
                  type: "text",
                  title: "Subtitle for the portal",
                  placeholder: "Add a message",
                  showEditButton: true,
                  required: true,
                  hasError: false,
                  value: "",
                },
              },
            },
            aiSearch: {
              type: "group",
              nestFields: true,
              fields: {
                isActive: {
                  type: "toggle",
                  title: "Show the AI search bar to customers",
                  additionalClass: "large-toggle",
                  value: false,
                },
                placeholder: {
                  type: "text",
                  title: "Add searchbar text",
                  placeholder: "Add a text",
                  showEditButton: true,
                  show: false,
                  required: true,
                  hasError: false,
                  value: "",
                },
                activeSources: {
                  type: "multiple-select-dropdown",
                  placeholder: "Select sources",
                  title: "Sources to search from*",
                  show: false,
                  required: true,
                  hasError: false,
                  fields: [],
                  value: [],
                },
              },
            },
          },
        },
      },
      suggestedQuestionsFields: {
        content: {
          title: "Suggested questions",
          description: "Prompt customers with questions to start their chat",
        },
        fields: [],
      },
      customRepliesFields: {
        content: {
          title: "Custom replies",
          description:
            "Add questions that are linked to custom replies for workflows",
        },
        fields: [],
      },
      customReplies: [],
      suggestedQuestions: [],
      initData: {},
      dataChanged: false,

      status: {
        show: false,
        status: "success",
        title: "Self serve portal saved",
      },
    };
  },
  props: {
    widget: Object,
  },
  components: {
    RegularHeader,
    CustomReplies,
    SuggestedQuestions,
    SettingsForm,
    LineLoader,
    UnsavedChangesModal,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  watch: {
    widget: {
      immediate: true,
      deep: true,
      handler() {
        this.populateData();
      },
    },
  },
  async created() {
    this.populateData();
  },
  methods: {
    // Save widget
    async saveWidget() {
      if (this.dataChanged) {
        // Check if the form has valid input
        let data = { ...this.fields.appearance.fields };
        var isFormValid = this.validateForm(data);
        if (isFormValid) {
          const formData = this.constructPayload(this.parseData(data));
          await this.$emit("saveWidget", formData);
          this.dataPopulated = false;
        }
      }
    },

    // Populate the data for the fields
    populateData() {
      if (!this.dataPopulated) {
        this.dataPopulated = true;

        const { fields: appearance } = this.fields.appearance;
        const { selfServicePortal } = this.widget;

        appearance.isActive.value = selfServicePortal.isActive;
        appearance.thumbnailURL.value = selfServicePortal.thumbnailURL;
        appearance.messages.fields.title.value =
          selfServicePortal.messages.title;
        appearance.messages.fields.description.value =
          selfServicePortal.messages.description;
        appearance.aiSearch.fields.isActive.value =
          selfServicePortal.aiSearch.isActive;
        appearance.aiSearch.fields.placeholder.value =
          selfServicePortal.aiSearch.placeholder;
        appearance.aiSearch.fields.activeSources.value =
          selfServicePortal.aiSearch.activeSources;

        this.customReplies = this.widget.selfServicePortal.customReplies;

        this.suggestedQuestions =
          this.widget.selfServicePortal.suggestedQuestions;

        this.initAiSearch();
        this.initFormData();
      }
    },

    // Initialise the AI search
    initAiSearch() {
      const { sources } = this.company;
      const options = [];
      sources.forEach((s) => {
        options.push({ name: s.name, value: s._id });
      });

      this.fields.appearance.fields.aiSearch.fields.activeSources.fields =
        options;
      this.aiSearchHandler();
    },

    // Toggle the AI Search options
    aiSearchHandler() {
      const aiSearchFields = this.fields.appearance.fields.aiSearch.fields;
      Object.keys(aiSearchFields).forEach(function (key) {
        if (key !== "isActive") {
          aiSearchFields[key].show = aiSearchFields.isActive.value;
        }
      });
    },

    // Update the custom reply
    updateCustomReply(reply) {
      const tempReplies = _.cloneDeep(this.customReplies);
      const index = tempReplies.findIndex((r) => r._id === reply._id);

      if (index == -1) tempReplies.push(reply);
      else tempReplies[index] = reply;

      this.customReplies.splice(0);
      this.customReplies = _.cloneDeep(tempReplies);
    },

    // Delete custom reply
    deleteCustomReply(replyID) {
      const index = this.customReplies.findIndex((r) => r._id === replyID);
      this.customReplies.splice(index, 1);
    },

    // Update the suggested questions
    updateSuggestedQuestion(question) {
      const tempQuestions = _.cloneDeep(this.suggestedQuestions);
      const index = tempQuestions.findIndex((q) => q._id === question._id);
      if (index == -1) tempQuestions.push(question);
      else tempQuestions[index] = question;

      this.suggestedQuestions.splice(0);
      this.suggestedQuestions = _.cloneDeep(tempQuestions);
    },

    // Reconstruct messages after item move
    constructSuggestedQuestions(questions) {
      const tempQuestions = [];
      questions.forEach((c) => {
        tempQuestions.push(c.question);
        c.questions.forEach((q) => {
          tempQuestions.push(q.question);
        });
      });

      this.suggestedQuestions.splice(0);
      this.suggestedQuestions = _.cloneDeep(tempQuestions);

      this.fieldChanged();
    },

    // Delete suggested question
    deleteSuggestedQuestion(questionID) {
      const index = this.suggestedQuestions.findIndex(
        (q) => q._id === questionID
      );
      this.suggestedQuestions.splice(index, 1);
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = {
        ...this.fields,
        suggestedQuestions: this.suggestedQuestions,
        customReplies: this.customReplies,
      };
      this.disableSaveButton(true);
      this.initData = _.cloneDeep(data);
    },

    // Event when field changed
    fieldChanged() {
      this.aiSearchHandler();

      const data = {
        ...this.fields,
        suggestedQuestions: this.suggestedQuestions,
      };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Construct the payload for the controller
    constructPayload(data) {
      const widget = {
        selfServicePortal: data,
      };
      widget.selfServicePortal.suggestedQuestions = this.suggestedQuestions;

      // Create form data
      let formData = new FormData();
      for (var key in widget) formData.append(key, JSON.stringify(widget[key]));

      // Add thumbnail image
      const { thumbnailURL } = this.fields.appearance.fields;
      if (thumbnailURL.imagesList.length) {
        formData.append("images", thumbnailURL.imagesList[0]);
        formData.append("selfServiceIconAdded", true);

        delete widget.thumbnailURL;
      }

      return formData;
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Event on settings scroll
    settingsScroll() {
      const scrollTop = this.$refs.settings.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.unsavedChanges = false;
    },

    // Discard all the changes
    discardChanges() {
      this.fields = _.cloneDeep(this.initData);
      this.disableSaveButton(true);
      this.closeModal();
    },

    // Discard the changes and move to the selected route
    continueRoute() {
      this.closeModal();
      this.discardChanges();
      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
    },
  },
  // Check if there are any changes to be saved
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      this.toRoute = to;
      this.showOptions.unsavedChanges = true;
    } else {
      next();
    }
  },
};
</script>
          
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  margin-bottom: 0 !important;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.settings-main {
  overflow-y: scroll;
  padding: 1.5rem 1rem;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}

.suggested-questions {
  flex: 1;
}
</style>