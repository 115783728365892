<template>
  <!--START: Answer Rule-->
  <div
    v-if="show"
    class="modal modal-large no-overflow"
    :class="{ show: show }"
  >
    <!--START: Header-->
    <ModalHeader :title="title" @closeModal="closeModal"></ModalHeader>
    <!--END: Header-->

    <!--START: Page Loader-->
    <div v-if="showOptions.preLoader" class="pre-loader-wrapper">
      <PreLoader :show="true"></PreLoader>
    </div>
    <!--START: Page Loader-->

    <!--START: Modal Body-->
    <div v-else class="modal-body">
      <!--START: Title-->
      <div
        class="title-wrapper"
        :class="{ 'show-shadow': showOptions.showShadow }"
      >
        <div class="title-info">
          <h3 class="title">Answer Rule</h3>
          <p>Rule for the AI to follow when creating answers</p>
        </div>
        <!--START: Attributes-->
        <div class="attributes-wrapper">
          <FormBuilder
            :fields="fields"
            @fieldChanged="fieldChanged"
          ></FormBuilder>
          <button
            class="btn btn-small btn-primary"
            :disabled="showOptions.disableButton || showOptions.saveInProgress"
            @click="saveAnswerRule"
          >
            Save
          </button>
        </div>
        <!--END: Attributes-->
      </div>
      <!--END: Title-->

      <div class="main-wrapper" ref="documentContent" @scroll="contentScroll">
        <FormBuilder
          :fields="answerRuleFields"
          @fieldChanged="fieldChanged"
        ></FormBuilder>
      </div>
    </div>
    <!--END: Modal Body-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader
      :show="showOptions.lineLoader"
      :class="{ bottom: !showOptions.modalActions }"
    ></LineLoader>
    <!--END: Loader-->
  </div>
  <!--END: Answer Rule-->
</template>
            
  <script>
//Import libraries
import _ from "lodash";

// Importing components
import ModalHeader from "@/components/modals/ModalHeader";
import LineLoader from "@/components/loaders/LineLoader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import FormBuilder from "@/components/form/FormBuilder";
import PreLoader from "@/components/loaders/PreLoader";

// Importing Services
import { AiAgentService } from "@/services";

export default {
  name: "ViewAnswerRule",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    selectedRule: {
      type: Object,
      required: true,
    },
  },
  watch: {
    show: {
      immediate: true,
      deep: true,
      handler() {
        this.initModal();
      },
    },
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  data() {
    return {
      title: "Answer Rule",
      showOptions: {
        lineLoader: false,
        showShadow: false,
        preLoader: true,
        disableButton: true,
      },
      answerRule: {},
      fields: {
        isActive: {
          type: "toggle",
          title: "Active",
          additionalClass: "large-toggle",
          value: true,
        },
      },
      answerRuleFields: {
        title: {
          type: "text",
          title: "Title",
          required: true,
          hasError: false,
          placeholder: "Add a rule name",
          value: "",
        },
        description: {
          type: "textarea",
          placeholder: "Describe the rule for the AI to follow",
          required: true,
          hasError: false,
          rows: 9,
          title: "Description",
          value: "",
        },
      },
      initData: {},
      dataChanged: false,

      status: {
        show: false,
        status: "success",
        title: "Answer rule saved",
      },
      saveInProgress: false,
    };
  },
  components: {
    ModalHeader,
    LineLoader,
    FormBuilder,
    PreLoader,
    NotificationMessage,
  },
  created() {},

  methods: {
    // Init the event timings
    async initModal() {
      this.resetModal();
      this.getAnswerRule();
    },

    // Save answer rule
    async saveAnswerRule() {
      if (!this.saveInProgress) {
        this.saveInProgress = true;
        this.showOptions.lineLoader = true;

        // Check if the form has valid input
        const data = { ...this.fields, ...this.answerRuleFields };
        var isFormValid = this.validateForm(data);
        if (isFormValid) {
          const payload = this.constructPayload(this.parseData(data));
          const response = await AiAgentService.SaveAnswerRule(payload);

          // Check for errors
          if (!response.hasError) {
            this.answerRule = response.data;

            this.populateFields();
            this.showStatusMessage(this.status, 2500);
            this.$emit("ruleAdded", this.answerRule);
          } else this.showErrorMessage(this.status, response.message);
        }

        this.showOptions.lineLoader = false;
        this.saveInProgress = false;
      }
    },

    // Get the document
    async getAnswerRule() {
      if (this.selectedRule._id) this.answerRule = this.selectedRule;
      this.populateFields();
      this.showOptions.preLoader = false;
    },

    // Populate the fields
    populateFields() {
      if (!_.isEmpty(this.answerRule)) {
        this.fields.isActive.value = this.answerRule?.isActive;
        const { title, description } = this.answerRuleFields;

        title.value = this.answerRule.title;
        description.value = this.answerRule.description;
      }

      this.initFormData();
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = {
        ...this.fields,
        ...this.answerRuleFields,
      };

      this.disableSaveButton(true);
      this.initData = _.cloneDeep(data);
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Update rule when fields change
    fieldChanged() {
      const data = {
        ...this.fields,
        ...this.answerRuleFields,
      };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Construct the payload for the controller
    constructPayload(data) {
      const answerRule = {
        ruleID: this.answerRule._id,
        ...this.parseData(this.answerRuleFields),
        isActive: data.isActive,
      };

      return this.sanitizeObject(answerRule);
    },

    // Event on chat scroll
    contentScroll() {
      if (this.$refs.documentContent.scrollTop > 0)
        this.showOptions.showShadow = true;
      else this.showOptions.showShadow = false;
    },

    // Reset booking modal
    resetModal() {
      this.disableSaveButton(true);
      this.showOptions.preLoader = true;
      this.showOptions.lineLoader = false;
      this.showOptions.showShadow = false;
      this.answerRule = {};
    },

    // Hide modal
    closeModal() {
      this.showOptions.lineLoader = false;
      this.$emit("closeModal");
    },
  },
};
</script>
            
    <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ .modal-header {
  padding: 0.5rem 1rem;

  /deep/ .unicon svg {
    background-color: lighten($blackColor, 15%);
    fill: $whiteColor;
  }
}

/deep/ .modal-actions {
  flex-direction: row;
  justify-content: center;
}

.modal-body {
  position: relative;
  padding: 0 !important;
  flex: 1;
  overflow: hidden;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/deep/ .status-message {
  position: relative;
  bottom: auto;
  right: 0;
  left: 0;
  border-radius: 0;
  text-align: center;
}

/deep/ .line-loader {
  position: relative;
  bottom: auto;
  right: 0;
  left: 0;
}

.title-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 2rem;
  z-index: 5;

  &.show-shadow {
    box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
  }

  .title-info {
    flex: 1;
    .title {
      font-size: $mediumFontSize;
      text-transform: capitalize;
      margin-bottom: 0.15rem;
    }

    p {
      color: $paragraphColor;
      font-size: $smallFontSize;
    }
  }
}

.attributes-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  /deep/ {
    .field-main-item {
      margin-bottom: 0;
    }
  }

  .btn {
    margin-left: 1.5rem;
  }
}

.pre-loader-wrapper {
  margin: 2rem 3rem;
}

.node {
  position: relative;
  display: block;
  border-radius: 0.75rem;
  background-color: $whiteColor;
  border: 1px solid $greyBorderColor;
  box-shadow: $cardBoxShadow;
  margin-bottom: 2rem;
  transition: all 0.3s;

  &:hover {
    border-color: $secondaryColor;
  }

  &::after {
    position: absolute;
    content: "";
    top: 100%;
    left: 50%;
    transform: translateX(calc(-50% - 1px));
    width: 1px;
    height: 2rem;
    border-left: 2px dashed darken($lightWhiteColor, 25%);
  }

  .node-preview {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 1.5rem;
    width: calc(100% - 3rem);
    cursor: pointer;

    .unicon {
      /deep/ svg {
        width: 1.25rem;
        height: auto;
        fill: $secondaryColor;
      }
    }

    h3 {
      flex: 1;
      color: lighten($darkBlackColor, 15%);
      font-size: $normalFontSize;
      font-weight: $normalFontWeight;
      margin-left: 1rem;
      margin-bottom: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      max-width: 75%;

      b {
        color: $darkBlackColor;
      }
    }

    .node-type {
      position: absolute;
      display: block;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      padding: 0.35rem 0.75rem;
      border-radius: 0.5rem;
      color: darken($whiteColor, 45%);
      background-color: darken($whiteColor, 7.5%);
      font-size: $smallestFontSize;
    }
  }

  .node-data {
    display: none;
    padding: 1.5rem 2rem 2rem;
    border-top: 1px solid $greyBorderColor;

    /deep/ {
      .field-main-item {
        margin-bottom: 0;
      }
    }
  }

  &.expand {
    box-shadow: 0 0 1rem -0.25rem $boxShadowColor;
    .node-data {
      display: block;
    }
  }
}

.handoff-node {
  margin-bottom: 0;
  &::after {
    display: none;
  }
}

.trigger-details {
  padding: 0.75rem;

  p {
    color: $greyColor;
    font-size: $smallerFontSize;

    b {
      color: $darkBlackColor;
    }
  }
}

.editable-field {
  cursor: pointer;
  color: $blueColor;
  text-decoration: underline;
}

.options {
  span {
    font-weight: $boldFontWeight;
  }
}

.main-wrapper {
  padding: 2rem 3rem;
  //   background: linear-gradient(
  //         90deg,
  //         $lightWhiteColor (22px - 1px),
  //         transparent 1%
  //       )
  //       center,
  //     linear-gradient($lightWhiteColor (22px - 1px), transparent 1%) center,
  //     darken($lightWhiteColor, 25%);
  background-size: 22px 22px;
  border-top: 1px solid $greyBorderColor;
  overflow-y: scroll;

  &.show-shadow {
    box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
  }
}

.ticket-field-item,
.category-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.15rem;
  padding-bottom: 0.15rem;
  border-bottom: 1px solid $cardBorderColor;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .ticket-field-detail,
  .category-detail {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  span {
    font-size: $smallFontSize;
    line-height: auto;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .unicon /deep/ {
    svg {
      fill: $secondaryColor;
      height: auto;
      width: 1rem;
      margin-right: 0.75rem;
    }
  }

  .category-options {
    display: none;

    /deep/ svg {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }

  /deep/ .field-main-item {
    margin-bottom: 0 !important;
  }
}

.category-color {
  display: block;
  width: 0.55rem;
  height: 0.55rem;
  margin: 0 0.2rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.clickable-event {
  font-weight: $mediumFontWeight !important;
  color: $blueColor !important;
  text-decoration: underline;
  cursor: pointer;
}
</style>