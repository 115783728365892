<template>
  <div class="data-table">
    <!--START: Documents List-->
    <ul class="grid-table">
      <li
        class="grid-row grid-header"
        :class="{ 'grid-header-shadow': showOptions.headerShadow }"
      >
        <span class="grid-column">Name</span>
        <span class="grid-column">Intents</span>
        <span class="grid-column">Custom Reply</span>
        <span class="grid-column">Status</span>
      </li>
    </ul>
    <ul
      class="grid-table grid-item-clickable"
      ref="table"
      @scroll="tableScroll"
    >
      <li
        v-for="(c, index) in categories"
        :key="index"
        class="grid-row"
        @click="showIntents(c._id)"
      >
        <div class="grid-column">
          <h3 class="name">{{ c.title }}</h3>
        </div>

        <div class="grid-column">
          <span
            class="intent-count"
            :class="{ 'no-intents': c.intents.length === 0 }"
            >{{ c.intents.length }}</span
          >
        </div>

        <div class="grid-column">
          <div v-if="customReplies.length == 0" class="pre-loader-wrapper">
            <PreLoader :show="true"></PreLoader>
          </div>
          <span v-else class="custom-reply">{{ getCustomReply(c) }}</span>
        </div>

        <div class="grid-column">
          <span class="status" :class="{ inactive: !c.isActive }">{{
            c.isActive ? "Active" : "Inactive"
          }}</span>
        </div>

        <div class="grid-column grid-actions">
          <button class="btn btn-icon btn-small">
            <unicon name="eye"></unicon>
            <span>Intents</span>
          </button>
          <button class="btn btn-icon btn-small" @click.stop="gotoItem(c._id)">
            <unicon name="pen"></unicon>
            <span>Edit</span>
          </button>
          <button
            class="btn btn-icon btn-delete"
            @click.stop="showDeleteModal(c._id, c.title)"
          >
            <unicon name="trash-alt"></unicon>
          </button>
        </div>
      </li>
    </ul>
    <!--END: Documents List-->

    <!--START: Documents Count-->
    <span class="grid-count"
      >Showing {{ categories.length }}
      {{ categories.length == 1 ? "category" : "categories" }}</span
    >
    <!--END: Documents Count-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->
  </div>
</template>
            
<script>
// Importing Components
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import PreLoader from "@/components/loaders/PreLoader";

export default {
  name: "QuestionCategoriesTable",
  props: {
    categories: Array,
    customReplies: Array,
  },
  data() {
    return {
      showOptions: {
        headerShadow: false,
      },
      deleteID: null,
      confirmationFields: {
        show: false,
        title: "",
        type: "delete",
        modalType: "fixed",
        message:
          "This action cannot be undone. All intents for this category will be deleted.",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  computed: {},
  components: {
    ConfirmationModal,
    PreLoader,
  },
  methods: {
    // Get the custom reply for the category
    getCustomReply(category) {
      const selectedReply = this.customReplies.find(
        (m) => m._id === category.customReplyID
      );

      return selectedReply ? selectedReply.name : "-";
    },

    // Event on table scroll
    tableScroll() {
      const scrollTop = this.$refs.table.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Goto view a particular category
    gotoItem(itemID) {
      this.$emit("categorySelected", itemID);
    },

    // Goto view a category's intents
    showIntents(itemID) {
      this.$emit("showCategoryIntents", itemID);
    },

    // Show the category modal
    showDeleteModal(itemID, itemTitle) {
      this.deleteID = itemID;
      this.confirmationFields.confirmValue = itemTitle;
      this.confirmationFields.show = true;
      this.confirmationFields.title = `Delete "${itemTitle}"?`;
    },

    // Delete the category
    async deleteItem() {
      this.$emit("deleteCategory", this.deleteID);
      this.closeModal();
    },

    // Close all the modals
    closeModal() {
      this.confirmationFields.show = false;
    },
  },
};
</script>
            
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.data-table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  margin-bottom: 0.5rem;
}

.grid-table.grid-item-clickable {
  flex: 1;
  overflow-y: scroll;
}

.grid-column {
  &:nth-child(1) {
    width: calc(55% - 25rem);
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:nth-child(2),
  &:nth-child(4) {
    width: 15%;
    text-align: center;
  }

  &:nth-child(3) {
    width: 25%;
  }
}

.grid-actions {
  width: 25rem;

  .btn {
    margin-left: 1.5rem;
  }
}

.name,
.intent-count,
.custom-reply {
  color: $greyColor;
  font-size: $smallerFontSize;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
}

.no-intents {
  opacity: 0.35;
}

.name {
  font-size: $smallFontSize;
  color: $blackColor;
  font-weight: $mediumFontWeight;
}

.status {
  position: relative;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  color: $greenMessageTextColor;
  background-color: lighten($greenMessageColor, 0%);
  border: 1px solid $greenMessageBorderColor;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  text-transform: capitalize;

  &.inactive {
    color: $redMessageTextColor;
    background-color: lighten($redMessageColor, 5%);
    border: 1px solid $redMessageBorderColor;
  }
}

.pre-loader-wrapper /deep/ {
  .buffer-hero,
  .buffer-title {
    height: 1rem;
    margin: 0;
  }

  .buffer-line,
  .buffer-category {
    display: none;
  }
}
</style>