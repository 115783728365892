<template>
  <div class="banner-wrapper">
    <span
      >Your subscription has expired. Please email us at
      <a href="mailto:hello@getmacha.com">hello@getmacha.com</a> to renew your
      subscription.</span
    >
  </div>
</template>
  
<script>
export default {
  name: "SubscriptionBanner",
};
</script>
  
<style scoped lang="scss">
.banner-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0.5rem 0;
  background-color: $redColor;
  text-align: center;

  span {
    font-size: $smallerFontSize;
    color: $whiteColor;
  }

  a {
    color: $whiteColor;
    font-weight: $boldFontWeight;
    text-decoration: underline;
  }
}
</style>