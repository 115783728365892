<template>
  <div v-if="!lodash.isEmpty(widget)" class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton"
        @click="saveWidget"
      >
        Save Changes
      </button>
    </div>
    <!--END: Header Wrapper-->

    <div ref="settings" class="settings-main" @scroll="settingsScroll">
      <!--START: Form Blocks-->
      <!--START: Opening Messages-->
      <div class="settings-form card messages-form-wrapper">
        <div class="settings-form-wrapper">
          <div class="content-wrapper">
            <h3>{{ openingMessagesFields.content.title }}</h3>
            <p>{{ openingMessagesFields.content.description }}</p>
          </div>

          <div class="form-builder">
            <div
              class="message-fields-wrapper"
              v-for="(message, index) in openingMessagesFields.fields"
              :key="message.id"
            >
              <FormBuilder
                class="message-field-form"
                :fields="{ fields: message }"
                @fieldChanged="fieldChanged"
              ></FormBuilder>
              <div
                v-if="openingMessagesFields.fields.length > 1"
                class="message-actions"
              >
                <unicon name="trash-alt" @click="deleteMessage(index)"></unicon>
              </div>
            </div>

            <button class="btn btn-small btn-text btn-icon" @click="addMessage">
              <unicon name="plus"></unicon>
              <span>Add message</span>
            </button>
          </div>
        </div>
      </div>
      <!--END: Opening Messages-->

      <SettingsForm
        v-for="field in fields"
        :key="field._id"
        :fields="field.fields"
        :content="field.content"
        @fieldChanged="fieldChanged"
      ></SettingsForm>
      <!--END: Form Blocks-->
    </div>

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->

    <!--START: Unsaved Changes Modal -->
    <UnsavedChangesModal
      :show="showOptions.unsavedChanges"
      @primaryEvent="continueRoute"
      @secondaryEvent="closeModal"
    ></UnsavedChangesModal>
    <!--END: Unsaved Changes Modal -->
  </div>
</template>
        
<script>
// Imoprt libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import FormBuilder from "@/components/form/FormBuilder";
import SettingsForm from "@/components/form/SettingsForm";
import LineLoader from "@/components/loaders/LineLoader";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal";

export default {
  name: "WidgetAppearance",
  data() {
    return {
      lodash: _,
      dataPopulated: false,
      showOptions: {
        headerShadow: false,
        unsavedChanges: false,
        lineLoader: false,
        disableButton: true,
      },
      regularHeader: {
        title: "Appearance",
        description:
          "Control the appearance of the chat widget used on the website",
      },
      openingMessagesFields: {
        content: {
          title: "Opening Messages",
          description: "Welcome customers with customised greetings",
        },
        fields: [],
      },
      fields: {
        theme: {
          content: {
            title: "Widget Colors",
            description: "Decide the colors of your brand, chat bubbles",
          },
          fields: {
            brand: {
              type: "group",
              nestFields: true,
              fields: {
                brandColor: {
                  type: "color-picker",
                  title: "Brand color",
                  required: false,
                  hasError: false,
                  value: "#4e45dd",
                },
              },
            },
            chatBubble: {
              type: "group",
              nestFields: true,
              fields: {
                customer: {
                  title: "Customer messages",
                  type: "group",
                  nestFields: true,
                  fields: {
                    backgroundColor: {
                      type: "color-picker",
                      title: "Background color",
                      required: false,
                      hasError: false,
                      value: "#4e45dd",
                    },
                    textColor: {
                      type: "color-picker",
                      title: "Text color",
                      required: false,
                      hasError: false,
                      value: "#fffff",
                    },
                  },
                },
                aiAgent: {
                  title: "AI agent messages",
                  type: "group",
                  nestFields: true,
                  fields: {
                    backgroundColor: {
                      type: "color-picker",
                      title: "Background color",
                      required: false,
                      hasError: false,
                      value: "#4e45dd",
                    },
                    textColor: {
                      type: "color-picker",
                      title: "Text color",
                      required: false,
                      hasError: false,
                      value: "#fffff",
                    },
                  },
                },
              },
            },
          },
        },
        button: {
          content: {
            title: "Button appearance",
            description: "Appearance for the widget button",
          },
          fields: {
            button: {
              type: "group",
              nestFields: true,
              fields: {
                customIconURL: {
                  type: "profile-image",
                  title: "Custom icon",
                  fileList: [],
                  imagesList: [],
                  showPreview: true,
                  cropType: "auto",
                  required: false,
                  hasError: false,
                  value: "",
                },
                size: {
                  type: "dropdown",
                  placeholder: "Select size",
                  title: "Select the size of the widget",
                  required: true,
                  hasError: false,
                  fields: [
                    { name: "Normal", value: "normal" },
                    { name: "Small", value: "small" },
                  ],
                  value: [],
                },
                show: {
                  type: "toggle",
                  title: "Show the button",
                  additionalClass: "large-toggle",
                  value: true,
                },
                zIndex: {
                  type: "number",
                  title: "Set the Z Index of the button",
                  placeholder: "Add a value",
                  additionalClass: "small-input",
                  showEditButton: true,
                  hasError: false,
                  required: true,
                  value: "",
                },
                position: {
                  title: "Postion of the button",
                  type: "group",
                  nestFields: true,
                  fields: {
                    top: {
                      type: "text",
                      title: "Top position",
                      placeholder: "Add a position",
                      required: false,
                      hasError: false,
                      showEditButton: true,
                      show: true,
                      value: "",
                    },
                    left: {
                      type: "text",
                      title: "Left position",
                      placeholder: "Add a position",
                      required: false,
                      hasError: false,
                      showEditButton: true,
                      show: true,
                      value: "",
                    },
                    bottom: {
                      type: "text",
                      title: "Bottom position",
                      placeholder: "Add a position",
                      required: false,
                      hasError: false,
                      showEditButton: true,
                      show: true,
                      value: "",
                    },
                    right: {
                      type: "text",
                      title: "Right position",
                      placeholder: "Add a position",
                      required: false,
                      hasError: false,
                      showEditButton: true,
                      show: true,
                      value: "",
                    },
                  },
                },
              },
            },
          },
        },
        notifications: {
          content: {
            title: "Notifications",
            description: "Settings for notification sounds",
          },
          fields: {
            notifications: {
              type: "group",
              nestFields: true,
              fields: {
                enableSound: {
                  type: "toggle",
                  title: "Enable chat sounds",
                  additionalClass: "large-toggle",
                  value: true,
                },
                enableWelcomeMessage: {
                  type: "toggle",
                  title: "Show welcome messages",
                  additionalClass: "large-toggle",
                  value: true,
                },
              },
            },
          },
        },
      },
      messageField: {
        type: "text",
        title: "Opening message",
        placeholder: "Type a message",
        required: false,
        hasError: false,
        showEditButton: true,
        value: "",
      },
      initData: {},
      dataChanged: false,
      status: {
        show: false,
        status: "success",
        title: "Widget theme saved",
      },
    };
  },
  props: {
    widget: Object,
  },
  watch: {
    widget: {
      immediate: true,
      deep: true,
      handler() {
        this.populateData();
      },
    },
  },
  components: {
    RegularHeader,
    FormBuilder,
    SettingsForm,
    LineLoader,
    UnsavedChangesModal,
  },
  computed: {},
  async created() {
    this.populateData();
  },
  methods: {
    // Save widget
    async saveWidget() {
      if (this.dataChanged) {
        // Check if the form has valid input
        const data = {
          ...this.fields.theme.fields,
          ...this.fields.button.fields,
          ...this.fields.notifications.fields,
        };
        var isFormValid = this.validateForm(data);
        if (isFormValid) {
          const formData = this.constructPayload(this.parseData(data));
          await this.$emit("saveWidget", formData);
          this.dataPopulated = false;
        }
      }
    },

    // Add a message field
    addMessage() {
      const messageField = _.cloneDeep(this.messageField);
      messageField.title = "Subsequent message";
      this.openingMessagesFields.fields.push(messageField);
    },

    // Delete a message field
    deleteMessage(index) {
      this.openingMessagesFields.fields.splice(index, 1);
      this.fieldChanged();
    },

    // Populate the data for the fields
    populateData() {
      if (!this.dataPopulated) {
        this.dataPopulated = true;
        const { fields: openingMessages } = this.openingMessagesFields;
        const { fields: theme } = this.fields.theme;
        const { fields: button } = this.fields.button.fields.button;
        const { fields: notifications } =
          this.fields.notifications.fields.notifications;

        // Add opening messages
        let messageCount = 1;
        openingMessages.splice(0);
        this.widget.openingMessages.forEach((m) => {
          const messageField = _.cloneDeep(this.messageField);
          messageField.value = m;

          if (messageCount > 1) messageField.title = "Subsequent message";
          openingMessages.push(messageField);
          messageCount++;
        });

        // Color settings
        theme.brand.fields.brandColor.value =
          this.widget.theme.brand.brandColor;
        theme.chatBubble.fields.customer.fields.backgroundColor.value =
          this.widget.theme.chatBubble.customer.backgroundColor;
        theme.chatBubble.fields.customer.fields.textColor.value =
          this.widget.theme.chatBubble.customer.textColor;
        theme.chatBubble.fields.aiAgent.fields.backgroundColor.value =
          this.widget.theme.chatBubble.aiAgent.backgroundColor;
        theme.chatBubble.fields.aiAgent.fields.textColor.value =
          this.widget.theme.chatBubble.aiAgent.textColor;

        // Button fields
        button.position.fields.top.value = this.widget.button.position.top;
        button.position.fields.left.value = this.widget.button.position.left;
        button.position.fields.bottom.value =
          this.widget.button.position.bottom;
        button.position.fields.right.value = this.widget.button.position.right;
        button.customIconURL.value = this.widget.button.customIconURL;
        button.size.value = [
          this.toTitleCase(this.widget.button.size),
          this.widget.button.size,
        ];
        button.show.value = this.widget.button.show;
        button.zIndex.value = this.widget.button.zIndex;

        // Notification fields
        notifications.enableSound.value = this.widget.notifications.enableSound;
        notifications.enableWelcomeMessage.value =
          this.widget.notifications.enableWelcomeMessage;

        this.initFormData();
      }
    },

    // Initialise the form data for comparisons
    initFormData() {
      this.disableSaveButton(true);
      this.initData = _.cloneDeep({
        ...this.fields,
        ...this.openingMessagesFields,
      });
    },

    // Event when field changed
    fieldChanged() {
      const data = {
        ...this.fields,
        ...this.openingMessagesFields,
      };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Construct the payload for the controller
    constructPayload(data) {
      const openingMessages = [];
      this.openingMessagesFields.fields.forEach((message) => {
        openingMessages.push(message.value);
      });

      const widget = {
        openingMessages,
        theme: { brand: data.brand, chatBubble: data.chatBubble },
        button: data.button,
        notifications: data.notifications,
      };

      // Create form data
      let formData = new FormData();
      for (var key in widget) formData.append(key, JSON.stringify(widget[key]));

      // Add button icon
      const { customIconURL } = this.fields.button.fields.button.fields;
      if (customIconURL.imagesList.length) {
        formData.append("images", customIconURL.imagesList[0]);
        formData.append("customIconAdded", true);

        delete widget.button.customIconURL;
      }

      return formData;
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Event on settings scroll
    settingsScroll() {
      const scrollTop = this.$refs.settings.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.unsavedChanges = false;
    },

    // Discard all the changes
    discardChanges() {
      this.fields = _.cloneDeep(this.initData);
      this.disableSaveButton(true);
      this.closeModal();
    },

    // Discard the changes and move to the selected route
    continueRoute() {
      this.closeModal();
      this.discardChanges();
      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
    },
  },
  // Check if there are any changes to be saved
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      this.toRoute = to;
      this.showOptions.unsavedChanges = true;
    } else {
      next();
    }
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  margin-bottom: 0 !important;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.settings-main {
  overflow-y: scroll;
  padding: 1.5rem 1rem;
  flex: 1;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}

.messages-form-wrapper {
  .message-fields-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;

    .message-field-form {
      flex: 1;
    }

    .message-actions {
      transform: translateY(-0.25rem);
      .unicon /deep/ {
        svg {
          cursor: pointer;
          height: auto;
          width: 0.75rem;
          fill: $greyColor;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .btn {
    float: right;
  }
}
</style>