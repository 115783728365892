<template>
  <div class="key-metrics">
    <div v-for="model in usage" :key="model.id" class="stat-item">
      <b class="stat">{{ nFormatter(model.tokensUsed) }}</b>
      <div class="stat-label">
        <span>{{ model.model }}</span>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "OverallUsage",
  props: {
    usage: { type: Array, required: true },
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  async created() {},
  methods: {},
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.key-metrics {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 1rem;
  background: linear-gradient(to right, $purpleColor 0%, #ff4eb9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.stat {
  -webkit-text-fill-color: transparent;
}
.stat-label {
  -webkit-text-fill-color: $darkBlackColor;
}
.stat-item {
  margin-left: 2.5rem;
  padding-left: 2.5rem;
  border-left: 1px solid $greyBorderColor;

  &:first-child {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    .stat-info p {
      left: auto;
      right: 0;
      transform: none;
    }
  }
}
</style>