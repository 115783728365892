<template>
  <div class="view-insights-wrapper">
    <div class="insights-header">
      <!--START: Total Conversations-->
      <div class="total-conversations">
        <h3>Total conversations</h3>
        <div class="statistic">
          <b>{{ insights.totalConversations }}</b>
          <span>with AI</span>
        </div>
      </div>
      <!--END: Total Conversations-->

      <!--START: Key Metrics-->
      <KeyMetrics :insights="insights"></KeyMetrics>
      <!--END: Key Metrics-->
    </div>

    <!--START: Conversation Stats-->
    <div class="chat-category-wrapper conversation-stats">
      <div class="conversation-metrics-wrapper">
        <div class="conversation-metric">
          <span>Average messages per chat</span>
          <b
            >{{ insights.averageMessagesCount }}
            {{ insights.averageMessagesCount == 1 ? "message" : "messages" }}</b
          >
        </div>
      </div>
      <div class="chat-category">
        <div class="category-title">
          <unicon name="comment-alt"></unicon>
          <h3>Chats by AI categories</h3>
        </div>
        <div class="categories-wrapper">
          <div class="chat-stats-wrapper"></div>
          <CategoryInsights
            categoryTitle="All categories"
            :category="insights.aiCategories"
          ></CategoryInsights>
        </div>
      </div>
    </div>
    <!--END: Conversation Stats-->

    <!--START: No Ticket Chats-->
    <div class="chat-category-wrapper">
      <div class="category-title">
        <svg
          :data-src="require('@/assets/images/icons/ai-sparkle.svg')"
          height="15px"
          width="15px"
          class="ai-icon"
        ></svg>
        <h3>Chats handled by AI</h3>
      </div>
      <div class="categories-wrapper">
        <CategoryInsights
          categoryTitle="Deflected chats"
          :category="insights.openChats"
        ></CategoryInsights>
        <CategoryInsights
          categoryTitle="Resolved chats"
          :category="insights.resolvedChats"
        ></CategoryInsights>
      </div>
    </div>
    <!--END: No Ticket Chats-->

    <div class="chat-category-wrapper">
      <div class="category-title">
        <unicon name="upload"></unicon>
        <h3>Custom Replies & Suggested Questions</h3>
      </div>
      <div class="categories-wrapper">
        <CategoryInsights
          categoryTitle="Custom Replies"
          :category="insights.customReplies"
        ></CategoryInsights>
        <CategoryInsights
          categoryTitle="Suggested Questions"
          :category="insights.suggestedQuestions"
        ></CategoryInsights>
      </div>
    </div>
  </div>
</template>
  
<script>
// Import components
import KeyMetrics from "./KeyMetrics";
import CategoryInsights from "./CategoryInsights";

export default {
  name: "ViewInsights",
  props: {
    insights: { type: Object, required: true },
  },
  data() {
    return {};
  },
  components: { KeyMetrics, CategoryInsights },
  async created() {},
  methods: {},
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.view-insights-wrapper {
  flex: 1;
}
.insights-header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: stretch;
}
.total-conversations {
  flex: 1;

  h3 {
    font-size: $largestFontSize;
    margin-bottom: 0;
  }

  .statistic {
    font-weight: 900;
    background: linear-gradient(to right, $purpleColor 0%, #ff4eb9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    b {
      font-size: 4rem;
    }

    span {
      font-size: 2.5rem;
      margin-left: 0.5rem;
    }
  }
}

/deep/ {
  .stat-item {
    text-align: center;
  }
  .stat {
    font-size: $extraExtraLargeFontSize;
  }
  .stat-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
      font-size: $smallerFontSize;
      opacity: $lightOpacity;
    }
    .stat-info {
      position: relative;
      margin-left: 0.5rem;

      span {
        cursor: pointer;
        display: inline-block;
        font-weight: bold;
        font-size: 8px;
        padding: 1px;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: darken($whiteColor, 20%);
        display: block;
      }

      p {
        display: none;
        position: absolute;
        font-size: $smallestFontSize;
        top: calc(100% + 0.5rem);
        left: 50%;
        transform: translateX(-50%);
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: $darkBlackColor;
        color: $whiteColor;
        width: 10rem;
        z-index: 5;
      }

      span:hover + p {
        display: block;
      }
    }
  }
}

.chat-category-wrapper {
  margin-bottom: 2rem;
  .category-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;

    /deep/ svg {
      fill: $secondaryColor;
      width: 15px;
      height: auto;
    }

    .unicon /deep/ svg {
      width: 18px;
    }

    h3 {
      font-size: $mediumFontSize;
      margin-left: 0.5rem;
      margin-bottom: 0;
    }
  }
  .categories-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .category-insights {
    flex: 1;

    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      margin-left: 1rem;
    }
  }
}

.conversation-stats {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5rem;

  .category-insights {
    margin-left: 0 !important;
    margin-right: 0;
  }

  .conversation-metrics-wrapper {
    flex: 1;
    margin-right: 1rem;
    margin-top: 0rem;
  }

  .chat-category {
    flex: 1;
    margin-left: 1rem;
  }
}

.conversation-metric {
  margin-bottom: 2rem;

  span {
    display: block;
    color: $paragraphColor;
    opacity: $lightOpacity;
    margin-bottom: 0.5rem;
  }

  b {
    font-size: $largerFontSize;
  }
}
</style>