<template>
  <div class="inbox-wrapper">
    <!--START: All Mesages Title-->
    <div
      class="inbox-options-wrapper"
      :class="{ 'show-shadow': showOptions.inboxHeaderShadow }"
    >
      <h1>
        Inbox<span v-if="unreadCount" class="unread-count">{{
          unreadCount
        }}</span>
      </h1>
      <div class="inbox-options">
        <FormBuilder
          :fields="fields"
          @fieldChanged="fieldChanged"
        ></FormBuilder>
      </div>
    </div>
    <!--END: All Mesages Title-->

    <!--START: All Mesages-->
    <div
      v-if="inboxLoaded"
      class="inbox-messages-wrapper"
      ref="inbox"
      @scroll="inboxScroll"
    >
      <div
        class="chat-item"
        v-for="(chat, index) in inbox"
        :key="chat.chatID"
        :class="{
          unread: !chat.chatRead,
          selected: selectedIndex == index,
        }"
        @click="getSelectedChat(chat, index)"
      >
        <span class="name">{{ getCustomerName(chat).name }}</span>
        <div class="last-message-wrapper">
          <span class="last-message" v-html="replaceURLs(getLastMessage(chat))">
          </span>
          <span v-if="chat.messageCount" class="message-count">3</span>
        </div>
        <div class="chat-details">
          <!--START: Categories-->
          <div class="category-wrapper">
            <div
              v-for="c in getCategories(chat)"
              :key="c.id"
              :class="c.type"
              class="category"
            >
              <unicon
                v-if="c.type !== 'ai-category'"
                :name="getCategoryIcon(c.type)"
              ></unicon>
              <svg
                v-else
                :data-src="require('@/assets/images/icons/ai-sparkle.svg')"
                height="12px"
                width="12px"
                class="ai-icon"
              ></svg>
              <span>{{ c.title }}</span>
            </div>
          </div>
          <!--END: Categories-->

          <div class="ticket-details">
            <unicon
              v-if="chat.isFlagged"
              class="flagged-chat"
              name="exclamation-circle"
            ></unicon>
            <span class="ticketID">#{{ chat.ticketID }}</span>
          </div>
        </div>
        <div class="status-wrapper">
          <span class="status" :class="getStatus(chat).class">{{
            getStatus(chat).title
          }}</span>
          <span class="timestamp">{{
            getLastMessageTime(
              chat.messages[chat.messages.length - 1].timestamp
            )
          }}</span>
        </div>
      </div>
      <span v-if="inbox.length == 0" class="empty-inbox"
        >Your inbox is empty</span
      >
    </div>
    <!--END: All Mesages-->

    <!--START: Page Loader-->
    <div v-else class="pre-loader-wrapper">
      <PreLoader :show="true" v-for="index in 5" :key="index"></PreLoader>
    </div>
    <!--START: Page Loader-->
  </div>
</template>
        
<script>
// Import libraries
import _ from "lodash";

// Import functions
import { getLastMessageTime, replaceURLs } from "@/utils/chat.utils";

// Importing components
import FormBuilder from "@/components/form/FormBuilder";
import PreLoader from "@/components/loaders/PreLoader";

export default {
  name: "InboxMessages",
  data() {
    return {
      showOptions: {
        inboxHeaderShadow: false,
      },
      fields: {
        view: {
          type: "multiple-toggle",
          required: false,
          hasError: false,
          isToggle: true,
          value: "all",
          options: [
            { name: "All", value: "all" },
            { name: "Unread", value: "unread" },
          ],
        },
      },
      status: {
        show: false,
        status: null,
        title: null,
        message: null,
      },
    };
  },
  props: {
    inbox: { type: Array, required: true },
    selectedIndex: { type: Number, required: true },
    inboxLoaded: { type: Boolean, required: true, default: true },
    unreadCount: { type: Number, required: false },
  },
  watch: {},
  components: {
    FormBuilder,
    PreLoader,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },

    categories() {
      return this.$store.getters.categories;
    },
  },
  async created() {},
  methods: {
    // Extend functions from chat.utils
    getLastMessageTime,
    replaceURLs,

    // Function to show the selected chat
    getSelectedChat(chat, index) {
      this.$emit("chatSelected", chat, index);
    },

    // Event when field changed
    fieldChanged() {
      let getUnreadChats;
      if (this.fields.view.value == "unread") getUnreadChats = true;

      this.$emit("filterChanged", getUnreadChats);
    },

    // Event on inbox scroll
    inboxScroll() {
      const scrollTop = this.$refs.inbox.scrollTop;
      const scrollHeight = this.$refs.inbox.scrollHeight;
      const clientHeight = this.$refs.inbox.clientHeight;
      const bufferHeight = scrollHeight - clientHeight;

      if (scrollTop > 0) this.showOptions.inboxHeaderShadow = true;
      else this.showOptions.inboxHeaderShadow = false;

      // Check for inifinite scroll
      if (bufferHeight - scrollTop < 30) this.$emit("getInbox");
    },

    // Get the customer name or email
    getCustomerName(chat) {
      let name = "Customer";
      let email = null;

      const customer = chat.dataModels?.find((d) => d.name === "Customer");
      if (!_.isEmpty(customer) && customer.fields.name) {
        name = customer.fields.name;
        email = customer.fields.email;
      } else if (!_.isEmpty(customer) && customer.fields.email)
        name = chat.customer.email;

      return { name, email };
    },

    // Get chat categories
    getCategories(chat) {
      const categories = [];

      // Extract the AI categories
      if (Array.isArray(chat.aiAnalysis)) {
        let aiCategories = chat?.aiAnalysis?.filter(function (r) {
          return r !== undefined;
        });
        aiCategories = _.uniqBy(aiCategories, "questionCategory");
        if (aiCategories.length > 0) {
          aiCategories.forEach((a) => {
            if (!_.isEmpty(a)) {
              categories.push({
                title: a.questionCategory,
                type: "ai-category",
              });
            }
          });
        }
      }

      // Extract the custom replies
      let customReplies = [
        ...new Set(
          chat.messages.map((m) => {
            if (m.messageData?.customReplyID)
              return m.messageData?.customReplyID;
          })
        ),
      ];
      customReplies = customReplies.filter(function (r) {
        return r !== undefined;
      });

      customReplies.forEach((r) => {
        const reply = this.categories.customReplies?.find((cr) => cr._id === r);
        if (reply) {
          categories.push({ title: reply.name, type: "custom-reply" });
        }
      });

      // Extract the suggested questions
      let suggestedQuestions = [
        ...new Set(
          chat.messages.map((m) => {
            if (m.messageData?.questionID) return m.messageData?.questionID;
          })
        ),
      ];
      suggestedQuestions = suggestedQuestions.filter(function (q) {
        return q !== undefined;
      });

      suggestedQuestions.forEach((q) => {
        const question = this.categories.suggestedQuestions?.find(
          (sq) => sq._id === q
        );
        if (question) {
          categories.push({
            title: question.question,
            type: "suggested-question",
          });
        }
      });

      return categories;
    },

    // Get the icon for the category
    getCategoryIcon(category) {
      switch (category) {
        case "ai-category":
          return "copy-alt";
        case "suggested-question":
          return "comment-alt";
        case "custom-reply":
          return "exchange";
        default:
          return "copy-alt";
      }
    },

    // Construct the order message
    constructOrderMessage(order) {
      const { name } = order.order;
      return `Shared order details: <b>${name}</b>`;
    },

    // Get the last message
    getLastMessage(chat) {
      const { messages } = chat;
      const lastMessage = messages[messages.length - 1];

      let senderRole = "";
      if (lastMessage.senderRole === "ai") senderRole = "AI:";

      let message = lastMessage.message;
      switch (lastMessage.messageType) {
        case "integration_event":
          // eslint-disable-next-line no-case-declarations
          const { integrationEvent } = lastMessage.messageData;
          switch (integrationEvent.event) {
            case "show_order":
              message = `Showing <b>${integrationEvent.appType}</b> order`;
              break;
          }
          break;
        case "form":
          message = `Showing form - <b>${lastMessage.messageData.form.title}</b>`;
          break;
        default:
          break;
        case "widget_event":
          // eslint-disable-next-line no-case-declarations
          const { widgetEvent } = lastMessage.messageData;
          switch (widgetEvent?.event) {
            case "end_chat":
              message = `Chat resolved by customer`;
              break;
            case "handoff":
              message = `Handed over to support team`;
              break;
          }
      }

      return `${senderRole} ${message}`;
    },

    // Get chat status
    getStatus(chat) {
      let status = { title: "Open", class: "yellow" };
      if (chat.resolved) status = { title: "Resolved", class: "green" };
      else if (chat.resolved === false) {
        if (chat.status === "ticket")
          status = { title: "Ticket Raised", class: "red" };
      }
      return status;
    },
  },
};
</script>
        
<style scoped lang="scss">
.inbox-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .inbox-messages-wrapper {
    flex: 1;
    overflow-y: scroll;
  }
}

.inbox-options-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1.25rem;
  min-height: 3rem;
  position: relative;
  z-index: 2;

  h1 {
    flex: 1;
    font-size: $largeFontSize;
    margin-bottom: 0;

    .unread-count {
      display: inline-block;
      color: $paragraphColor;
      font-size: $smallestFontSize;
      font-weight: $normalFontWeight;
      margin-left: 0.5rem;
      opacity: $lightOpacity;
      transform: translateY(-0.5px);
    }
  }

  .inbox-options {
    transform: translateX(0.75rem);

    /deep/ {
      .field-main-item,
      .field-radio-wrapper {
        margin-bottom: 0;
      }
    }
  }
}

.chat-item {
  position: relative;
  padding: 0.75rem 1.05rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  cursor: pointer;

  &::after {
    position: absolute;
    display: block;
    content: "";
    border-bottom: 1px solid lighten($greyBorderColor, 5%);
    left: 0.75rem;
    top: -0.25rem;
    width: calc(100% - 1.5rem);
  }

  &:first-child {
    margin-top: 0;

    &::after {
      display: none;
    }
  }

  .name {
    display: block;
    font-size: $smallFontSize;
    opacity: 0.75;
    font-weight: $mediumFontWeight;
    color: $darkBlackColor;
    margin-bottom: 0.15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 3.5rem);
    flex: 1;
  }
  .chat-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.75rem 0 0.25rem;
  }

  .category {
    display: inline-block;
    font-size: 10px;
    font-weight: $mediumFontWeight;
    text-transform: capitalize;
    padding: 0.25rem 0.65rem;
    margin-right: 0.25rem;
    border-radius: 0.35rem;
    color: lighten($darkBlackColor, 45%);
    border: 1px solid darken($whiteColor, 10.5%);

    span {
      display: inline-block;
      vertical-align: middle;
      max-width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ai-icon,
    .unicon /deep/ svg {
      display: inline-block;
      vertical-align: middle;
      height: auto;
      width: 0.85rem;
      margin-right: 0.35rem;
      fill: darken($whiteColor, 35%);
    }

    .ai-icon {
      width: 10px;
    }
  }

  .status {
    display: inline-block;
    font-size: 10px;
    padding: 3px 8px;
    margin-right: 0.5rem;
    border-radius: 0.5rem;
    font-weight: $mediumFontWeight;
    color: $whiteColor;

    &.green {
      color: darken($emeraldColor, 25%);
      background-color: lighten($emeraldColor, 25%);
    }

    &.yellow {
      color: darken($yellowColor, 35%);
      background-color: lighten($yellowColor, 25%);
    }

    &.red {
      background-color: $redColor;
    }
  }

  .ticket-details {
    flex: 1;
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    transform: translateX(0.5rem);
  }

  .ticketID {
    font-size: 10px;
    color: $greyColor;
    opacity: 0.5;
    margin-left: 0.5rem;
  }

  .flagged-chat {
    /deep/ svg {
      fill: $redColor;
      width: 1rem;
      height: auto;
      margin: 0.15rem 0 0 0.25rem;
    }
  }

  .last-message-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .last-message {
      flex: 1;
      display: block;
      color: $darkBlackColor;
      font-size: $smallerFontSize;
      opacity: 0.55;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      /deep/ b {
        text-transform: capitalize;
      }
    }

    .message-count {
      display: block;
      border-radius: 50%;
      color: $whiteColor;
      background-color: $purpleColor;
      font-size: 12px;
      width: 12px;
      height: 12px;
      line-height: 1;
      text-align: center;
      padding: 4px;
      margin-left: 0.5rem;
    }
  }

  .status-wrapper {
    top: 0.8rem;
    right: 0.5rem;
    position: absolute;
  }

  .timestamp {
    color: $greyColor;
    font-size: 10px;
    opacity: 0.55;
  }

  &.unread {
    .name,
    .last-message {
      color: $blackColor;
      font-weight: $boldFontWeight;
      opacity: $fullOpacity;
    }

    .name {
      color: $darkBlackColor;
    }

    .last-message {
      font-weight: $mediumFontWeight;
    }

    .timestamp {
      color: $purpleColor;
      font-weight: $mediumFontWeight;
      opacity: $fullOpacity;
    }
  }

  &:hover {
    background: $secondaryColor;
    border-color: lighten($secondaryColor, 5%);

    background-color: darken($lightWhiteColor, 0%);
    border-color: darken($lightWhiteColor, 2.5%);

    .category {
      background-color: darken($lightWhiteColor, 2.5%);
    }

    & + .chat-item:after,
    &::after {
      display: none;
    }
  }

  &.selected {
    background-color: darken($lightWhiteColor, 5%);
    border-color: darken($lightWhiteColor, 5%);
    & + .chat-item:after,
    &::after {
      display: none;
    }

    .name {
      opacity: $fullOpacity;
    }

    .last-message,
    .timestamp {
      opacity: $mediumOpacity;
    }

    .category {
      color: #676684;
      background-color: #d7d5f6;

      .ai-icon,
      .unicon /deep/ svg {
        fill: lighten($purpleColor, 15%);
      }
    }
  }
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}

.pre-loader-wrapper {
  margin: 0 2rem;

  /deep/ {
    .buffer-line {
      width: auto;
    }
    .buffer-category {
      display: none;
    }
  }
}

.category-wrapper {
  display: block;
  white-space: nowrap;
  overflow-x: scroll;
}

.empty-inbox {
  display: block;
  font-size: $smallFontSize;
  color: $paragraphColor;
  margin: 0 1rem;
  padding: 2rem 2rem 0;
  border-top: 1px solid $greyBorderColor;
  text-align: center;
}
</style>