<template>
  <Draggable
    class="category"
    tag="ul"
    handle=".handle"
    :list="questions"
    :group="{ name: 'category' }"
    @change="itemsMoved"
  >
    <li v-for="(el, index) in questions" :key="index">
      <div class="question">
        <unicon name="bars" class="handle"></unicon>
        <p class="title">{{ el.question.question }}</p>
        <div class="actions">
          <unicon name="pen" @click="showQuestion(el)"></unicon>
          <unicon name="trash-alt" @click="deleteQuestion(el.question)"></unicon>
        </div>
      </div>
      <NestedDraggable
        :questions="el.questions"
        :class="{ empty: !el.questions.length }"
        @questionSelected="showQuestion"
        @deleteQuestion="deleteQuestion"
        @itemsMoved="itemsMoved"
      />
    </li>
  </Draggable>
</template>

<script>
// Import services
import Draggable from "vuedraggable";

export default {
  name: "NestedDraggable",
  props: {
    questions: {
      required: true,
      type: Array,
    },
  },
  components: {
    Draggable,
  },
  methods: {
    // Question has been selected
    showQuestion(question) {
      this.$emit("questionSelected", question);
    },

    // Delete a question
    deleteQuestion(question) {
      this.$emit("deleteQuestion", question);
    },

    // Event when an item has been moved
    itemsMoved() {
      this.$emit("itemsMoved");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.category {
  position: relative;

  .category {
    .category {
      display: none;
    }
  }
}

.question {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: $smallFontSize;
  border: 1px solid $greyBorderColor;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;

  .handle /deep/ {
    margin-right: 1rem;
    svg {
      cursor: grab;
      fill: $purpleColor;
      height: auto;
      width: 1rem;
      transform: translateY(2px);
    }
  }

  .title {
    flex: 1;
    font-size: $smallFontSize;
  }

  .actions {
    .unicon /deep/ {
      svg {
        fill: $greyColor;
        opacity: $lightOpacity;
        height: auto;
        width: 1rem;
        cursor: pointer;
        padding: 0 0.5rem;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
</style>