<template>
  <div class="dashboard-view">
    <div class="page-wrapper">
      <!--START: Menu-->
      <CategoriesMenu
        class="page-menu"
        @menuClicked="initInbox"
      ></CategoriesMenu>
      <!--END: Menu-->

      <!--START: Page Content-->
      <div class="inbox-main page-content">
        <!--START: Inbox Messages-->
        <div v-if="!chatSearched" class="inbox-messages-main">
          <InboxMessages
            ref="inboxMessages"
            :inbox="inbox"
            :selectedIndex="selectedInboxIndex"
            :unreadCount="unreadCount"
            :inboxLoaded="inboxLoaded"
            @chatSelected="chatSelected"
            @getInbox="getInbox"
            @filterChanged="changeInboxFilter"
          ></InboxMessages>

          <!--START: Line Loader-->
          <LineLoader :show="showOptions.lineLoader"></LineLoader>
          <!--END: Line Loader-->
        </div>

        <!--END: Inbox Messages-->

        <!--START: Zero State -->
        <ZeroState
          v-if="inboxLoaded && inbox.length == 0"
          class="zero-state"
          :imageURL="zeroStateData.imageURL"
          :title="zeroStateData.title"
          :message="zeroStateData.message"
        ></ZeroState>
        <!--END: Zero State -->

        <!--START: Pre Loader-->
        <div
          v-else-if="lodash.isEmpty(selectedChat)"
          class="pre-loader-wrapper"
        >
          <PreLoader :show="true" v-for="index in 2" :key="index"></PreLoader>
        </div>
        <!--END: Pre Loader-->

        <!--START: Selected Chat-->
        <div
          v-else
          class="selected-chat-main"
          :class="{ 'chat-searched': chatSearched }"
        >
          <!--START: Chat Window-->
          <SelectedChat
            :chat="selectedChat"
            @toggleResolution="toggleResolution"
            @toggleFlag="toggleFlag"
            @toggleTicket="toggleTicket"
            @markUnread="markUnread"
            @deleteChat="deleteChat"
          ></SelectedChat>
          <!--END: Chat Window-->

          <!--START: Actions-->
          <ChatActions :chat="selectedChat"></ChatActions>
          <!--END: Actions-->
        </div>
        <!--END: Selected Chat-->
      </div>
      <!--END: Page Content-->

      <!--START: Notification Message-->
      <NotificationMessage
        :show="status.show"
        :status="status.status"
        :title="status.title"
      ></NotificationMessage>
      <!--END: Notification Message-->
    </div>
  </div>
</template>
    
<script>
// Importing libraries
import _ from "lodash";

// Importing Services
import { ChatService } from "@/services";
import "external-svg-loader";

// Import Components
import CategoriesMenu from "@/components/dashboard/inbox/CategoriesMenu";
import ZeroState from "@/components/misc/ZeroState";
import InboxMessages from "@/components/dashboard/inbox/InboxMessages";
import SelectedChat from "@/components/dashboard/inbox/SelectedChat";
import ChatActions from "@/components/dashboard/inbox/ChatActions";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PreLoader from "@/components/loaders/PreLoader";

export default {
  name: "Inbox",
  data() {
    return {
      lodash: _,
      showOptions: {
        chatHeaderShadow: false,
        lineLoader: false,
      },
      inboxLoaded: false,
      chatSearched: false,
      selectedChat: {},
      selectedChatLoaded: true,
      totalCount: -1,
      unreadCount: 0,
      inbox: [],
      selectedInboxIndex: 0,
      pageNumber: 1,
      status: {
        show: false,
        status: "success",
        title: null,
      },

      zeroStateData: {
        imageURL: "services.jpg",
        title: "No messages found",
        message: "Looks like this inbox is empty",
      },
    };
  },
  props: {},
  watch: {},
  components: {
    CategoriesMenu,
    ZeroState,
    InboxMessages,
    SelectedChat,
    ChatActions,
    NotificationMessage,
    LineLoader,
    PreLoader,
  },
  computed: {},
  async created() {
    await this.initInbox();
  },

  methods: {
    // Load a new inbox
    async initInbox() {
      const { ticketID } = this.$route.query;

      if (!ticketID) {
        this.resetInbox();
        this.resetSelectedChat();
        await this.getInbox();

        if (this.inbox.length) this.chatSelected(this.inbox[0]);
      } else {
        this.getTicket(ticketID);
      }
    },

    // Get a particular ticket
    async getTicket(ticketID) {
      const response = await ChatService.GetChatByID({ ticketID });
      if (!response.hasError) {
        this.chatSelected(response.data);
        this.chatSearched = true;
      }
    },

    // Get all the chats for the particular company
    async getInbox(unreadChats = false) {
      if (this.totalCount == -1 || this.inbox.length < this.totalCount) {
        this.showOptions.lineLoader = true;
        const { category, status, customReply } = this.$route.query;

        this.pageNumber += 1;
        const response = await ChatService.GetInbox({
          pageNumber: this.pageNumber,
          category,
          status,
          customReply,
          unreadChats,
        });

        if (!response.hasError) this.populateInbox(response.data);
      }
    },

    // Populate the inbox
    populateInbox(data) {
      this.unreadCount = data.unreadCount;
      this.totalCount = data.totalCount;
      this.inbox.push(...data.inbox);

      this.showOptions.lineLoader = false;
      this.inboxLoaded = true;
    },

    // Event when inbox filter has changed
    async changeInboxFilter(unreadChats) {
      this.inboxLoaded = false;

      this.resetInbox();
      this.resetSelectedChat();
      await this.getInbox(unreadChats);
      if (this.inbox.length) this.chatSelected(this.inbox[0]);

      this.selectedInboxIndex = 0;
      this.inboxLoaded = true;
    },

    // New chat selected from inbox
    chatSelected(chat, index = 0) {
      this.resetSelectedChat();
      this.getSelectedChat(chat);
      this.selectedInboxIndex = index;

      if (!chat.chatRead) this.unreadCount--;
      chat.chatRead = true;
    },

    // Get the selected chat
    async getSelectedChat(chat = this.selectedChat) {
      if (chat && this.selectedChatLoaded) {
        this.selectedChatLoaded = false;

        const data = { chatID: chat._id };
        const response = await ChatService.GetChat(data);

        if (!response.hasError) {
          this.selectedChat = response.data;
          this.selectedChatLoaded = true;
        }
      }
    },

    // Populate the selected chat
    populateSelectedChat(chat, repopulateChat = false) {
      // Checking if messages have to be added to an existing chat
      const { messages } = chat;
      if (this.selectedChat.messages && !repopulateChat) {
        this.selectedChat.messages.unshift(...messages);
      } else {
        // Initialising a new chat
        this.selectedChat = chat;
      }

      // Check for loading more messages
      if (messages?.length < this.selectedChatOptions.count) {
        this.selectedChatOptions.messagesRemaining = false;
      }

      if (this.selectedChat) this.selectedChat.chatRead = true;
      this.selectedChatLoaded = true;
    },

    // Toggle the chat resolution
    async toggleResolution(chatID) {
      this.showOptions.lineLoader = true;
      const response = await ChatService.ToggleResolution({
        chatID,
        resolved: !this.selectedChat.resolved,
      });

      this.showOptions.lineLoader = false;

      if (!response.hasError) {
        const { data: chat, message } = response;

        if (this.selectedChat._id === chat._id) {
          this.selectedChat.resolved = chat.resolved;

          // Show notification
          this.status.title = message;
          this.showStatusMessage(this.status, 2500);
        }

        // Update to Inbox
        const index = this.inbox.findIndex((c) => c._id === chat._id);
        if (index > -1) this.inbox[index].resolved = chat.resolved;
      }
    },

    // Toggle the chat flag status
    async toggleFlag(chatID) {
      this.showOptions.lineLoader = true;
      const response = await ChatService.ToggleFlag({
        chatID,
        isFlagged: !this.selectedChat.isFlagged,
      });

      this.showOptions.lineLoader = false;

      if (!response.hasError) {
        const { data: chat, message } = response;

        if (this.selectedChat._id === chat._id) {
          this.selectedChat.isFlagged = chat.isFlagged;

          // Show notification
          this.status.title = message;
          this.showStatusMessage(this.status, 2500);
        }

        // Update to Inbox
        const index = this.inbox.findIndex((c) => c._id === chat._id);
        if (index > -1) this.inbox[index].isFlagged = chat.isFlagged;
      }
    },

    // Toggle the chat ticket status
    toggleTicket(chatID) {
      const status = this.selectedChat.status !== "ticket" ? "ticket" : "human";
      ChatService.ToggleTicket({ chatID, status });
    },

    // Toggle ticket status socket event handler
    toggleTicketHandler(response) {
      if (!response.hasError) {
        const { data: chat, message } = response;

        if (this.selectedChat._id === chat._id) {
          this.selectedChat.status = chat.status;

          // Show notification
          this.status.title = message;
          this.showStatusMessage(this.status, 2500);
        }

        // Update to Inbox
        const index = this.inbox.findIndex((c) => c._id === chat._id);
        if (index > -1) this.inbox[index].status = chat.status;
      } else {
        // TODO: Error handling part to be added here
      }
    },

    // Mark the chat as unread
    async markUnread(chatID) {
      this.showOptions.lineLoader = true;
      const chat = this.inbox.find((c) => c._id == chatID);

      if (chat.chatRead) {
        const response = await ChatService.MarkUnread({ chatID });

        if (!response.hasError) {
          const { data: chat, message } = response;

          if (this.selectedChat._id === chat._id) {
            this.selectedChat.chatRead = chat.chatRead;

            // Show notification
            this.status.title = message;
            this.showStatusMessage(this.status, 2500);
          }

          // Update to Inbox
          const index = this.inbox.findIndex((c) => c._id === chat._id);
          if (index > -1) this.inbox[index].chatRead = chat.chatRead;
          this.unreadCount++;
        }
      }

      this.showOptions.lineLoader = false;
    },

    // Delete selected chat
    async deleteChat(chatID) {
      this.showOptions.lineLoader = true;
      const response = await ChatService.DeleteChat({ chatID });

      this.showOptions.lineLoader = false;
      if (!response.hasError) {
        const { data: chatID, message } = response;

        // Update to Inbox
        const index = this.inbox.findIndex((c) => c._id == chatID);
        if (!this.inbox[index].chatRead) this.unreadCount--;
        this.inbox.splice(index, 1);

        if (this.selectedChat._id === chatID) {
          // Reload with new selected chat
          this.resetSelectedChat();
          this.getSelectedChat(this.inbox[index]);

          // Show notification
          this.status.title = message;
          this.showStatusMessage(this.status, 2500);
        }
      }
    },

    // Reset the inbox
    resetInbox() {
      this.chatSearched = false;
      this.inbox.splice(0);
      this.pageNumber = 0;
      this.unreadCount = 0;
      this.totalCount = -1;
    },

    // Reset the chat options
    resetSelectedChat() {
      this.selectedChat = {};
      this.selectedChatOptions = {
        startIndex: -20,
        count: 20,
        messagesRemaining: true,
      };
    },
  },
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.inbox-main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .inbox-messages-main {
    position: relative;
    width: 25rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: scroll;
    border-right: 1px solid $greyBorderColor;

    /deep/ .line-loader {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .selected-chat-main {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: calc(100% - 25rem);
    height: 100%;

    &.chat-searched {
      width: calc(100%);
    }
  }
}

.page-menu {
  z-index: 5;
  overflow-y: scroll;

  /deep/ .menu-items-wrapper {
    height: auto;
  }
}

.pre-loader-wrapper {
  flex: 1;
  margin: 2rem;

  /deep/ {
    .buffer-category {
      height: 30vh;
    }
    .pre-loader:not(:first-child) {
      .buffer-title,
      .buffer-category {
        display: none;
      }
      .buffer-hero {
        margin-bottom: 0;
      }

      .buffer-line {
        width: auto;
      }
    }
  }
}

.zero-state {
  flex: 1;
  margin: 2rem;
  padding: 2rem 0;
  background-color: darken($whiteColor, 0.5%);
  border: 1px solid $greyBorderColor2;
  border-radius: 1rem;
}

/deep/ {
  .notification-message {
    position: fixed;
  }
}
</style>