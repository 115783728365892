<template>
  <div class="dashboard-view">
    <div class="page-wrapper">
      <!--START: Menu-->
      <PageMenu
        :menu="pageMenu"
        :highlightExactRoute="false"
        class="page-menu"
      ></PageMenu>
      <!--END: Menu-->

      <!--START: Settings View-->
      <div v-if="!lodash.isEmpty(widget)" class="page-content">
        <keep-alive
          ><router-view
            :widget="widget"
            @saveWidget="saveWidget"
            @toggleShopifyWidget="toggleShopifyWidget"
          ></router-view
        ></keep-alive>
      </div>
      <!--END: Settings View-->

      <!--START: Pre Loader-->
      <div v-else class="pre-loader-wrapper">
        <PreLoader :show="true" v-for="index in 2" :key="index"></PreLoader>
      </div>
      <!--END: Pre Loader-->

      <!--START: Loader-->
      <LineLoader :show="showOptions.lineLoader"></LineLoader>
      <!--END: Loader-->

      <!--START: Notification Message-->
      <NotificationMessage
        :show="status.show"
        :status="status.status"
        :title="status.title"
      ></NotificationMessage>
      <!--END: Notification Message-->
    </div>
  </div>
</template>
  
<script>
// Import libraries
import _ from "lodash";

//Importing components
import PageMenu from "@/components/dashboard/PageMenu";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PreLoader from "@/components/loaders/PreLoader";

// Importing Services
import { WidgetSettingsService } from "@/services";

export default {
  name: "Widget",
  data() {
    return {
      lodash: _,
      showOptions: {
        lineLoader: false,
      },
      pageMenu: [
        {
          name: "Chat Widget",
          type: "category",
          menuItems: [
            {
              name: "Appearance",
              icon: "pathfinder",
              path: "/chat-widget/appearance",
            },
            {
              name: "Self Serve Portal",
              icon: "comment-alt",
              path: "/chat-widget/self-serve-portal",
            },
            {
              name: "Handoff",
              icon: "exchange",
              path: "/chat-widget/handoff",
            },
            {
              name: "Active Hours",
              icon: "clock",
              path: "/chat-widget/active-hours",
            },
            {
              name: "Activation",
              icon: "setting",
              path: "/chat-widget/activation",
            },
          ],
        },
      ],
      widget: {},
      status: {
        show: false,
        status: "success",
        title: "Widget saved",
      },
      saveInProgress: false,
    };
  },
  components: {
    PageMenu,
    NotificationMessage,
    LineLoader,
    PreLoader,
  },
  computed: {},
  async created() {
    this.restrictAdminAccess();
    await this.getWidget();
  },
  methods: {
    // Save widget
    async saveWidget(widget) {
      if (!this.saveInProgress) {
        this.saveInProgress = true;
        // Hide status message and show line loader
        this.status.show = false;
        this.showOptions.lineLoader = true;

        const response = await WidgetSettingsService.SaveWidget(widget);
        this.widget = response.data;
        this.showStatusMessage(this.status);

        this.showOptions.lineLoader = false;
        this.saveInProgress = false;
      }
    },

    // Toggle Shopify widget
    async toggleShopifyWidget() {
      if (!this.saveInProgress) {
        this.saveInProgress = true;
        // Hide status message and show line loader
        this.status.show = false;
        this.showOptions.lineLoader = true;

        const response = await WidgetSettingsService.ToggleShopifyWidget();
        this.widget = response.data;
        this.showStatusMessage(this.status);

        this.showOptions.lineLoader = false;
        this.saveInProgress = false;
      }
    },

    // Get Widget
    async getWidget() {
      const response = await WidgetSettingsService.GetWidget();
      if (!response.hasError) this.widget = response.data;
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ .page-header {
  margin-bottom: 3rem;
}

.pre-loader-wrapper {
  flex: 1;
  margin: 5rem;

  /deep/ {
    .buffer-line {
      width: auto;
    }
    .buffer-category {
      display: none;
    }

    .pre-loader:first-child {
      margin-bottom: 2rem;
      .buffer-category {
        display: block;
      }
    }
  }
}

/deep/ .line-loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
</style>